import React from "react"

import { styled } from "styled-components"

import styles from "styles/styles"

import { ReactComponent as TaekusIcon } from "assets/svg/TaekusIcon.svg";

const TaekusLoadingIcon = () => {
    return <Container>
        <StyledTaekusIcon fill={styles.Color.White}/>
        <LoadingWrapper>
            <StyledTaekusIcon fill={styles.Color.TaekusPurple}/>
        </LoadingWrapper>
    </Container>
}

const Container = styled.div`
    position: relative;
    width: 90px;
    height: 80px;
    ${styles.MediaQueries.Mobile} {
        width: 45px;
        height: 40px;
    }
`

const LoadingWrapper = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    background-size: 100%;
    overflow: hidden;
    display: flex;
    align-items: end;
    -moz-transition: all .6s ease-in-out;
    -o-transition: all .6s ease-in-out;
    -webkit-transition: all .6s ease-in-out;
    transition: all .6s ease-in-out;
    animation : scroll 3s infinite;
    @keyframes scroll {
        0% {
            height: 0;
            align-items: end;
            bottom: 0;
        }
        50% {
            bottom: 0;
            top: auto;
            height: 100%;
            align-items: end;
        }
        51% {
            bottom: auto;
            height: 100%;
            align-items: start;
            top: 0;
        }
        100% {
            top: 0;
            align-items: start;
            height: 0;
        }
    }
`

const StyledTaekusIcon = styled(TaekusIcon)`
    width: 90px;
    height: 80px;
    ${styles.MediaQueries.Mobile} {
        width: 45px;
        height: 40px;
    }
`

export default TaekusLoadingIcon