import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { AnimatePresence, motion } from "framer-motion";
import { styled } from "styled-components"

import { onboardUser, resendVerificationEmail } from "components/signup/signupSlice";
import { OnboardStatus, ProductType } from "components/signup/types";

import Button from "components/signup/components/Button";
import TaekusLoadingIcon from "components/signup/components/TaekusLoadingIcon";
import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";

import { fadeInOutMotionProps } from "styles/motionConstants";
import styles from "styles/styles";

const resendCodeDebounceTime = 30000 // 30 seconds

const VerifyEmail = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const userExists = useSelector((state: any) => state.signup.userExists)
    const application = useSelector((state: any) => state.signup.application)
    const isLoading = useSelector((state: any) => state.signup.isLoading)
    const onboardStatus = useSelector((state: any) => state.signup.onboardStatus)
    const onboardFailed = useSelector((state: any) => state.signup.onboardFailed)
    const alloy = useSelector((state: any) => state.signup.alloy)
    const products = useSelector((state: any) => state.signup.products)
    const offerUuid = useSelector((state: any) => state.signup.offer?.uuid)
    const stepDirection = useSelector((state: any) => state.signup.direction)

    const [isResendDebounced, setIsResendDebounced] = useState(true)

    const selectedProduct = products?.find((product: any) => product.productCode === application.productCode)
    const isCredit = selectedProduct?.productType === ProductType.Credit

    const redirect = () => {
        history.push('/')
    }

    const handleResend = () => {
        if (!isResendDebounced) {
            setIsResendDebounced(true)
            dispatch(resendVerificationEmail(offerUuid))
            setTimeout(() => {
                setIsResendDebounced(false)
            }, resendCodeDebounceTime)
        }
    }

    const getContent = () => {
        // Onboard API failed
        if (onboardFailed) {
            return <>
                <Title>Something went wrong.</Title>
                <Text>An error occured while finalizing your application. Please contact support at <PurpleText>support@taekus.com</PurpleText>.</Text>
            </>
        }
        // User is pending manual review
        if ([OnboardStatus.PendingReview, OnboardStatus.PendingUserInput].includes(onboardStatus)) {
            return <>
                <Title>Thanks for applying!</Title>
                <Text>Please allow 5-7 business days for our team to review your documents. We’ll reach out by text or email once we’ve reviewed your application.</Text>
            </>
        }
        // User already has a Taekus account
        if (userExists) {
            return <>
                <Title>Your new card account has been added!</Title>
                <Text>Log in to your account to start spending.</Text>
                <Button onClick={redirect}>Log In</Button>
            </> 
        }   
        // User needs to verify email
        return <>
            <Title>Last thing - we sent you an email to verify your email address.</Title>
            <Text>Please click on the link in your email to confirm your email address.</Text>
            <Retry disabled={isResendDebounced} onClick={handleResend}>Didn’t receive an email?</Retry>
        </>
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsResendDebounced(false)
        }, resendCodeDebounceTime)

        return () => { clearTimeout(timeout) }
    }, [])

    useEffect(() => {
        dispatch(onboardUser({
            uuid: offerUuid,
            username: application.username,
            password: application.password,
            cardHolderAgreementAcknowledgedUrl: selectedProduct?.cardHolderAgreementUrl,
            creditTermsAcknowledgedUrl: isCredit ? selectedProduct?.productTermsUrl : undefined,
            debitTermsAcknowledgedUrl: !isCredit ? selectedProduct?.productTermsUrl : undefined,
            electronicFundsTransferAcknowledgedUrl: selectedProduct?.electronicFundsTransferUrl,
            electronicDisclosureAcknowledgedUrl: selectedProduct?.electronicDisclosureUrl,
            rewardsTermsAcknowledgedUrl: selectedProduct?.rewardsTermsUrl,
            privacyPolicyAcknowledgedUrl: selectedProduct?.privacyPolicyUrl,
            journeyApplicationToken: alloy?.journey_application_token,
        }))
    }, [dispatch]) // eslint-disable-line

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='VerifyEmail'
    >
        <AnimatePresence mode="wait">
            {isLoading ? <Content key='loading' {...fadeInOutMotionProps}>
                <TaekusLoadingIcon/>
                <Title>Finalizing your new account...</Title>
            </Content> : <Content {...fadeInOutMotionProps}>
                {getContent()}
            </Content>}
        </AnimatePresence>
    </StepContainer>
}

const PurpleText = styled.span`
    color: ${styles.Color.TaekusPurple};
`

const Content = styled(motion.div)`
    display: flex;
    flex-direction: column;
    align-items: center;
`

type RetryProps = {
    disabled: boolean
}

const Retry = styled.div<RetryProps>`
    color: ${props => props.disabled ? `rgba(153, 152, 152, 0.6)` : styles.Color.TaekusPurple};
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    margin-top: 8px;
    ${styles.MediaQueries.Mobile} {
        font-size: 14px;
    }
    ${styles.Animation.transitionStyles}
`

const Text = styled.div`
    color: ${styles.Color.TaekusGrey2};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    margin-bottom: 16px;
    ${styles.MediaQueries.Mobile} {
        font-size: 14px;
        line-height: 18px; /* 128.571% */
        margin-bottom: 24px;
    }
`

const Title = styled.div`
    color: ${styles.Color.TaekusGrey1};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-bottom: 32px;
    &:last-child {
        margin-bottom: 0;
        margin-top: 32px;
    }
    ${styles.MediaQueries.Mobile} {
        font-size: 24px;
        line-height: 124%; /* 29.76px */
        letter-spacing: 0.24px;
        margin-bottom: 16px;
    }
`

export default VerifyEmail