import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import { geocodeByPlaceId } from "react-google-places-autocomplete";

import { AnimatePresence, motion } from "framer-motion";
import { styled } from "styled-components"

import { parseGoogleAddress } from "components/common/util";

import { SignupStep } from "components/signup/constants";
import { StringError, TermsError } from "components/signup/errors";
import messages from "components/signup/messages";
import { updateApplication, updateLead, updateStep } from "components/signup/signupSlice";
import { StepDirection } from "components/signup/types";
import { validateString, validateSSN, validateAddress } from "components/signup/validators";

import AddressInput from "components/signup/components/AddressInput";
import Button from "components/signup/components/Button";
import Checkbox from "components/signup/components/Checkbox";
import Input from "components/signup/components/Input";
import SSNInput from "components/signup/components/SSNInput";
import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";

import { expandVerticallyMotionProps } from "styles/motionConstants";
import styles from "styles/styles";

const UserDetails = () => {
    const dispatch = useDispatch();
    
    const leadUuid = useSelector((state: any) => state.signup.leadUuid)
    const application = useSelector((state: any) => state.signup.application)
    const isLoading = useSelector((state: any) => state.signup.isLoading)
    const products = useSelector((state: any) => state.signup.products)
    const stepDirection = useSelector((state: any) => state.signup.direction)

    const [showErrors, setShowErrors] = useState(false)
    const [firstName, setFirstName] = useState(application.firstName || '')
    const [lastName, setLastName] = useState(application.lastName || '')
    const [ssn, setSSN] = useState(application.ssn || application.ssnLastFour || '')
    const [showLastFour, setShowLastFour] = useState(!application.ssn && application.ssnLastFour)
    const [isSsaAgree, setIsSsaAgreed] = useState(false)
    const [address, setAddress] = useState<any>(application.addressStreet ? {
        label: `${application.addressStreet}, ${application.addressCity}, ${application.addressState}`,
        value: undefined,
    } : undefined)
    const [addressLine2, setAddressLine2] = useState(application.addressLineTwo || '')
    
    const selectedProduct = products?.find((product: any) => product.productCode === application.productCode)

    const firstNameError: any = validateString(firstName)
    const lastNameError: any = validateString(lastName)
    const ssnError: any = !showLastFour && validateSSN(ssn)
    const addressError: any = validateAddress(address)
    const address2Error: any = addressLine2.length > 40 ? StringError.TooLong : undefined
    const ssaError: any = isSsaAgree === false ? TermsError.Required : undefined
    const isSubmitDisabled = [firstNameError, lastNameError, ssnError, addressError, address2Error, ssaError]
        .some(error => error !== undefined)

    const handleSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        
        if (!isSubmitDisabled) {
            // Geocode personal address
            const parsedAddress = await geocodeByPlaceId(address?.value?.place_id)
                .then((gAddress) => parseGoogleAddress(gAddress))
                .catch(() => undefined)

            const applicationChanges = {
                firstName,
                lastName,
                ssn: showLastFour ? undefined : ssn,
                addressStreet: parsedAddress?.street || application.addressStreet,
                addressCity: parsedAddress?.city || application.addressCity,
                addressState: parsedAddress?.state || application.addressState,
                addressZip: parsedAddress?.zip || application.addressZip,
                addressLineTwo: addressLine2,
            }

            dispatch(updateApplication(applicationChanges))
            dispatch(updateLead({ leadUuid, application: applicationChanges }))
            dispatch(updateStep({ step: SignupStep.Email, direction: StepDirection.Right }))
        } else { 
            setShowErrors(true)
        }
    }

    // onChange handlers
    const handleFirstNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.value.length < 40) {
            setFirstName(ev.target.value)
        }
    }

    const handleLastNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.value.length < 40) {
            setLastName(ev.target.value)
        }
    }

    const handleAddressChange = (value: any) => {
        setAddress(value)
    }

    const handleAddress2Change = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.value.length < 40) {
            setAddressLine2(ev.target.value)
        }
    }

    const handleSsnChange = (value: string) => {
        if (showLastFour) {
            setShowLastFour(false)
            setSSN('')
        } else {
            setSSN(value)
        }
    }

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='UserDetails'
    >
        <form className="d-flex flex-column align-items-center" style={{maxWidth: '100%'}} onSubmit={handleSubmit}>
            <Title>Sorry, we couldn’t find your info—</Title>
            <Text>But we’d love to get to know you. Tell us about yourself!</Text>
            <InputList>
                <InputContainer>
                    <Input errorMessage={showErrors && messages.Errors.FirstName[firstNameError]} onChange={handleFirstNameChange}  value={firstName} label="First Name"/>
                </InputContainer>
                <InputContainer>
                    <Input errorMessage={showErrors && messages.Errors.LastName[lastNameError]} onChange={handleLastNameChange}  value={lastName} label="Last Name"/>
                </InputContainer>
                <InputContainer>
                    <SSNInput errorMessage={showErrors && messages.Errors.SSN[ssnError]} lastFour={showLastFour} value={ssn} onChange={handleSsnChange}/>
                </InputContainer>
                <InputContainer>
                    <AddressInput errorMessage={showErrors && messages.Errors.Address[addressError]} placeholder="Address Line 1" value={address} onChange={handleAddressChange} />
                </InputContainer>
                <InputContainer>
                    <Input errorMessage={showErrors && messages.Errors.Address2[address2Error]} autocomplete="new-password" onChange={handleAddress2Change}  value={addressLine2} label="Address Line 2*"/>
                </InputContainer>
            </InputList>
            <CheckboxContainer htmlFor='agree'>
                <div style={{marginRight: '16px'}}>
                    <Checkbox checked={isSsaAgree} onClick={(ev) => { setIsSsaAgreed(!isSsaAgree); ev?.preventDefault(); }} id='agree' />
                </div>
                <div>
                    I authorize the <span style={{ fontWeight: 500 }}>Social Security Administration (SSA) to verify and disclose to {selectedProduct?.bankName}</span> through Socure, LLC, their service provider for the purpose of this transaction whether the name, Social Security Number (SSN) and date of birth I have submitted matches information in SSA records. My consent is for a one-time validation within the next 90 days.
                </div>
            </CheckboxContainer>
            <AnimatePresence mode='wait'>
                {showErrors && messages.Errors.Terms[ssaError] && <motion.div style={{minWidth: '100%', width: 'min-content'}} {...expandVerticallyMotionProps}>
                    <ErrorMessage>{messages.Errors.Terms[ssaError]}</ErrorMessage>
                </motion.div>}
            </AnimatePresence>
            <div style={{margin: '48px 0', display: 'flex'}}>
                <Button isLoading={isLoading} type="submit">Next</Button>
            </div>
        </form>
    </StepContainer>
}

const ErrorMessage = styled.div`
    color: ${styles.Color.TaekusRed};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 14.88px */
    letter-spacing: 0.24px;
    padding: 8px 16px 0;
`

const CheckboxContainer = styled.label`
    margin-top: 48px;
    display: flex;
    align-items: start;
    width: 632px;
    border-radius: 4px;
    border: 1px solid ${styles.Color.TaekusGrey5};
    background: ${styles.Color.White};
    padding: 16px;
    color: ${styles.Color.TaekusGrey3};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    cursor: pointer;
    user-select: none;
    &:hover {
        box-shadow: 0px 0px 16px 0px rgba(124, 61, 118, 0.15);
        border: 1px solid transparent;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
    ${styles.Animation.transitionStyles}
`

const InputList = styled.div`
    width: 416px;
    margin-top: 48px;
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const InputContainer = styled.div`
    &:not(&:last-child) {
        margin-bottom: 12px;
    }
`

const Text = styled.div`
    color: ${styles.Color.TaekusGrey2};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    ${styles.MediaQueries.Mobile} {
        font-size: 14px;
    }
`

const Title = styled.div`
    color: ${styles.Color.TaekusGrey1};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-bottom: 16px;
    ${styles.MediaQueries.Mobile} {
        font-size: 24px;
    }
`

export default UserDetails