import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import { AnimatePresence, motion } from "framer-motion";
import { styled } from "styled-components"

import { SignupStep } from "components/signup/constants";
import { updateApplication, updateLead, updateStep } from "components/signup/signupSlice";
import { CompanyStructure, StepDirection } from "components/signup/types";

import ArrowButton, { ArrowDirection } from "components/signup/components/ArrowButton";
import Button from "components/signup/components/Button";
import Input from "components/signup/components/Input";
import Selectable from "components/signup/components/Selectable";
import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";

import styles from "styles/styles";

const dynamicInputMotionProps = {
    initial: { height: 0, opacity: 0, padding: 0, marginTop: 0 },
    exit: { height: 0, opacity: 0, padding: 0, marginTop: 0 },
    animate: { height: '72px', opacity: 1, padding: '8px', marginTop: '32px' }
}

const CaptureBizDBA = () => {
    const dispatch = useDispatch()
    
    const inputRef = useRef(null)

    const application = useSelector((state: any) => state.signup.application)
    const leadUuid = useSelector((state: any) => state.signup.leadUuid)
    const stepDirection = useSelector((state: any) => state.signup.direction)

    const [hasDBA, setHasDBA] = useState<boolean | undefined>(!!application.dbaName || undefined)
    const [dba, setDBA] = useState(application.dbaName || '')

    const isInputInvalid = hasDBA === undefined || (hasDBA === true && dba === '')

    const handleBack = (ev: React.MouseEvent) => {
        ev.preventDefault();
        ev.stopPropagation();
        dispatch(updateStep({
            step: application.companyStructure === CompanyStructure.SoleProp ? SignupStep.BizType : SignupStep.BizName,
            direction: StepDirection.Left
        }))
    }

    const handleSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();
        const applicationChanges = { 
            bizHasDBA: hasDBA, 
            dbaName: hasDBA ? dba : undefined 
        }

        dispatch(updateApplication(applicationChanges))
        dispatch(updateLead({ leadUuid, application: applicationChanges }))
        dispatch(updateStep({ step: SignupStep.BizEIN, direction: StepDirection.Right }))
    }

    // Autofocus DBA input when user selects yes (or if step mounts and user has already selected yes)
    useEffect(() => {
        if (hasDBA) {
            (inputRef.current as any)?.focus()
        }
    }, [hasDBA])

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='BusinessDBA'
    >
        <form className="d-flex flex-column align-items-center" onSubmit={handleSubmit}>
            <Title>Do you have a DBA?</Title>
            <Text>A DBA is formally registered with your state of incoroporation. If you don't have one, select no.</Text>
            <SelectableMargin>
                <Selectable onClick={() => { setHasDBA(false) }} selected={hasDBA === false} label='No'/>
            </SelectableMargin>
            <Selectable onClick={() => { setHasDBA(true) }} selected={hasDBA === true} label='Yes'/>
            <AnimatePresence mode='wait'>
                {hasDBA && <motion.div {...dynamicInputMotionProps} style={{overflow: 'hidden'}}>
                    <InputContainer>
                        <Input parentRef={inputRef} value={dba} onChange={(ev) => { setDBA(ev.target.value) }} label="DBA"/>
                    </InputContainer>
                </motion.div>}
            </AnimatePresence>
            <ButtonContainer>
                <BackButtonContainer>
                    <ArrowButton onClick={handleBack} direction={ArrowDirection.Left}/>
                </BackButtonContainer>
                <Button disabled={isInputInvalid}>Submit</Button>
            </ButtonContainer>
        </form>
    </StepContainer>
}

const BackButtonContainer = styled.div`
    margin-right: 16px;
`

const ButtonContainer = styled.div`
    margin-top: 32px;
    display: flex;
`

const InputContainer = styled.div``

const SelectableMargin = styled.div`
    margin-bottom: 8px;
`

const Text = styled.div`
    color: ${styles.Color.TaekusGrey2};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    margin-bottom: 32px;
    ${styles.MediaQueries.Mobile} {
        font-size: 14px;
    }
`

const Title = styled.div`
    color: ${styles.Color.TaekusBlack};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-bottom: 16px;
    ${styles.MediaQueries.Mobile} {
        font-size: 24px;
    }
`

export default CaptureBizDBA