import React from "react"
import styled from "styled-components"
import styles from "styles/styles"
import { ReactComponent as Checkmark } from "assets/svg/Checkbox.svg"

type CheckboxProps = {
    checked: boolean;
    onChange: (props: any) => void;
    disabled?: boolean;
    id?: string;
}

const Checkbox = (props: CheckboxProps) => {
    return (
        <CheckboxContainer
            id={props.id}
            disabled={props.disabled}
            onClick={props.disabled ? undefined : props.onChange}
            checked={props.checked}
        >
            <StyledCheckmark checked={props.checked} />
        </CheckboxContainer>
    )
}

const CheckboxContainer = styled.div<{ disabled?: boolean, checked?: boolean }>`
    width: 16px;
    height: 16px;
    border-radius: 1px;
    background-color: ${props => props.disabled ? styles.Color.Grey : styles.Color.White};
    ${props => !props.disabled && 'cursor: pointer;'}
    border: ${props => (props.disabled || props.checked) ? 'none' : '1px solid ' + styles.Color.TaekusGrey4};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`

const StyledCheckmark = styled(Checkmark)<{ checked: boolean }>`
    opacity: ${props => props.checked ? 1 : 0};
    transform: scale(${props => props.checked ? 1 : 0.5});
    transition: all 0.2s ease-in-out;
`

export default Checkbox