import React from 'react'
import styled from 'styled-components'
import styles from 'styles/styles'
import { ReactComponent as TaekusLogoSVG } from "assets/svg/TaekusIcon.svg"

type PaymentDetailRowProps = {
    label: string
    showDivider?: boolean
    showTaekusIcon?: boolean
    value: React.ReactNode
}

export const PaymentDetailRow = ({ 
    label, 
    showDivider = false, 
    showTaekusIcon = false, 
    value 
}: PaymentDetailRowProps) => {
    return (
        <>
            <Container>
                <Label>{label}</Label>
                <ValueContainer>
                    {showTaekusIcon && (
                        <IconContainer>
                            <StyledTaekusLogo />
                        </IconContainer>
                    )}
                    <Value>
                        {value}
                    </Value>
                </ValueContainer>
            </Container>
            {showDivider && <Divider />}
        </>
    )
}


const StyledTaekusLogo = styled(TaekusLogoSVG)`
    width: 100%;
    height: 100%;
    fill: ${styles.Color.TaekusGrey1};
`

const Container = styled.div`
    display: flex;
    padding: 16px 0px;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
        padding: 0;
        gap: 8px;
    }
`

const Label = styled.div`
    color: ${styles.Color.TaekusGrey3};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%;
    letter-spacing: 0.32px;
    width: 240px;
`

const ValueContainer = styled.div`
    display: flex;
    gap: 8px;
    flex: 1 0 0;
    align-items: center;
`

const Value = styled.div`
    color: ${styles.Color.TaekusGrey1};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%;
    letter-spacing: 0.36px;
`

const IconContainer = styled.div`
    display: flex;
    width: 24px;
    height: 24px;
    padding: 1.572px 0px 1.857px 0px;
    justify-content: center;
    align-items: center;

    svg {
        width: 24px;
        height: 24px;
    }

    ${styles.MediaQueries.Mobile} {
        width: 16px;
        height: 16px;
        padding: 0;
        
        svg {
            width: 16px;
            height: 16px;
        }
    }
`
export const Divider = styled.div`
    height: 1px;
    width: 100%;
    background-color: ${styles.Color.TaekusGrey4};
    
    ${styles.MediaQueries.Mobile} {
        display: none;
    }
`
