import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import { styled } from "styled-components"

import { UsernameError } from "components/signup/errors";
import messages from "components/signup/messages";
import {  validateUsernameUniqueness } from "components/signup/signupSlice";
import { validateUsername } from "components/signup/validators";

import Button from "components/signup/components/Button";
import Input from "components/signup/components/Input";
import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";

import styles from "styles/styles";

const Username = () => {
    const dispatch = useDispatch()

    const isLoading = useSelector((state: any) => state.signup.isLoading)
    const isUsernameUnique = useSelector((state: any) => state.signup.isUsernameValid)
    const stepDirection = useSelector((state: any) => state.signup.direction)

    const inputRef = useRef(null)

    const [username, setUsername] = useState('')
    const [showErrors, setShowErrors] = useState(false)

    const usernameError = validateUsername(username)
    const isUsernameValid = usernameError === undefined
    
    const getUsernameErrorMessage = () => {
        if (isUsernameUnique === false) {
            return messages.Errors.Username[UsernameError.Unique]
        }
        return usernameError !== undefined ? messages.Errors.Username[usernameError] : undefined
    }
    const errorMessage = getUsernameErrorMessage()

    const handleSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();
        if (isUsernameValid) {
            dispatch(validateUsernameUniqueness(username))
        }
        setShowErrors(true)
    }

    const handleInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.value.length <= 40 && /^[a-zA-Z0-9@.+-_]*$/.test(ev.target.value)) {
            setUsername(ev.target.value)
        }
    }

    // Focus username input on mount
    useEffect(() => {
        (inputRef.current as any)?.focus()
    }, [])

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='Username'
    >
        <form className="d-flex flex-column align-items-center" onSubmit={handleSubmit}>
            <Title>Let’s set up your Taekus login.</Title>
            <Text>Choose a username to use when you log in to Taekus.</Text>
            <Input 
                errorMessage={showErrors && errorMessage} 
                onChange={handleInputChange} 
                value={username} 
                setValue={setUsername} 
                parentRef={inputRef} 
                label="Username"
            />
            <ButtonRow>
                <Button isLoading={isLoading} type="submit">Next</Button>
            </ButtonRow>
        </form>
    </StepContainer>
}

const ButtonRow = styled.div`
    margin-top: 32px;
`

const Text = styled.div`
    color: ${styles.Color.TaekusGrey2};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    margin-bottom: 32px;
    ${styles.MediaQueries.Mobile} {
        font-size: 14px;
        line-height: 18px; /* 128.571% */
        margin-bottom: 24px;
    }
`

const Title = styled.div`
    color: ${styles.Color.TaekusGrey1};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-bottom: 24px;
    ${styles.MediaQueries.Mobile} {
        font-size: 24px;
        line-height: 124%; /* 29.76px */
        letter-spacing: 0.24px;
        margin-bottom: 16px;
    }
`

export default Username