import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"

import moment from "moment"

import { motion } from "framer-motion";
import styled from "styled-components"

import { LinkedAccount } from "types/LinkedAccount"

import { AppPath } from "components/appRouter/constants"

import { DropdownKeys } from "redux/features/global/constants"
import { updateDropdownKey } from "redux/features/global/globalSlice"

import Button, { ButtonSize, ButtonType } from "components/common/Button"
import DatePicker from "components/common/DatePicker/DatePicker"
import StatusDisplay from "components/common/StatusDisplay"

import AccountsDropdown from "components/pages/Funding/Payment/AccountsDropdown"
import { defaultCreditPaymentOptionErrors } from "components/pages/Funding/Payment/constants";
import { messages } from "components/pages/Funding/Payment/messages"
import { getAccountLabel, getAccountName, sortPaymentAccounts } from "components/pages/Funding/Payment/util"

import { fadeInOutMotionProps } from "styles/motionConstants"
import styles from "styles/styles"

import { ReactComponent as CalendarSVG } from "assets/svg/Calendar.svg";
import { ReactComponent as CaretDown } from "assets/svg/CaretDown.svg";
import { Medium } from "components/common/Typography";

import { CardAccountType } from "types/CardAccount";

import { PaymentOption } from "../types"
import { PaymentAmountOption } from "./PaymentAmountOption"
import { Container } from "../styles";

export type OptionProps = {
    amount: string
    updateAmount: (amount: string) => void
    sourceAccount?: LinkedAccount
    updateSourceAccount: (account?: LinkedAccount) => void
    paymentDate: moment.Moment
    updatePaymentDate: (date: moment.Moment) => void
    incrementStep: () => void
    totalBalance: number
    minimumPaymentDue: number
    formattedPaymentDueDate: string
    isMinimumPaymentOverdue: boolean
    selectedOption: PaymentOption
    setSelectedOption: (option: PaymentOption) => void
}

const CreditPaymentForm = (props: OptionProps) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const {
        amount,
        updateAmount,
        sourceAccount,
        updateSourceAccount,
        paymentDate,
        updatePaymentDate,
        incrementStep,
        totalBalance,
        minimumPaymentDue,
        formattedPaymentDueDate,
        isMinimumPaymentOverdue,
        selectedOption,
        setSelectedOption,
    } = props;

    const isMobile = useSelector((state: any) => state.global.isMobile)
    const isAccountDropdownOpen = useSelector((state: any) => state.global.dropdownKey) === DropdownKeys.Funding.Account;
    const externalAccounts = useSelector((state: any) => state.linkedAccounts.linkedAccounts)
    const internalNonCreditAccounts = useSelector((state: any) => state.linkedAccounts.internalAccounts.filter((account: any) => account.type !== CardAccountType.Credit))
    const selectedAccountName = useSelector((state: any) => {
        const accountUuid = state.banking.account.uuid;
        const account = state.linkedAccounts.internalAccounts.find((account: any) => account.uuid === accountUuid);
        return account ? `${getAccountName(account)} -${account.accountLast4}` : '';
    });

    const [isSourceDropdownSelected, setIsSourceDropdownSelected] = useState(true)
    const [optionsErrors, setOptionsErrors] = useState(defaultCreditPaymentOptionErrors)
    const [amountWarning, setAmountWarning] = useState<string | null>(null)

    const topOptionError = Object.values(optionsErrors).find(error => !!error)

    const getVisibleAccounts = () => {
        const oppositeAccount = isSourceDropdownSelected ? sourceAccount : sourceAccount

        return [...externalAccounts, ...internalNonCreditAccounts]
            .filter(account => account.uuid !== oppositeAccount?.uuid)
            .sort(sortPaymentAccounts)
    }

    const toggleAccountDropdown = (ev: React.MouseEvent) => {
        ev.stopPropagation()
        const selectedOtherDropdown = isSourceDropdownSelected !== ((ev.target as HTMLInputElement).id === 'source')
        dispatch(updateDropdownKey(isAccountDropdownOpen && !selectedOtherDropdown ? '' : DropdownKeys.Funding.Account))
        setIsSourceDropdownSelected((ev.target as HTMLInputElement).id === 'source')
    }

    const handleAccountClick = (account: any) => {
        const oppositeAccount = isSourceDropdownSelected ? sourceAccount : sourceAccount

        // If both the selected and opposite accounts are external, unselect the opposite account
        if (!account.isInternalAccount && !oppositeAccount?.isInternalAccount) { 
            isSourceDropdownSelected ? updateSourceAccount(undefined) : updateSourceAccount(undefined)
        }
        // If both accounts are internal, set the transfer date to today
        if (account.isInternalAccount && oppositeAccount?.isInternalAccount) {
            updatePaymentDate(moment())
        }
        isSourceDropdownSelected ? updateSourceAccount(account) : updateSourceAccount(account) 
    }

    const validateAmount = (amount: string) => {
        if (amount === '' || amount === '-.--') {
            return messages.DebitPayment.Options.Errors.EmptyAmount
        }
        if (isNaN(Number(amount.replaceAll(',', '')))) {
            return messages.DebitPayment.Options.Errors.InvalidAmount
        }
        if ((sourceAccount?.availableBalance !== undefined && Number(amount.replaceAll(',', '')) > sourceAccount?.availableBalance)) {
            return messages.DebitPayment.Options.Errors.InsufficientFunds
        }

        return null;
    }

    const validateDate = (date: moment.Moment) => {
        const dueDate = moment(formattedPaymentDueDate, 'MMMM D')
        // Add .startOf('day') to both dates to ignore time components
        if (!date.startOf('day').isSameOrBefore(dueDate.startOf('day'))) {
            return `Your payment will be processed after the due date, ${formattedPaymentDueDate}. A late fee may apply.`
        }
        return null;
    }

    const validateOptions = () => {
        const errors = {
            amount: validateAmount(amount),
            sourceAccount: sourceAccount === undefined ? messages.DebitPayment.Options.Errors.EmptySourceAccount : null,
            date: null,
        }
        setOptionsErrors(errors)
        return !Object.values(errors).some(error => !!error)
    }

    const handleButtonClick = () => {
        if (validateOptions()) {
            incrementStep();
        }
    }

    const validateAmountInput = (amount: string) => {
        const numAmount = Number(amount.replaceAll(',', ''))
        if (numAmount < minimumPaymentDue) {
            setAmountWarning(messages.CreditPayment.Options.Errors.TooSmall)
        } else if (numAmount > totalBalance) {
            setAmountWarning(messages.CreditPayment.Options.Errors.TooLarge)
        } else {
            setAmountWarning(null)
        }
    }

    const errorMessage = topOptionError || amountWarning
    const isSubmitDisabled = !sourceAccount || !paymentDate || !amount || Number(amount.replaceAll(',', '')) === 0 || Number(amount.replaceAll(',', '')) > totalBalance

    return <Container {...fadeInOutMotionProps} key='options'>
        {isMinimumPaymentOverdue && <StatusContainer>
                <StatusDisplay isLoading={false} hideIcon isError={true} label={`Your minimum payment is overdue. Your next payment due is ${formattedPaymentDueDate}.`}/>
            </StatusContainer>
        }
        <OptionsContainer>
            <Header>
                Pay Bill for<Medium>{selectedAccountName}</Medium>
            </Header>
            
                <AmountSection>
                    <PaymentAmountOption
                        isSelected={selectedOption === PaymentOption.TotalBalance}
                        title={PaymentOption.TotalBalance}
                        subtitle="Total of all posted (not pending) charges, interest, credits and payments on your account."
                        amount={totalBalance}
                        onClick={() => {
                            setSelectedOption(PaymentOption.TotalBalance)
                            updateAmount(totalBalance.toString())
                            setAmountWarning(null)
                        }}
                    />
                    <PaymentAmountOption
                        isSelected={selectedOption === PaymentOption.MinimumPayment}
                        title={PaymentOption.MinimumPayment}
                        amount={minimumPaymentDue}
                        onClick={() => {
                            setSelectedOption(PaymentOption.MinimumPayment)
                            updateAmount(minimumPaymentDue.toString())
                            setAmountWarning(null)
                        }}
                    />
                    <PaymentAmountOption
                        isSelected={selectedOption === PaymentOption.Other}
                        title={PaymentOption.Other}
                        amount={selectedOption === PaymentOption.Other ? Number(amount) || undefined : ''}
                        onClick={() => {
                            setSelectedOption(PaymentOption.Other)
                            updateAmount('')
                            setAmountWarning(null)
                        }}
                        isOther={true}
                        onAmountChange={(value) => {
                            updateAmount(value)
                            validateAmountInput(value)
                        }}
                    />
                </AmountSection>
                <FormGroup>
                    <AccountsRow>
                        <Field>
                            <Label>Pay From</Label>
                            <DropdownInputWrapper>
                                <DropdownInput
                                    data-testid='sourceAccount'
                                    id='source'
                                    account={sourceAccount}
                                    onClick={toggleAccountDropdown}
                                    onChange={(ev: any) => { ev.stopPropagation() }}
                                >
                                    {getAccountLabel(sourceAccount, false) || "Source Account"}
                                </DropdownInput>
                                <CaretContainer>
                                    <StyledCaretDown/>
                                </CaretContainer>
                            </DropdownInputWrapper>
                            {(isAccountDropdownOpen && isSourceDropdownSelected) && <AccountsDropdown
                                accounts={getVisibleAccounts()}
                                onClick={handleAccountClick}
                            />}
                        </Field>
                    </AccountsRow>
                    <ExternalAccountLink onClick={() => { history.push(AppPath.ExternalAccounts) }}>Manage External Accounts</ExternalAccountLink>
                </FormGroup>
                <FormGroup>
                    <Field>
                        <Label>Date</Label>
                        <DateContainer>
                            <DatePicker
                                id='datePicker'
                                selected={paymentDate}
                                startDate={paymentDate}
                                onChange={(date) => { 
                                    updatePaymentDate(moment(date))
                                    const dateError = validateDate(moment(date))
                                    setOptionsErrors(prev => ({
                                        ...prev,
                                        date: dateError
                                    }))
                                }}
                                customInput={<DateInput>
                                    {paymentDate.format('MMM D, YYYY')}
                                    <StyledCalendarIcon/>
                                </DateInput>}
                                minDate={moment()}
                                maxDate={moment().add(60, 'days')}
                                disabled={sourceAccount?.isInternalAccount}
                            />
                            {sourceAccount?.isInternalAccount && <DateHelperText>Payments from internal accounts are instant.</DateHelperText>}
                            {!sourceAccount?.isInternalAccount && <DateHelperText>Payments must be posted by 8:00 CST to be posted with today's date.</DateHelperText>}
                        </DateContainer>
                    </Field>
                </FormGroup>
        </OptionsContainer>
        {errorMessage && 
            <motion.div {...fadeInOutMotionProps}>
                <ErrorMessage>{errorMessage}</ErrorMessage>
            </motion.div>
        }
        <ActionSection>
            <Button
                data-testid='optionsButton'
                size={ButtonSize.Wide}
                buttonType={ButtonType.Purple}
                label='Make Payment'
                onClick={handleButtonClick}
                disabled={isSubmitDisabled}
            />
        </ActionSection>
    </Container>
}

const ErrorMessage = styled.div`
    color: #C91616; 
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
`

const StyledCalendarIcon = styled(CalendarSVG)`
    height: 18px;
    width: auto;
    margin-right: 12px;
`

const Header = styled.div`
    display: flex;
    padding: 8px 12px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    background-color: #EDEDED;
    border-radius: 4px;

    ${styles.MediaQueries.Mobile} {
        display: flex;
        padding: 12px;
        align-items: flex-start;
        gap: 8px;
    }
`

const OptionsContainer = styled.div`
    display: flex;
    width: 632px;
    padding: 8px 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;

    ${styles.MediaQueries.Mobile} {
        width: 100%;
        padding: 0;
    }
`

const AmountSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
`

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const Field = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 100%;
    ${styles.MediaQueries.Mobile} {
        width: 100%;
        &:not(&:only-child) {
            margin-bottom: 16px;
        }
    }
`

const Label = styled.div<{ disabled?: boolean }>`
    color: ${styles.Color.TaekusGrey2};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.14px;
    margin-bottom: 8px;
    ${props => props.disabled && 'opacity: 0.5;'}
`

const AccountsRow = styled.div`
    display: flex;
    ${styles.MediaQueries.Desktop} {
        align-items: end;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
        flex-direction: column;
    }
`

const ActionSection = styled.div`
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
    gap: 16px;
    align-items: flex-start;
`

const StatusContainer = styled.div`
    min-height: 24px;
    ${styles.MediaQueries.Desktop} {
        margin-bottom: 0px;
    }
    ${styles.MediaQueries.Mobile} {
        margin-bottom: 24px;
    }
`

const DateContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`

const DateHelperText = styled.div`
    color: ${styles.Color.TaekusGrey2};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.12px;
    margin-left: 4px;
`

const DateInput = styled.div`
    color: ${styles.Color.NearBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 29.76px */
    letter-spacing: 0.48px;
    cursor: pointer;
    border-bottom: 1px solid ${styles.Color.Black};
    padding-bottom: 8px;
    ${styles.MediaQueries.Desktop} {
        width: 416px;
        font-size: 24px;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
        font-size: 20px;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${styles.Animation.transitionStyles}
    &:hover {
        border-bottom: 1px solid ${styles.Color.TaekusPurple};
    }
`

const CaretContainer = styled.div`
    position: absolute;
    pointer-events: none;
    right: 0;
    top: 0;
    height: 100%;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const StyledCaretDown = styled(CaretDown)`
    margin-bottom: 8px;
    width: 12px;
    height: 12px;
`

const DropdownInputWrapper = styled.div`
    position: relative;
    overflow: hidden;
`

type DropdownInputProps = {
    account: any,
}

const DropdownInput = styled.div<DropdownInputProps>`
    color: ${props =>  props.account === undefined ? 'rgba(0,0,0,0.5)' : styles.Color.Black};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    ${styles.MediaQueries.Desktop} {
        font-size: 24px;
        width: 416px;
    }
    ${styles.MediaQueries.Mobile} {
        font-size: 20px;
        width: 100%;
        max-width: 100%;
    }
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 29.76px */
    letter-spacing: 0.48px;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: 0;
    border-bottom: 1px solid ${styles.Color.Black};
    caret-color: transparent;
    padding-bottom: 8px;
    padding-right: 24px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    ${styles.Animation.transitionStyles}
    &:hover, &:focus {
        border-bottom: 1px solid ${styles.Color.TaekusPurple};
    }
`

const ExternalAccountLink = styled.a`
    color: ${styles.Color.TaekusPurple};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 19.32px */
    letter-spacing: 0.28px;
    cursor: pointer;
    user-select: none;
    width: min-content;
    white-space: nowrap;
    margin-left: 4px;
    &:hover {
        color: ${styles.Color.TaekusPurple};
    }
`

export default CreditPaymentForm