// hooks/useAccountTypes.ts
import { useSelector } from 'react-redux';
import { CardAccountType } from 'types/CardAccount';

interface AccountTypeInformation {
  hasCreditAccount: boolean;
  hasDebitAccount: boolean;
  hasOnlyCredit: boolean;
  hasOnlyDebit: boolean;
  isSelectedAccountCredit: boolean;
  isSelectedAccountDebit: boolean;
  isLoading: boolean;
}

export const useAccountTypeInformation = (): AccountTypeInformation => {
  const cardAccounts = useSelector((state: any) => 
    state.currentUser.currentUser.cardAccounts
  );
  const selectedAccount = useSelector((state: any) => 
    state.banking.account
  );
  const isLoadingUser = useSelector((state: any) => 
    state.currentUser.isFetching
  );
  const isLoadingBanking = useSelector((state: any) => 
    state.banking.isLoading
  );

  const creditAccounts = cardAccounts.filter(
    (account: any) => account.productType === CardAccountType.Credit
  );
  const debitAccounts = cardAccounts.filter(
    (account: any) => account.productType === CardAccountType.Debit
  );

    return {
      // default values
      hasCreditAccount: creditAccounts.length > 0,
      hasDebitAccount: debitAccounts.length > 0,
      hasOnlyCredit: creditAccounts.length > 0 && debitAccounts.length === 0,
      hasOnlyDebit: debitAccounts.length > 0 && creditAccounts.length === 0,
      isSelectedAccountCredit: selectedAccount.accountType === CardAccountType.Credit,
      isSelectedAccountDebit: selectedAccount.accountType === CardAccountType.Debit,
      isLoading: isLoadingUser || isLoadingBanking,
    };
};