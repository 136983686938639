import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "redux/currentUser";
import { getNAICSCodes } from '../../common/naicsConstants'

import styled from "styled-components";
import styles from "styles/styles";
import Select from 'components/common/Select/Select'

import { Medium } from "components/common/Typography";

enum CompanyStructure {
    LLC ='LLC',
    C_CORP = 'C_CORP',
    S_CORP = 'S_CORP',
    SOLE_PROP = 'SOLE_PROPRIETORSHIP',
    PARTNERSHIP = 'PARTNERSHIP'
}

const messages = {
    companyStructure: {
        [CompanyStructure.LLC]: 'llc',
        [CompanyStructure.C_CORP]: 'C Corp',
        [CompanyStructure.S_CORP]: 'S Corp',
        [CompanyStructure.SOLE_PROP]: 'Sole Proprietorship',
        [CompanyStructure.PARTNERSHIP]: 'Partnership',
    }
}

const sanitizeCompanyStructure = (companyStructure: any) => {
    switch (companyStructure) {
        case CompanyStructure.LLC:
            return messages.companyStructure[CompanyStructure.LLC]
        case CompanyStructure.C_CORP:
            return messages.companyStructure[CompanyStructure.C_CORP]
        case CompanyStructure.S_CORP:
            return messages.companyStructure[CompanyStructure.S_CORP]
        case CompanyStructure.SOLE_PROP:
            return messages.companyStructure[CompanyStructure.SOLE_PROP]
        case CompanyStructure.PARTNERSHIP:
            return messages.companyStructure[CompanyStructure.PARTNERSHIP]
        default:
            return ""
    }
}


const Business = () => {
    // Redux state
    const dispatch = useDispatch()
    const currentUser = useSelector((state: any) => state.currentUser.currentUser)
    const parentUser = currentUser.parentUser
    const businessDetails = useSelector((state: any) => state.currentUser.currentUser.business)
    const businessAccounts = currentUser.cardAccounts.filter((cardAccount: any) => cardAccount?.isBusiness)
    const businessAccountOptions = businessAccounts.map((businessAccount: any) => ({
        value: businessAccount.uuid,
        label: businessAccount.name
    }))

    // Component state
    const [bizAccountUuid, setBizAccountUuid] = useState<any>(null)
    const naicsConstants = getNAICSCodes()

    const getIndustry = (naicsCode: Number) => {
        const industry = naicsConstants.find( (naicsObj: any) => naicsObj.value === naicsCode)
        return industry?.label ?? "Unknown"
    }

    const updateCurrentBusinessAccount = (option: any) => {
        setBizAccountUuid(option.value)
    }

    useEffect(() => {
        if (businessAccounts.length) {
            setBizAccountUuid(businessAccounts[0].uuid)
        }
    }, [currentUser.cardAccounts]) // eslint-disable-line

    useEffect(() => {
        if (bizAccountUuid) {
            dispatch(Actions.getBusinessDetails({
                cardAccountUuid: bizAccountUuid
            }))
        }
    }, [bizAccountUuid, dispatch])

    return <div>
        <Title>Business</Title>
        {parentUser ? <HelpText><b>Acting as an authorized user for {parentUser.firstName} {parentUser.lastName}</b></HelpText>:<></>}
        <SettingItem>
            <div>Account</div>
            <Select
                value={businessAccountOptions.find((option: any) => option.value === bizAccountUuid)}
                onChange={updateCurrentBusinessAccount}
                options={businessAccountOptions}
            />
        </SettingItem>
        <SettingItem>
            <div>Company Name</div>
            <CustomMediumText>{businessDetails?.companyName}</CustomMediumText>
        </SettingItem>
        <SettingItem>
            <div>Company Structure</div>
            <CustomMediumText>{businessDetails && sanitizeCompanyStructure(businessDetails?.companyStructure)}</CustomMediumText>
        </SettingItem>
        <SettingItem>
            <div>Phone Number</div>
            <CustomMediumText>{businessDetails?.companyPhone}</CustomMediumText>
        </SettingItem>
        {
            businessDetails?.businessAddressStreet && businessDetails?.businessAddressCity && businessDetails?.businessAddressState &&
            <SettingItem>
                <div>Address</div>
                <CustomMediumText>
                    <div>{businessDetails?.businessAddressStreet}</div>
                    <div>{businessDetails?.businessAddressCity}, {businessDetails?.businessAddressState} {businessDetails?.businessAddressZip}</div>
                </CustomMediumText>
            </SettingItem>
        }
        {
            businessDetails?.website && <SettingItem>
                <div>Website</div>
                <CustomMediumText>{businessDetails?.website}</CustomMediumText>
            </SettingItem>
        }
        {
            businessDetails?.dbaName &&
            <SettingItem>
                <div>Doing Business As (DBA) Name</div>
                <CustomMediumText>{businessDetails?.dbaName}</CustomMediumText>
            </SettingItem>
        }
    </div>
}

// Text Styles
const Title = styled.div`
    ${styles.Text.DisplayMedium}
    width: 100%;
    padding-bottom: ${styles.Spacing.XS};
    height: 50px;
    border-bottom: 1px solid grey;
`

const HelpText = styled.div`
     ${styles.Text.BodySmall}
    font-size: ${styles.Font.Size.Small};
    padding-top: ${styles.Spacing.S};
    text-align: center;
    color: ${styles.Color.NearBlack};
    opacity: 0.75;
`

const CustomMediumText = styled(Medium)`
    text-align: right;
`

const SettingItem = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk}
    display: flex;
    height: 100px;
    display: flex;
    &:not(&:last-child) {
        border-bottom: 1px solid #D7D7D7;
    }
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
        justify-content: space-between;
        padding: ${styles.Spacing.XS} 0;
    }
    ${styles.MediaQueries.Desktop} {
        align-items: center;
        justify-content: space-between;
    }
`

export default Business;