export const messages = {
    DebitPayment: {
        Options: {
            Errors: {
                InvalidAmount: 'Amount must be a valid number.',
                EmptyAmount: 'Amount cannot be blank.',
                EmptySourceAccount: 'Source Account cannot be blank.',
                EmptyDestinationAccount: 'Destination Account cannot be blank.',
                InsufficientFunds: `Amount cannot be greater than the source account's balance.`,
            }
        }
    },
    CreditPayment: {
        Options: {
            Errors: {
                TooSmall: 'The amount you entered is less than the minimum payment due. You may be charged late fees if you do not pay the minimum amount due by the due date.',
                TooLarge: 'The amount cannot be greater than the current balance.',
            }
        }
    }
}