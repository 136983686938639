import React from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import NumberFormat from 'react-number-format'
import styles from 'styles/styles'

type SelectableOptionProps = {
    isSelected: boolean
    title: string
    subtitle?: string
    onClick: () => void
    showSubtitleWhenUnselected?: boolean
}

export const SelectableOption = (props: SelectableOptionProps) => {
    const { isSelected,  title, subtitle, onClick, showSubtitleWhenUnselected } = props;

    return (
        <Wrapper onClick={onClick} isSelected={isSelected}>
            <Radio isSelected={isSelected} />
            <TextContainer>
                <Title isSelected={isSelected}>{title}</Title>
                <AnimatePresence>
                    {subtitle && (showSubtitleWhenUnselected ? true : isSelected) && (
                        <motion.div
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: "auto", opacity: 1 }}
                            exit={{ height: 0, opacity: 0 }}
                            transition={{ 
                                duration: 0.2,
                                opacity: { duration: 0.15 }
                            }}
                            style={{ overflow: 'hidden' }}
                        >
                            <Subtitle>{subtitle}</Subtitle>
                        </motion.div>
                    )}
                </AnimatePresence>
            </TextContainer>

        </Wrapper>
    )
}

const Wrapper = styled.div<{ isSelected: boolean }>`
    display: flex;
    padding: 16px;
    min-height: 62px;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    cursor: pointer;
    border: 1px solid ${({ isSelected }) => 
        isSelected ? styles.Color.TaekusPurple : styles.Color.TaekusGrey4};
    background: ${({ isSelected }) => 
        isSelected 
            ? 'linear-gradient(0deg, rgba(124, 61, 118, 0.04), rgba(124, 61, 118, 0.04))' 
            : styles.Color.Transparent};
    ${styles.Animation.transitionStyles}
    
    &:hover {
        border: 1px solid ${({ isSelected }) => 
            isSelected ? styles.Color.TaekusPurple : styles.Color.TaekusGrey4};
        background: ${({ isSelected }) => 
            isSelected 
                ? 'linear-gradient(0deg, rgba(124, 61, 118, 0.04), rgba(124, 61, 118, 0.04))'
                : styles.Color.White};
    }
`

const Radio = styled.div<{ isSelected: boolean }>`
    width: 24px;
    height: 24px;
    border-radius: 100px;
    border: 1px solid ${({ isSelected }) => 
        isSelected ? styles.Color.TaekusPurple : styles.Color.TaekusGrey4};
    background: ${styles.Color.White};
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:after {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 100px;
        background: ${styles.Color.TaekusPurple};
        opacity: ${({ isSelected }) => isSelected ? 1 : 0};
    }
`

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
`

const Title = styled.div<{ isSelected: boolean }>`
    color: ${({ isSelected }) => 
        isSelected ? styles.Color.TaekusBlack : styles.Color.TaekusGrey2};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`

const Amount = styled.div<{ isSelected: boolean }>`
    color: ${({ isSelected }) => 
        isSelected ? styles.Color.TaekusBlack : styles.Color.TaekusGrey1};
    text-align: right;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
`

const Subtitle = styled.div`
    color: ${styles.Color.TaekusGrey1};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
`

const Input = styled.input`
    border: none;
    background: transparent;
    text-align: right;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    color: ${styles.Color.TaekusBlack};
    caret-color: ${styles.Color.TaekusPurple};
    caret-width: 10px;
    
    &:focus {
        outline: none;
    }
`
