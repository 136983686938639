import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import { styled } from "styled-components"

import { SignupStep } from "components/signup/constants";
import messages from "components/signup/messages";
import { updateApplication, updateLead, updateStep } from "components/signup/signupSlice";
import { ProductType, CompanyStructure, StepDirection } from "components/signup/types";

import ArrowButton, { ArrowDirection } from "components/signup/components/ArrowButton";
import Button from "components/signup/components/Button";
import Selectable from "components/signup/components/Selectable";
import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";

import styles from "styles/styles";

const CaptureBizType = () => {
    const dispatch = useDispatch()
    
    const user = useSelector((state: any) => state.currentUser.currentUser)
    const application = useSelector((state: any) => state.signup.application)
    const leadUuid = useSelector((state: any) => state.signup.leadUuid)
    const products = useSelector((state: any) => state.signup.products)
    const stepDirection = useSelector((state: any) => state.signup.direction)

    const [isInputDisabled, setIsInputDisabled] = useState(false)
    const [bizType, setBizType] = useState<CompanyStructure | undefined>(application.companyStructure || undefined)

    const selectedProduct = products?.find((product: any) => product.productCode === application.productCode)
    const isButtonDisabled = bizType === undefined || isInputDisabled

    const goBack = (ev: any) => { 
        ev.stopPropagation();
        const isCreditApplication = selectedProduct.productType === ProductType.Credit

        dispatch(updateStep({ 
            step: isCreditApplication ? SignupStep.Housing : (user ? SignupStep.InitialReview : SignupStep.Email),
            direction: StepDirection.Left
        }))
    }

    const updateBizType = (ev: React.MouseEvent<HTMLButtonElement>) => {
        setBizType((ev.target as any).id)
    }
    
    const handleSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();
        setIsInputDisabled(true)
        const applicationChanges = { companyStructure: bizType }

        dispatch(updateApplication(applicationChanges))
        dispatch(updateLead({ leadUuid, application: applicationChanges }))
        dispatch(updateStep({
            step: bizType === CompanyStructure.SoleProp ? SignupStep.BizDBA : SignupStep.BizName,
            direction: StepDirection.Right
        }))
    }

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='BusinessStructure'
    >
        <form className="d-flex flex-column align-items-center" onSubmit={handleSubmit}>
            <Title>Let’s talk business. What structure does your business have?</Title>
            {selectedProduct?.acceptsSoleProps && <SelectableMargin>
                <Selectable 
                    id={CompanyStructure.SoleProp}
                    onClick={updateBizType}
                    selected={bizType === CompanyStructure.SoleProp}
                    label={messages.BizTypes[CompanyStructure.SoleProp]}
                />
            </SelectableMargin>}
            <SelectableMargin>
                <Selectable
                    id={CompanyStructure.LLC}
                    onClick={updateBizType}
                    selected={bizType === CompanyStructure.LLC}
                    label={messages.BizTypes[CompanyStructure.LLC]}
                />
            </SelectableMargin>
            <SelectableMargin>
                <Selectable
                    id={CompanyStructure.cCorp}
                    onClick={updateBizType}
                    selected={bizType === CompanyStructure.cCorp}
                    label={messages.BizTypes[CompanyStructure.cCorp]}
                />
            </SelectableMargin>
            <Selectable
                id={CompanyStructure.sCorp}
                onClick={updateBizType}
                selected={bizType === CompanyStructure.sCorp}
                label={messages.BizTypes[CompanyStructure.sCorp]}
            />
            <ButtonContainer>
                <BackButtonContainer>
                    <ArrowButton onClick={goBack} direction={ArrowDirection.Left}/>
                </BackButtonContainer>
                <Button disabled={isButtonDisabled} type="submit">Next</Button>
            </ButtonContainer>
        </form>
    </StepContainer>
}

const BackButtonContainer = styled.div`
    margin-right: 16px;
`

const ButtonContainer = styled.div`
    margin-top: 32px;
    display: flex;
`

const SelectableMargin = styled.div`
    margin-bottom: 8px;
`

const Title = styled.div`
    color: ${styles.Color.TaekusBlack};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 39.68px */
    letter-spacing: 0.32px;
    margin-bottom: 32px;
    ${styles.MediaQueries.Mobile} {
        font-size: 24px;
    }
`

export default CaptureBizType