import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import { AnimatePresence, motion } from "framer-motion";
import { styled } from "styled-components"

import { SignupStep } from "components/signup/constants";
import { updateStep } from "components/signup/signupSlice";
import { ProductCategory, ProductType, StepDirection } from "components/signup/types";

import CommonButton from "components/signup/components/Button";
import Checkbox from "components/signup/components/Checkbox";

import { expandVerticallyMotionProps, fadeInOutMotionProps } from "styles/motionConstants";
import styles from "styles/styles";

const ElectronicFundsTransferTermsLink = 'https://taekus.com/legal/eft-terms'
const RewardsAgreementLink = 'https://taekus.com/legal/rewards-terms'
const CreditTermsLink = 'https://taekus.com/legal/credit-terms'

const messages = {
    [ProductType.Credit]: {
        title: ", you're pre-approved for a Taekus Credit Account!",
        subtitle: 'The only credit card with next-gen travel rewards',
    },
    [ProductType.Debit]: {
        title: ', open your Taekus Debit Account!',
        subtitle: 'All Taekus debit cards are issued by ',
    },
}

const Offer = () => {
    const dispatch = useDispatch()
    
    const imgRef = useRef(null)

    const isMobile = useSelector((state: any) => state.global.isMobile)
    const offer = useSelector((state: any) => state.signup.offer)
    const userExists = useSelector((state: any) => state.signup.userExists)
    const application = useSelector((state: any) => state.signup.application)
    const products = useSelector((state: any) => state.signup.products)

    const [showError, setShowError] = useState(false)
    const [isImageLoaded, setIsImageLoaded] = useState(false)
    const [isCardholderAgreed, setIsCardholderAgreed] = useState(false)
    const [isECDAgreed, setIsECDAgreed] = useState(false)
    const [isCreditReportAgreed, setIsCreditReportAgreed] = useState(false)

    const selectedProduct = products?.find((product: any) => product.productCode === application.productCode)
    const isCreditProduct = selectedProduct?.productType === ProductType.Credit
    const isBusinessDebit = selectedProduct?.productType === ProductType.Debit && selectedProduct?.productCategory === ProductCategory.Business
    const isAgreementsInvalid = !(isCardholderAgreed && isECDAgreed && (!isCreditProduct || isCreditReportAgreed))

    const disclaimerMessage = `This account will be issued by ${selectedProduct?.bankName}`

    const handleSubmit = () => {
        if (isAgreementsInvalid) {
            setShowError(true)
        } else {
            setShowError(false)

            const nextCreditStep = offer?.needDocumentVerification ? SignupStep.KYCUpload : SignupStep.ReviewDisclosure
            const nextDebitStep = (userExists ? SignupStep.VerifyEmail : SignupStep.CreateUsername)
            const nextStep = isCreditProduct ? nextCreditStep : nextDebitStep
            dispatch(updateStep({
                step: nextStep,
                direction: StepDirection.Right
            }))
        }
    }

    const loadBackgroundImage = () => {
        const bgImage = new Image();

        bgImage.onload = () => {
            if (imgRef.current) {
                (imgRef?.current as any).style.backgroundImage = `url(${bgImage.src})`;
            }
            setIsImageLoaded(true)
        }
        bgImage.onerror = () => {
            setIsImageLoaded(true)
        }

        bgImage.src = isMobile ? selectedProduct?.productImageUrl : selectedProduct?.heroImageUrl;
    }

    // On component mount
    useEffect(() => {
        loadBackgroundImage()
    }, [isMobile]) // eslint-disable-line

    const debitBullets = selectedProduct?.promotionalInformation?.bullets?.map((bullet: any, index: number) => <GridItem key={index}>
        <GridTitle>{bullet.title}</GridTitle>
        <GridSubtitle>{bullet.text}</GridSubtitle>
    </GridItem>)
    const multipliers = selectedProduct?.promotionalMultipliers?.multipliers?.map((multiplier: any, index: number) => <MultiplierContainer key={index}>
        <Multiplier>{multiplier.value}X</Multiplier>
        <MultiplierLabel>{multiplier.label}</MultiplierLabel>
    </MultiplierContainer>)

    return isMobile ? <MobileContainer>
        <MobileProductImage ref={imgRef} $isimageLoaded={isImageLoaded}/>
        <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '40px 16px'}}>
            <Title>{application.firstName}{messages[selectedProduct?.productType as ProductType]?.title}</Title>
            {isBusinessDebit && <Subtitle>Make managing business expenses easier when you open a FDIC-insured Taekus Business Checking Account.</Subtitle>}
            {selectedProduct.productType === ProductType.Debit ? <Grid>{debitBullets}</Grid> : <>
                <DetailContainer>
                    <div style={{flex: 1}}>
                        <DetailTitle>Credit Limit</DetailTitle>
                        <CreditLimit>${Number(offer?.credit?.creditLimit).toLocaleString()}</CreditLimit>
                    </div>
                    <div style={{ height: '41px', width: '1px', backgroundColor: styles.Color.TaekusGrey4, margin: '0 16px' }}/>
                    <div style={{flex: 1}}>
                        <DetailTitle>Interest Rate</DetailTitle>
                        <InterestRate>{offer?.credit?.apr}% APR {selectedProduct?.isInterestRateVariable ? 'variable' : ''}</InterestRate>
                    </div>
                </DetailContainer>
                <MultiplierRow>{multipliers}</MultiplierRow>
            </>}
            <Agreements>
                <CheckboxContainer htmlFor="cardholder">
                    <Checkbox id='cardholder' checked={isCardholderAgreed} onClick={(ev) => { ev?.preventDefault(); setIsCardholderAgreed(!isCardholderAgreed) }}/>
                    <CheckboxLabel>I agree and consent to the <Link target='_blank' href={selectedProduct?.cardHolderAgreementUrl || CreditTermsLink}>Taekus Cardholder Agreement</Link> and <Link target='_blank' href={selectedProduct?.rewardsTermsUrl || RewardsAgreementLink}>Rewards Agreement</Link>.</CheckboxLabel>
                </CheckboxContainer>
                <CheckboxContainer htmlFor="ecd">
                    <Checkbox id='ecd' checked={isECDAgreed} onClick={(ev) => { ev?.preventDefault(); setIsECDAgreed(!isECDAgreed) }}/>
                    <CheckboxLabel>I have reviewed and agree to the terms of the <Link target="_blank" href={selectedProduct?.electronicFundsTransferUrl || ElectronicFundsTransferTermsLink}>Electronic Funds Transfer Disclosure</Link>.</CheckboxLabel>
                </CheckboxContainer>
                {isCreditProduct && <CheckboxContainer htmlFor="report">
                    <Checkbox id='report' checked={isCreditReportAgreed} onClick={(ev) => { ev?.preventDefault(); setIsCreditReportAgreed(!isCreditReportAgreed) }}/>
                    <CheckboxLabel>By accepting this offer, I authorize Taekus to obtain information to evaluate my income and to request my consumer report from credit reporting agencies, which is a hard inquiry that may affect my credit.</CheckboxLabel>
                </CheckboxContainer>}
            </Agreements>
            <CommonButton onClick={handleSubmit}>Open Account</CommonButton>
            <AnimatePresence mode='wait'>
                {(showError && isAgreementsInvalid) && <motion.div style={{width: 'fit-content'}} {...expandVerticallyMotionProps}>
                    <ErrorMessage>You must accept all agreements to continue.</ErrorMessage>
                </motion.div>}
            </AnimatePresence>
            <Disclaimer>{disclaimerMessage}</Disclaimer>
        </div>
    </MobileContainer> : <Container
        {...fadeInOutMotionProps}
        key='Offer'
    >
        <ImageContainer ref={imgRef} $isimageLoaded={isImageLoaded}>
            <Content>
                <Title>{application.firstName}{messages[selectedProduct?.productType as ProductType]?.title}</Title>
                <Subtitle>{messages[selectedProduct?.productType as ProductType]?.subtitle}{selectedProduct?.productType === ProductType.Debit ? `${selectedProduct?.bankName}.` : ''}</Subtitle>
                {selectedProduct?.productType === ProductType.Debit ? <Grid>{debitBullets}</Grid> : <>
                    <DetailContainer>
                        <div style={{flex: 1}}>
                            <DetailTitle>Credit Limit</DetailTitle>
                            <CreditLimit>${Number(offer?.credit?.creditLimit).toLocaleString()}</CreditLimit>
                        </div>
                        <div style={{ height: '41px', width: '1px', backgroundColor: styles.Color.TaekusGrey4, margin: '0 16px' }}/>
                        <div style={{flex: 1}}>
                            <DetailTitle>Interest Rate</DetailTitle>
                            <InterestRate>{offer?.credit?.apr}% APR {selectedProduct?.isInterestRateVariable ? 'variable' : ''}</InterestRate>
                        </div>
                    </DetailContainer>
                    <MultiplierRow>{multipliers}</MultiplierRow>
                </>}
                <Agreements>
                    <CheckboxContainer htmlFor="cardholder">
                        <Checkbox id='cardholder' checked={isCardholderAgreed} onClick={(ev) => { ev?.preventDefault(); setIsCardholderAgreed(!isCardholderAgreed) }}/>
                        <CheckboxLabel>I agree and consent to the <Link target='_blank' href={selectedProduct?.cardHolderAgreementUrl || CreditTermsLink}>Taekus Cardholder Agreement</Link> and <Link target='_blank' href={selectedProduct?.rewardsTermsUrl || RewardsAgreementLink}>Rewards Agreement</Link>.</CheckboxLabel>
                    </CheckboxContainer>
                    <CheckboxContainer htmlFor="ecd">
                        <Checkbox id='ecd' checked={isECDAgreed} onClick={(ev) => { ev?.preventDefault(); setIsECDAgreed(!isECDAgreed) }}/>
                        <CheckboxLabel>I have reviewed and agree to the terms of the <Link target="_blank" href={selectedProduct?.electronicFundsTransferUrl || ElectronicFundsTransferTermsLink}>Electronic Funds Transfer Disclosure</Link>.</CheckboxLabel>
                    </CheckboxContainer>
                    {isCreditProduct && <CheckboxContainer htmlFor="report">
                        <Checkbox id='report' checked={isCreditReportAgreed} onClick={(ev) => { ev?.preventDefault(); setIsCreditReportAgreed(!isCreditReportAgreed) }}/>
                        <CheckboxLabel>By accepting this offer, I authorize Taekus to obtain information to evaluate my income and to request my consumer report from credit reporting agencies, which is a hard inquiry that may affect my credit.</CheckboxLabel>
                    </CheckboxContainer>}
                </Agreements>
                <CommonButton onClick={handleSubmit}>Open Account</CommonButton>
                <AnimatePresence mode='wait'>
                    {(showError && isAgreementsInvalid) && <motion.div style={{width: 'fit-content'}} {...expandVerticallyMotionProps}>
                        <ErrorMessage>You must accept all agreements to continue.</ErrorMessage>
                    </motion.div>}
                </AnimatePresence>
                <Disclaimer>{disclaimerMessage}</Disclaimer>
            </Content>
        </ImageContainer>
    </Container>
}

const ErrorMessage = styled.div`
    color: ${styles.Color.TaekusRed};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 14.88px */
    letter-spacing: 0.24px;
    padding: 8px 16px 0;
`

const Link = styled.a`
    text-decoration: underline;
    color: #767ED8 !important;
    ${styles.MediaQueries.Mobile} {
        color: ${styles.Color.TaekusBlue} !important;
    }
`

const Disclaimer = styled.div`
    margin-top: 24px;
    color: ${styles.Color.TaekusGrey3};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    ${styles.MediaQueries.Mobile} { 
        margin-top: 24px;
        font-size: 12px;
    }
`

const MultiplierLabel = styled.div`
    color: ${styles.Color.TaekusGrey5};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
    ${styles.MediaQueries.Mobile} { 
        color: ${styles.Color.TaekusGrey3};
        font-size: 12px;
        line-height: 16px; /* 133.333% */
    }
`

const Multiplier = styled.div`
    color: ${styles.Color.White};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 60px; /* 125% */
    ${styles.MediaQueries.Mobile} { 
        color: ${styles.Color.TaekusBlack};
        font-size: 32px;
        line-height: 40px; /* 125% */
        letter-spacing: 0.32px;
    }
`

const MultiplierContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.20);
    flex: 1;
    &:not(&:first-child) {
        margin-left: 8px;
    }
    &:not(&:last-child) {
        margin-right: 8px;
    }
    ${styles.MediaQueries.Mobile} { 
        border-top: 1px solid ${styles.Color.TaekusGrey4};
    }
`

const MultiplierRow = styled.div`
    display: flex;
    width: 100%;
    margin-top: 40px;
    ${styles.MediaQueries.Mobile} {
        margin-top: 16px;
    }
`

const InterestRate = styled.div`
    margin-top: 8px;
    color: ${styles.Color.TaekusCream};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 124%; /* 17.36px */
    letter-spacing: 0.14px;
    ${styles.MediaQueries.Mobile} {
        color: ${styles.Color.TaekusGrey1};
    }
`

const CreditLimit = styled.div`
    margin-top: 4px;
    color: ${styles.Color.White};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 124%; /* 24.8px */
    letter-spacing: 0.2px;
    ${styles.MediaQueries.Mobile} {
        color: ${styles.Color.TaekusGrey1};
    }
`

const DetailTitle = styled.div`
    color: ${styles.Color.White};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 12.4px */
    letter-spacing: 0.4px;
    text-transform: uppercase;
    ${styles.MediaQueries.Mobile} {
        color: ${styles.Color.TaekusGrey3};
    }
`

const DetailContainer = styled.div`
    padding: 16px 24px;
    display: flex;
    width: 100%;
`

const CheckboxContainer = styled.label`
    display: flex;
    padding: 12px 24px;
    margin: 0;
    width: 100%;
    border-radius: 4px;
    border: 1px solid transparent;
    cursor: pointer;
    user-select: none;
    background-color: rgba(255, 255, 255, 0);
    backdrop-filter: blur(0px);
    &:hover {
        background-color: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(50px);
    }
    ${styles.MediaQueries.Mobile} {
        border: 1px solid ${styles.Color.TaekusGrey5};
        background: ${styles.Color.White} !important;
        backdrop-filter: blur(50px);
    }
    ${styles.Animation.transitionStyles}
`

const CheckboxLabel = styled.div`
    color: ${styles.Color.TaekusGrey4};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 14.88px */
    letter-spacing: 0.24px;
    margin: 0 0 0 16px;
    ${styles.MediaQueries.Mobile} {
        color: ${styles.Color.TaekusGrey3};
    }
`

type AgreementsProps = {
    centerContent?: boolean,
}

const Agreements = styled.div<AgreementsProps>`
    display: flex;
    flex-direction: column;
    ${props => !props.centerContent && 'align-items: start;'}
    margin: 40px 0;
    max-width: 600px;
    ${styles.MediaQueries.Mobile} {
        margin: 32px 0;
    }
`

const GridTitle = styled.div`
    color: ${styles.Color.White};
    text-align: center;
    /* Title - Small */
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
    ${styles.MediaQueries.Mobile} {
        color: ${styles.Color.TaekusGrey1};
        font-size: 12px;
        font-weight: 500;
        line-height: 16px; /* 133.333% */
        letter-spacing: 0.12px;
        margin-bottom: 4px;
    }
`

const GridSubtitle = styled.div`
    color: ${styles.Color.TaekusGrey3};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    ${styles.MediaQueries.Mobile} {
        color: ${styles.Color.TaekusGrey2};
        font-size: 10px;
        font-weight: 400;
        line-height: 12px; /* 120% */
    }
`

const GridItem = styled.div`
    flex-basis: calc(50% - 8px);
    padding: 16px 0;
    &:nth-child(-n+2) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.20);
        ${styles.MediaQueries.Mobile} {
            border-bottom: 1px solid rgba(153, 152, 152, 0.2);
        }
    }
    ${styles.MediaQueries.Mobile} {
        flex-basis: calc(50% - 8px);
    }
`

const Grid = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 24px;
    ${styles.MediaQueries.Mobile} {
        margin-bottom: 32px;
    }
`

const Title = styled.div`
    color: ${styles.Color.White};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 39.68px */
    letter-spacing: 0.32px;
    ${styles.MediaQueries.Mobile} {
        color: ${styles.Color.TaekusBlack};
        font-size: 24px;
        line-height: 124%; /* 29.76px */
        letter-spacing: 0.24px;
    }
`

const Subtitle = styled.div`
    margin-top: 16px;
    margin-bottom: 24px;
    color: ${styles.Color.TaekusGrey4};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    ${styles.MediaQueries.Mobile} {
        color: ${styles.Color.TaekusGrey2};
        font-size: 14px;
        margin-bottom: 16px;
        line-height: 18px; /* 128.571% */
    }
`

const Content = styled(motion.div)`
    min-width: 100px;
    width: 50%;
    min-height: 100px;
    margin: 0 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const MobileProductImage = styled.image<{ $isimageLoaded: boolean }>`
    display: flex;
    width: 100%;
    min-height: 347px;
    background-size: cover;
    background-position: center;
    background-color: #4E3259;
    opacity: ${props => props.$isimageLoaded ? 1 : 0};
    -moz-transition: opacity 0.2s ease-in;
    -o-transition: opacity 0.2s ease-in;
    -webkit-transition: opacity 0.2s ease-in;
    transition: opacity 0.2s ease-in;
`

const ImageContainer = styled.div<{ $isimageLoaded: boolean }>`
    width: 100dvw;
    height: 100dvh;
    display: flex;
    justify-content: end;
    background-size: cover;
    background-position: center;
    background-color: #4E3259;
    -moz-transition: opacity 0.2s ease-in;
    -o-transition: opacity 0.2s ease-in;
    -webkit-transition: opacity 0.2s ease-in;
    transition: opacity 0.2s ease-in;
    opacity: ${props => props.$isimageLoaded ? 1 : 0};
    color: white;
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

const Container = styled(motion.div)`
    width: 100dvw;
    height: 100dvh;
    background-color: #4E3259;
`

const MobileContainer = styled(motion.div)`
    width: 100dvw;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
`

export default Offer