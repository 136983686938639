import { LinkedAccount } from "types/LinkedAccount"

import { Locale, USDCurrencyOptions } from "utils/constants"

export const getAccountName = (account: LinkedAccount) => {
    if (!account.isInternalAccount) {
        return account.name
    }
    if (account.nickname && account.nickname !== '') {
        return account.nickname
    }
    return account.displayName
}

export const getAccountLabel = (account?: LinkedAccount, includeBalance: boolean = false) => {
    if (!account) {
        return undefined
    }
    return `${getAccountName(account)} -${account.accountLast4} ${account.availableBalance !== undefined && includeBalance ? `— ${account.availableBalance.toLocaleString(Locale.English, USDCurrencyOptions)}` : ''}`
}

/**
 * Sorts payment accounts with the following priority:
 * 1. Internal accounts before external accounts
 * 2. Active accounts before inactive accounts (for external accounts)
 * 3. Alphabetically by name
 */
export const sortPaymentAccounts = (a: LinkedAccount, b: LinkedAccount) => {
    // Use name if external account, or the affixed Taekus name for internal accounts
    const aName = a.name || `Taekus ${a.displayName}`
    const bName = b.name || `Taekus ${b.displayName}`

    const isAActive = a.verified && a.isTransferReady
    const isBActive = b.verified && b.isTransferReady

    // if both accounts aren't the same type, sort internals first
    if (a.isInternalAccount !== b.isInternalAccount) {
        return a.isInternalAccount ? -1 : 1
    }
    // if both accounts are external and aren't both active or inactive, sort active ones first
    if (a.isInternalAccount === b.isInternalAccount && isAActive !== isBActive) {
        return isAActive ? -1 : 1
    }
    // sort by name
    return aName.localeCompare(bName)
}