import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import { styled } from "styled-components"

import { validatePassword } from "components/signup/signupSlice";

import Button from "components/signup/components/Button";
import Input from "components/signup/components/Input";
import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";

import styles from "styles/styles";

const Password = () => {
    const dispatch = useDispatch()
    
    const isLoading = useSelector((state: any) => state.signup.isLoading)
    const stepDirection = useSelector((state: any) => state.signup.direction)
    const isPasswordValid = useSelector((state: any) => state.signup.isPasswordValid)

    const inputRef = useRef(null)

    const [password, setPassword] = useState('')
    const [showError, setShowError] = useState<boolean>(false)

    const hasUppercase = /[A-Z]/.test(password)
    const hasDigit = /\d/.test(password)
    const isCorrectLength = password.length >= 12
    const isInputValid = hasUppercase && hasDigit && isCorrectLength

    const handleSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();
        if (isInputValid) {
            setShowError(false)
            dispatch(validatePassword(password))
        } else {
            setShowError(true)
        }
    }

    const handleInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.value.length <= 28) {
            setPassword(ev.target.value)
        }
    }

    // Focus password input on mount
    useEffect(() => {
        (inputRef.current as any)?.focus()
    }, [])

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='Password'
    >
        <form className="d-flex flex-column align-items-center" onSubmit={handleSubmit}>
            <Title>Create your password</Title>
            <Input 
                type="password" 
                onChange={handleInputChange} 
                value={password} 
                setValue={setPassword} 
                parentRef={inputRef} 
                label="Password"
                errorMessage={isPasswordValid === false ? 'You must use a more complex password.' : ''}
            />
            <Rules>
                <div>Passwords must:</div>
                <Rule $error={showError && !hasUppercase} $valid={hasUppercase}>  ⦁  Contain at least one upper case letter.</Rule>
                <Rule $error={showError && !hasDigit} $valid={hasDigit}>  ⦁  Contain at least one digit.</Rule>
                <Rule $error={showError && !isCorrectLength} $valid={isCorrectLength}>  ⦁  Be at least 12 characters long.</Rule>
            </Rules>
            <ButtonRow>
                <Button isLoading={isLoading} type="submit">Next</Button>
            </ButtonRow>
        </form>
    </StepContainer>
}

type RuleProps = {
    $valid: boolean,
    $error: boolean,
}

const Rule = styled.div<RuleProps>`
    color: ${props => props.$valid ? styles.Color.TaekusGreenText : 'auto'};
    font-weight: ${props => props.$valid ? 600 : 'auto'};
    ${props => props.$error && `
        color: ${styles.Color.TaekusRed};
        font-weight: 600;
    `}
    -moz-transition: color .2s ease-in;
    -o-transition: color .2s ease-in;
    -webkit-transition: color .2s ease-in;
    transition: color .2s ease-in;
`

const Rules = styled.div`
    user-select: none;
    margin-top: 8px;
    padding: 0 16px;
    min-width: 308px;
    color: ${styles.Color.TaekusGrey2};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    white-space: pre-wrap;
    ${styles.MediaQueries.Mobile} {
        margin-top: 12px;
    }
`

const ButtonRow = styled.div`
    margin-top: 32px;
    ${styles.MediaQueries.Mobile} {
        margin-top: 24px;
    }
`

const Title = styled.div`
    color: ${styles.Color.TaekusGrey1};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-bottom: 32px;
    ${styles.MediaQueries.Mobile} {
        font-size: 24px;
        line-height: 124%; /* 29.76px */
        letter-spacing: 0.24px;
        margin-bottom: 24px;
    }
`

export default Password