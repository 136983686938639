import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"
import { CreditPaymentSteps, PaymentOption } from "./types"
import { Actions as TransferActions } from 'redux/features/banking/transfers'
import { fadeInOutMotionProps } from "styles/motionConstants"
import Spinner from "components/common/Spinner"
import CreditPaymentReviewForm from "./components/CreditPaymentReviewForm"
import CreditPaymentForm from "./components/CreditPaymentForm"
import { Redirect, useHistory } from "react-router-dom"
import { CardAccountType } from "types/CardAccount"
import { useAccountTypeInformation } from "hooks/useAccountTypeInformation"

const CreditPayment = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const isLoadingUser = useSelector((state: any) => state.currentUser.isFetching)
    const selectedAccount = useSelector((state: any) => state.banking.account);
    const totalBalance = Number(selectedAccount?.balance?.currentBalance)
    const minimumPaymentDue = Number(selectedAccount?.balance?.remainingMinimumPaymentDue)
    const paymentDueDay = selectedAccount?.paymentDueDate

    const formattedPaymentDueDate = moment().date(paymentDueDay || 1)
    if (moment().date() >= paymentDueDay) {
        formattedPaymentDueDate.add(1, 'months')
    }
    const formattedPaymentDueDateString = formattedPaymentDueDate.format('MMMM D, YYYY')
    
    const isMinimumPaymentOverdue = moment().date() > paymentDueDay

    const [currentStepIndex, setCurrentStepIndex] = useState(CreditPaymentSteps.Options)
    const [paymentDate, setPaymentDate] = useState(moment())
    const [amount, setAmount] = useState(totalBalance.toString())
    const [sourceAccount, setSourceAccount] = useState<any | undefined>(undefined)
    const [selectedOption, setSelectedOption] = useState<PaymentOption>(PaymentOption.TotalBalance)
    const {isSelectedAccountCredit, isLoading} = useAccountTypeInformation()

    useEffect(() => {
        if (isLoading) {
            return
        }
        if (!isSelectedAccountCredit) {
            window.location.href = '/funding'
            return
        }
    }, [isSelectedAccountCredit, isLoading, history])

    if (!isSelectedAccountCredit) {
        return <Redirect to='/funding' />
    }

    const incrementStep = () => {
        if (currentStepIndex === CreditPaymentSteps.Confirm) {
            setAmount('-.--')
            setPaymentDate(moment())
            setSourceAccount(undefined)
            dispatch(TransferActions.clearCancelTransfer())
        }
        setCurrentStepIndex(currentStepIndex < 2 ? currentStepIndex + 1 : 0)
    }

    const decrementStep = () => {
        setCurrentStepIndex(currentStepIndex - 1)
    }

    const resetForm = () => {
        setCurrentStepIndex(CreditPaymentSteps.Options)
        setAmount(totalBalance.toString())
        setPaymentDate(moment())
        setSourceAccount(undefined)
        setSelectedOption(PaymentOption.TotalBalance)
        dispatch(TransferActions.clearCancelTransfer())
    }

    const getStepContent = (step: number) => {
        switch (step) {
            case CreditPaymentSteps.Review:
                return <CreditPaymentReviewForm
                    amount={amount}
                    sourceAccount={sourceAccount}
                    paymentDate={paymentDate}
                    decrementStep={decrementStep}
                    incrementStep={incrementStep}
                    selectedOption={selectedOption}
                    resetForm={resetForm}
                />
            case CreditPaymentSteps.Options:
            default:
                return <CreditPaymentForm
                    amount={amount}
                    updateAmount={setAmount}
                    sourceAccount={sourceAccount}
                    updateSourceAccount={setSourceAccount}
                    paymentDate={paymentDate}
                    updatePaymentDate={setPaymentDate}
                    incrementStep={incrementStep}
                    totalBalance={totalBalance}
                    minimumPaymentDue={minimumPaymentDue}
                    formattedPaymentDueDate={formattedPaymentDueDateString}
                    isMinimumPaymentOverdue={isMinimumPaymentOverdue}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                />
        }
    }

    return (
        <CreditPaymentContainer {...fadeInOutMotionProps}>
            <AnimatePresence mode='wait'>
                {isLoadingUser ? (
                    <SpinnerContainer key='spinner' {...fadeInOutMotionProps}>
                        <Spinner size={40}/>
                    </SpinnerContainer>
                ) : (
                    getStepContent(currentStepIndex)
                )}
            </AnimatePresence>
        </CreditPaymentContainer>
    )
}

const CreditPaymentContainer = styled(motion.div)`
    width: 100%;
    height: 100%;
`

const SpinnerContainer = styled(motion.div)`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
`

export default CreditPayment