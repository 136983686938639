import React, { Component } from 'react'
import ReactGA from 'react-ga4'
import { connect } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { DropdownKeys } from 'redux/features/global/constants'
import { updateDropdownKey, updateIsMobile, updateScreenSize } from 'redux/features/global/globalSlice'
import { getWindowScreenSize } from 'redux/features/global/utils'

import AppRouter from 'components/appRouter/appRouter'
import AppContainer from 'components/AppContainer'

import Login from 'components/authentication/Login'
import SignupAuthorizedUser from 'components/authentication/SignupAuthorizedUser'
import SignupNoAUInvite from 'components/authentication/SignupNoAUInvite' // SignupNoAUInvite
import SignupAlreadyLoggedIn from 'components/authentication/SignupAlreadyLoggedIn'
import AccountRecovery from 'components/authentication/AccountRecovery'

import Signup from 'components/signup/Signup'
import Welcome from 'components/signup/steps/Welcome'
import VerifyFailed from 'components/signup/steps/VerifyFailed'

class App extends Component {
  componentDidMount() {
    if (window.env.REACT_APP_DEBUG !== 'true') {
      ReactGA.initialize(`${window.env.REACT_APP_GA_ID}`)
      ReactGA.send({
        hitType: 'pageview',
        page: `${window.location.pathname + window.location.search}`,
      })
    }

    // Clicking outside the dropdown component should close it
    document.onclick = this.closeDropdowns
    // Update Redux isMobile state
    window.matchMedia('(max-width: 768px)').addEventListener('change', (ev) => {
      this.props.dispatch(updateIsMobile(ev.matches))
    })
    window.addEventListener("resize", () => {
      this.props.dispatch(updateScreenSize(getWindowScreenSize(window)))
    });
  }

  closeDropdowns = () => {
    if(this.props.dropdownKey !== DropdownKeys.Closed) {
      this.props.dispatch(updateDropdownKey(DropdownKeys.Closed))
    }
  }

  render() {
    const debug = window.env.REACT_APP_DEBUG === 'true'

    return (
      <div className="App">
        <Helmet>
          {debug && <meta name="robots" content="noindex"></meta>}
          {debug ? (
            <script type="text/javascript" src="https://ci-mpsnare.iovation.com/snare.js"></script>
          ) : (
            <script type="text/javascript" src="https://mpsnare.iesnare.com/snare.js"></script>
          )}
        </Helmet>
        <BrowserRouter>
          <AppContainer>
            <Switch>
              <Route exact path="/login/" render={(props) => <Login {...props} />} />
              <Route exact path="/signup-authorized-user/" render={(props) => <SignupAuthorizedUser {...props} />}/>
              <Route exact path="/signup-authorized-user/no-invite/" render={(props) => <SignupNoAUInvite {...props} />} />
              <Route exact path="/signup-authorized-user/already-logged-in/" render={(props) => <SignupAlreadyLoggedIn {...props} />}/>
              <Route exact path="/account/recovery/" render={(props) => <AccountRecovery {...props} />} />
              <Route exact path="/signup/verified" render={(props) => <Welcome {...props} />} />
              <Route exact path="/signup/error" render={(props) => <VerifyFailed {...props} />} />
              <Route exact path="/signup" render={(props) => <Signup {...props} />} />
              <Route path="/" render={(props) => <AppRouter {...props} />} />
            </Switch>
          </AppContainer>
        </BrowserRouter>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.currentUser,
  ...state.global,
})

export default connect(mapStateToProps, null)(App)