import React from 'react';
import styled from 'styled-components';
import { ReactComponent as RadioSVG } from "assets/svg/Radio.svg";
import styles from "styles/styles";

interface SuccessBannerProps {
    message: string;
}

const SuccessBanner = ({ message }: SuccessBannerProps) => {
    return (
        <Container>
            <RadioSVG width={40} height={40}/>
            <Message>{message}</Message>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    padding: 24px;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border: 1px solid rgba(113, 232, 189, 0.10);
    background: rgba(113, 232, 189, 0.10);

    ${styles.MediaQueries.Mobile} {
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
    }
`

const Message = styled.div`
    color: ${styles.Color.TaekusGreenText};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
`

export default SuccessBanner;