import moment from "moment"
import { 
    AddressError,
    BusinessIndustryError,
    BusinessStructureError,
    EINError,
    EmailError,
    FormationDateError,
    HousingError,
    IncomeError,
    PercentOwnershipError,
    SSNError,
    StringError,
    UsernameError 
} from "components/signup/errors"
import { CompanyStructure, HousingType } from "components/signup/types"

/*
 * The following functions act as validators for signup-related fields
 * They are intended to validate one field each (minus generic validators like string)
 * and return a unique error object that maps to an error message.
 */

export const validateString = (string: string) => {
    if (string.length < 2) {
        return StringError.TooShort
    } else if (string.length > 40) {
        return StringError.TooLong
    }
}

export const validateSSN = (ssn: string) => {
    if (ssn.length !== 9) {
        return SSNError.InvalidLength
    }
}

export const validateAddress = (address: any) => {
    if (address === undefined) {
        return AddressError.Required
    }
}

export const validateEmail = (email?: string) => {
    if (email === undefined || email === '') { 
        return EmailError.Required 
    } else if (!(/^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
        return EmailError.Format
    }
}

export const validateIncome = (income?: string) => {
    if (income === undefined || income === '') {
        return IncomeError.Required
    }
}

export const validateHousing = (housingType?: HousingType, housingPayment?: string) => {
    if (housingType === undefined) {
        return HousingError.Required
    } else if (housingType === HousingType.Rent && housingPayment === '') {
        return HousingError.PaymentTooLow
    }
}

export const validateBusinessStructure = (structure?: CompanyStructure) => {
    if (structure === undefined) {
        return BusinessStructureError.Required
    }
}

export const validateBusinessIndustry = (industry?: string) => {
    if (industry === undefined) {
        return BusinessIndustryError.Required
    }
}

export const validateEIN = (ein: string, companyStructure?: CompanyStructure) => {
    const isSoleProp = companyStructure === CompanyStructure.SoleProp
    if (!isSoleProp && ein === '') {
        return EINError.Required
    } else if (!(isSoleProp && ein === '') && (/[^\d-]/.test(ein) || ein.length !== 10)) {
        return EINError.Format
    } 
}

export const validateFormationDate = (date: string) => {
    if (date === undefined || date === '') {
        return FormationDateError.Required
    } else if (moment(date).isAfter(moment())) {
        return FormationDateError.FutureDate
    }  else if (moment().diff(moment(date), 'years') > 200) {
        return FormationDateError.DatePastMax
    }
}

const miminumPercentOwnership = 51
export const validatePercentOwnership = (percent: string) => {
    const asNumber = Number(percent)

    if (percent === undefined || percent === '' || isNaN(asNumber)) {
        return PercentOwnershipError.Required
    } else if (asNumber < miminumPercentOwnership) {
        // minimum % ownership is 25%
        return PercentOwnershipError.TooSmall
    } else if (asNumber > 100) {
        return PercentOwnershipError.TooLarge
    }
}

export const validateUsername = (username: string) => {
    if (username.length < 2) {
        return UsernameError.TooSmall
    } else if (username.length > 40) {
        return UsernameError.TooLarge
    }
}