import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import { AnimatePresence, motion } from "framer-motion";
import { styled } from "styled-components"

import { SignupStep } from "components/signup/constants";
import { updateStep } from "components/signup/signupSlice";
import { ProductCategory, ProductType, StepDirection } from "components/signup/types";

import Checkbox from "components/signup/components/Checkbox";
import CommonButton from "components/signup/components/Button";

import { expandVerticallyMotionProps, fadeInOutMotionProps } from "styles/motionConstants";
import styles from "styles/styles";

const ElectronicCommunicationsDisclosureLink = 'https://taekus.com/legal/electronic-communications-policy'
const ElectronicFundsTransferTermsLink = 'https://taekus.com/legal/eft-terms'
const RewardsAgreementLink = 'https://taekus.com/legal/rewards-terms'
const DebitTermsLink = 'https://taekus.com/legal/debit-terms'

const Offer = () => {
    const dispatch = useDispatch()
    
    const imgRef = useRef(null)

    const isMobile = useSelector((state: any) => state.global.isMobile)
    const products = useSelector((state: any) => state.signup.products)
    const upsellUuid = useSelector((state: any) => state.signup.upsellUuid)
    const userExists = useSelector((state: any) => state.signup.userExists)

    const upsellProduct = products?.find((product: any) => product.uuid === upsellUuid)
    const isBusinessDebit = upsellProduct?.productType === ProductType.Debit && upsellProduct?.productCategory === ProductCategory.Business

    const preselectTitle = `Open a ${upsellProduct?.displayName} Account`

    const [showError, setShowError] = useState(false)
    const [isImageLoaded, setIsImageLoaded] = useState(false)
    const [isCardholderAgreed, setIsCardholderAgreed] = useState(false)
    const [isECDAgreed, setIsECDAgreed] = useState(false)

    const isAgreementsInvalid = !(isCardholderAgreed && isECDAgreed)

    const handleSkip = () => {
        const nextStep = userExists ? SignupStep.VerifyEmail : SignupStep.CreateUsername

        dispatch(updateStep({
            step: nextStep,
            direction: StepDirection.Right
        }))
    }
    
    const handleSubmit = () => {
        if (isAgreementsInvalid) {
            setShowError(true)
        } else {
            setShowError(false)

            const nextStep = userExists ? SignupStep.VerifyEmail : SignupStep.CreateUsername
            dispatch(updateStep({
                step: nextStep,
                direction: StepDirection.Right
            }))
        }
    }

    const loadBackgroundImage = () => {
        const bgImage = new Image();

        bgImage.onload = () => {
            if (imgRef.current) {
                (imgRef?.current as any).style.backgroundImage = `url(${bgImage.src})`;
            }
            setIsImageLoaded(true)
        }
        bgImage.onerror = () => {
            setIsImageLoaded(true)
        }

        bgImage.src = isMobile ? upsellProduct?.productImageUrl : upsellProduct?.heroImageUrl;
    }

    // On component mount
    useEffect(() => {
        loadBackgroundImage()
    }, [isMobile]) // eslint-disable-line

    const debitBullets = upsellProduct?.promotionalInformation?.bullets?.map((bullet: any) => <GridItem>
        <GridTitle>{bullet.title}</GridTitle>
        <GridSubtitle>{bullet.text}</GridSubtitle>
    </GridItem>)

    const handleLearnMore = () => {
        window.open(upsellProduct?.learnMoreUrl, '_blank');
    };

    return isMobile ? <MobileContainer>
        <MobileProductImage ref={imgRef} isImageLoaded={isImageLoaded}/>
        <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '40px 16px'}}>
            <Title>{preselectTitle}</Title>
            {isBusinessDebit && <Subtitle>Make managing business expenses easier when you open a FDIC-insured Taekus Business Checking Account.</Subtitle>}
            <Grid>{debitBullets}</Grid>
            <Agreements>
                <CheckboxContainer htmlFor="cardholder">
                    <Checkbox id='cardholder' checked={isCardholderAgreed} onClick={(ev) => { ev?.preventDefault(); setIsCardholderAgreed(!isCardholderAgreed) }}/>
                    <CheckboxLabel>I agree and consent to the <Link target='_blank' href={upsellProduct?.cardHolderAgreementUrl || DebitTermsLink}>Taekus Cardholder Agreement</Link> and <Link target='_blank' href={upsellProduct?.rewardsTermsUrl || RewardsAgreementLink}>Rewards Agreement</Link>.</CheckboxLabel>
                </CheckboxContainer>
                <CheckboxContainer htmlFor="ecd">
                    <Checkbox id='ecd' checked={isECDAgreed} onClick={(ev) => { ev?.preventDefault(); setIsECDAgreed(!isECDAgreed) }}/>
                    <CheckboxLabel>I have reviewed and agree to the terms of the <Link target="_blank" href={upsellProduct?.electronicFundsTransferUrl || ElectronicFundsTransferTermsLink}>Electronic Funds Transfer Disclosure</Link>.</CheckboxLabel>
                </CheckboxContainer>
            </Agreements>
            <CommonButton onClick={handleSubmit}>Accept Offer</CommonButton>
            <AnimatePresence mode='wait'>
                {(showError && isAgreementsInvalid) && <motion.div style={{width: 'fit-content'}} {...expandVerticallyMotionProps}>
                    <ErrorMessage>You must accept all agreements to continue.</ErrorMessage>
                </motion.div>}
            </AnimatePresence>
        </div>
    </MobileContainer> : <Container key='Offer' {...fadeInOutMotionProps}>
        <ImageContainer ref={imgRef} isImageLoaded={isImageLoaded}>
            <AnimatePresence mode='wait'>
                <Content key='bonus' {...fadeInOutMotionProps}>
                    <Title>{`Congrats on your new credit card!\n Earn more rewards with a complimentary Taekus checking account`}</Title>
                    <Subtitle>We can use your information from your credit card application to open for a complimentary checking account with a few extra clicks.</Subtitle>
                    <Grid>{debitBullets}</Grid>
                    {upsellProduct?.learnMoreUrl && <Button onClick={handleLearnMore}>Learn More</Button>}
                    <Agreements $centercontent>
                        <CheckboxContainer htmlFor="cardholder">
                            <Checkbox id='cardholder' checked={isCardholderAgreed} onClick={(ev) => { ev?.preventDefault(); setIsCardholderAgreed(!isCardholderAgreed) }}/>
                            <CheckboxLabel>I agree and consent to the <Link target="_blank" href={upsellProduct?.cardHolderAgreementUrl || DebitTermsLink}>Taekus Cardholder Agreement</Link> and <Link target='_blank' href={upsellProduct?.rewardsTermsUrl || RewardsAgreementLink}>Rewards Agreement</Link>.</CheckboxLabel>
                        </CheckboxContainer>
                        <CheckboxContainer htmlFor="ecd">
                            <Checkbox id='ecd' checked={isECDAgreed} onClick={(ev) => { ev?.preventDefault(); setIsECDAgreed(!isECDAgreed) }}/>
                            <CheckboxLabel>I have reviewed and agree to the terms of the <Link target="_blank" href={upsellProduct?.electronicDisclosureUrl || ElectronicCommunicationsDisclosureLink}>Electronic Communications Disclosure</Link>. </CheckboxLabel>
                        </CheckboxContainer>
                    </Agreements>
                    <div style={{display: 'flex'}}>
                        <div style={{marginRight: '16px'}}>
                            <Button onClick={handleSkip}>Skip</Button>
                        </div>
                        <CommonButton onClick={handleSubmit}>Open Checking Account</CommonButton>
                    </div>
                    <AnimatePresence mode='wait'>
                        {(showError && isAgreementsInvalid) && <motion.div style={{width: 'fit-content'}} {...expandVerticallyMotionProps}>
                            <ErrorMessage>You must accept all agreements to continue.</ErrorMessage>
                        </motion.div>}
                    </AnimatePresence>
                </Content>
            </AnimatePresence>
        </ImageContainer>
    </Container>
}

const ErrorMessage = styled.div`
    color: ${styles.Color.TaekusRed};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 14.88px */
    letter-spacing: 0.24px;
    padding: 8px 16px 0;
`

const Link = styled.a`
    text-decoration: underline;
    color: #767ED8 !important;
    ${styles.MediaQueries.Mobile} {
        color: ${styles.Color.TaekusBlue} !important;
    }
`

const CheckboxContainer = styled.label`
    display: flex;
    justify-content: center;
    padding: 12px 24px;
    margin: 0;
    border-radius: 4px;
    border: 1px solid transparent;
    cursor: pointer;
    user-select: none;
    &:hover {
        background: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(50px);
    }
    ${styles.MediaQueries.Mobile} {
        border: 1px solid ${styles.Color.TaekusGrey5};
        background: ${styles.Color.White} !important;
        backdrop-filter: blur(50px);
    }
    ${styles.Animation.transitionStyles}
`

const CheckboxLabel = styled.div`
    color: ${styles.Color.TaekusGrey4};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 14.88px */
    letter-spacing: 0.24px;
    margin: 0 0 0 16px;
    ${styles.MediaQueries.Mobile} {
        color: ${styles.Color.TaekusGrey3};
    }
`

type AgreementsProps = {
    $centercontent?: boolean,
}

const Agreements = styled.div<AgreementsProps>`
    display: flex;
    flex-direction: column;
    ${props => !props.$centercontent && 'align-items: start;'}
    margin: 40px 0;
    max-width: 600px;
    ${styles.MediaQueries.Mobile} {
        margin: 32px 0;
    }
`

const Button = styled.button<any>`
    min-width: 120px;
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: transparent;
    padding: 10px 25px;
    border-radius: 2px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    color: ${styles.Color.White};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 19.32px */
    letter-spacing: 0.28px;
    &:hover {
        ${props => props.disabled && 'box-shadow: 0px 0px 16px 0px rgba(124, 61, 118, 0.15);'}
        border: 1px solid ${styles.Color.TaekusPurple};
    }
    ${styles.Animation.transitionStyles}
`

const GridTitle = styled.div`
    color: ${styles.Color.White};
    text-align: center;
    /* Title - Small */
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
    ${styles.MediaQueries.Mobile} {
        color: ${styles.Color.TaekusGrey1};
        font-size: 12px;
        font-weight: 500;
        line-height: 16px; /* 133.333% */
        letter-spacing: 0.12px;
        margin-bottom: 4px;
    }
`

const GridSubtitle = styled.div`
    color: ${styles.Color.TaekusGrey3};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    ${styles.MediaQueries.Mobile} {
        color: ${styles.Color.TaekusGrey2};
        font-size: 10px;
        font-weight: 400;
        line-height: 12px; /* 120% */
    }
`

const GridItem = styled.div`
    flex-basis: calc(50% - 8px);
    padding: 16px 0;
    &:nth-child(-n+2) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.20);
        ${styles.MediaQueries.Mobile} {
            border-bottom: 1px solid rgba(153, 152, 152, 0.2);
        }
    }
    ${styles.MediaQueries.Mobile} {
        flex-basis: calc(50% - 8px);
    }
`

const Grid = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 24px;
    ${styles.MediaQueries.Mobile} {
        margin-bottom: 32px;
    }
`

const Title = styled.div`
    color: ${styles.Color.White};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 39.68px */
    letter-spacing: 0.32px;
    ${styles.MediaQueries.Mobile} {
        color: ${styles.Color.TaekusBlack};
        font-size: 24px;
        line-height: 124%; /* 29.76px */
        letter-spacing: 0.24px;
    }
`

const Subtitle = styled.div`
    margin-top: 16px;
    margin-bottom: 24px;
    color: ${styles.Color.TaekusGrey4};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    ${styles.MediaQueries.Mobile} {
        color: ${styles.Color.TaekusGrey2};
        font-size: 14px;
        margin-bottom: 16px;
        line-height: 18px; /* 128.571% */
    }
`

const Content = styled(motion.div)`
    min-width: 100px;
    width: 50%;
    min-height: 100px;
    margin: 0 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const MobileProductImage = styled.image<{ isImageLoaded: boolean }>`
    display: flex;
    width: 100%;
    min-height: 347px;
    background-size: cover;
    background-position: center;
    background-color: #4E3259;
    opacity: ${props => props.isImageLoaded ? 1 : 0};
    -moz-transition: opacity 0.2s ease-in;
    -o-transition: opacity 0.2s ease-in;
    -webkit-transition: opacity 0.2s ease-in;
    transition: opacity 0.2s ease-in;
`

const ImageContainer = styled.div<{ isImageLoaded: boolean }>`
    width: 100dvw;
    height: 100dvh;
    display: flex;
    justify-content: end;
    background-size: cover;
    background-position: center;
    background-color: #4E3259;
    -moz-transition: opacity 0.2s ease-in;
    -o-transition: opacity 0.2s ease-in;
    -webkit-transition: opacity 0.2s ease-in;
    transition: opacity 0.2s ease-in;
    opacity: ${props => props.isImageLoaded ? 1 : 0};
    color: white;
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

const Container = styled(motion.div)`
    width: 100dvw;
    height: 100dvh;
    background-color: #4E3259;
`

const MobileContainer = styled(motion.div)`
    width: 100dvw;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
`

export default Offer