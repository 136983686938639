import React from "react"

import { styled } from "styled-components"

import styles from "styles/styles"

type CTAButtonProps = {
    children?: React.ReactNode,
    onClick: any,
    color?: string,
}

const CTAButton = (props: CTAButtonProps) => {
    return <Container onClick={props.onClick} color={props.color}>
        {props.children}
    </Container>
}

type ContainerProps = {
    color?: string,
}

const Container = styled.button<ContainerProps>`
    display: flex;
    align-items: center;
    height: 36px;
    color: ${props => props.color || styles.Color.TaekusBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 19.84px */
    letter-spacing: 0.32px;
    border-radius: 2px;
    background: transparent;
    padding: 8px 8px 8px 12px;
    border: 0;
    stroke: ${styles.Color.TaekusBlack};
    ${styles.Animation.transitionStyles}
    &:hover {
        background: rgba(124, 61, 118, 0.10);
    }
    &:active {
        color: ${styles.Color.TaekusPurple};
        stroke: ${styles.Color.TaekusPurple};
        background: rgba(124, 61, 118, 0.20);
    }
`

export default CTAButton