import { CreditPaymentOptionErrors, DebitPaymentOptionErrors } from "components/pages/Funding/Payment/types";

export const defaultDebitPaymentOptionErrors: DebitPaymentOptionErrors = {
    amount: null,
    sourceAccount: null,
    destinationAccount: null,
    date: null,
}

export const defaultCreditPaymentOptionErrors: CreditPaymentOptionErrors = {
    amount: null,
    sourceAccount: null,
    date: null,
}