import styled from 'styled-components'
import { motion } from 'framer-motion'
import styles from 'styles/styles'

export const Container = styled(motion.div)`
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    gap: 24px;
    margin-top: 24px;

    ${styles.MediaQueries.Mobile} {
        margin-top: 0px;
        padding-top: 24px;
    }
`

export const ActionSection = styled.div`
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
    gap: 16px;
    align-items: flex-start;
`

export const StatusContainer = styled.div`
    min-height: 24px;
    ${styles.MediaQueries.Desktop} {
        margin-bottom: 0px;
    }
    ${styles.MediaQueries.Mobile} {
        margin-bottom: 24px;
    }
`