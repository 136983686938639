import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import { styled } from "styled-components"

import { SignupStep } from "components/signup/constants";
import { updateApplication, updateLead, updateStep } from "components/signup/signupSlice";
import { StepDirection } from "components/signup/types";
import { formatAsUSD } from "components/signup/utils";

import ArrowButton, { ArrowDirection } from "components/signup/components/ArrowButton";
import Button from "components/signup/components/Button";
import CurrencyInput from "components/signup/components/CurrencyInput";
import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";

import styles from "styles/styles";

const CaptureIncome = () => {
    const dispatch = useDispatch()
    
    const inputRef = useRef(null)

    const user = useSelector((state: any) => state.currentUser)
    const application = useSelector((state: any) => state.signup.application)
    const leadUuid = useSelector((state: any) => state.signup.leadUuid)
    const stepDirection = useSelector((state: any) => state.signup.direction)

    const [income, setIncome] = useState(Number(application.totalAnnualIncome) ? Number(application.totalAnnualIncome)?.toLocaleString() : '')
    const [isInputDisabled, setIsInputDisabled] = useState(false)

    const isButtonDisabled = isInputDisabled || Number(income) === 0

    const goBack = (ev: any) => {
        ev.stopPropagation();
        dispatch(updateStep({ step: user.userLoaded ? SignupStep.InitialReview : SignupStep.Email, direction: StepDirection.Left }))
    }

    const handleInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const asNumber = Number(ev.target.value.replaceAll(',',''))
        if (ev.target.value === '' || (asNumber > 0 && String(asNumber).length <= 9)) {
            const inputValue = ev.target.value;
            const formattedValue = formatAsUSD(inputValue);
            setIncome(formattedValue);
        }
    }

    const handleSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();
        setIsInputDisabled(true)
        const applicationChanges = { totalAnnualIncome: income.replaceAll(',','') }

        dispatch(updateApplication(applicationChanges))
        dispatch(updateLead({ leadUuid, application: applicationChanges }))
        dispatch(updateStep({ step: SignupStep.Housing, direction: StepDirection.Right }))
    }

    // Focus income input on mount
    useEffect(() => {
        (inputRef.current as any)?.focus()
    }, [])

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='Income'
    >
        <form className="d-flex flex-column align-items-center" onSubmit={handleSubmit}>
            <Title>What’s your total annual income?</Title>
            <Text>Your total gross annual income is what you earn or expect to earn. If you include non-taxable income (income that you or your tax advisor has determined isn't subject to income tax), increase that amount by 25%.</Text>
            <CurrencyInput value={income} onChange={handleInputChange} parentRef={inputRef} label="Annual Income"/>
            <ButtonContainer>
                <BackButtonContainer>
                    <ArrowButton onClick={goBack} direction={ArrowDirection.Left}/>
                </BackButtonContainer>
                <Button disabled={isButtonDisabled} type="submit">Next</Button>
            </ButtonContainer>
        </form>
    </StepContainer>
}

const BackButtonContainer = styled.div`
    margin-right: 16px;
`

const ButtonContainer = styled.div`
    margin-top: 32px;
    display: flex;
`

const Text = styled.div`
    color: ${styles.Color.TaekusGrey2};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    margin-bottom: 16px;
    ${styles.MediaQueries.Mobile} {
        font-size: 14px;
    }
`

const Title = styled.div`
    color: ${styles.Color.TaekusGrey1};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-bottom: 32px;
    ${styles.MediaQueries.Mobile} {
        font-size: 24px;
    }
`

export default CaptureIncome