import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import { styled } from "styled-components"

import { useIsFirstRender } from "hooks/useIsFirstRender";
import { validateEmail } from "utils/utils";

import { SignupStep } from "components/signup/constants";
import { updateApplication, updateLead, updateStep } from "components/signup/signupSlice";
import { ProductType, StepDirection, ProductCategory } from "components/signup/types";

import ArrowButton, { ArrowDirection } from "components/signup/components/ArrowButton";
import Button from "components/signup/components/Button";
import Input from "components/signup/components/Input";
import StepContainer,{ getStepContainerMotionProps } from "components/signup/StepContainer";

import styles from "styles/styles";

const CaptureEmail = () => {
    const dispatch = useDispatch()
    
    const inputRef = useRef(null)
    const isFirstRender = useIsFirstRender()

    const isLoading = useSelector((state: any) => state.signup.isLoading)
    const patchFailed = useSelector((state: any) => state.signup.patchFailed)
    const leadUuid = useSelector((state: any) => state.signup.leadUuid)
    const application = useSelector((state: any) => state.signup.application)
    const products = useSelector((state: any) => state.signup.products)
    const stepDirection = useSelector((state: any) => state.signup.direction)

    const [email, setEmail] = useState(application.email || '')
    const [showError, setShowError] = useState(false)
    const [isInputDisabled, setIsInputDisabled] = useState(false)

    const selectedProduct = products?.find((product: any) => product.productCode === application.productCode)
    const isButtonDisabled = isInputDisabled
    const emailErrorMessage = patchFailed ? 'This email is already in use.' : 'Enter a valid email address.'

    const handleInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.value.length > 60) return;
        
        setEmail(ev.target.value)
    }

    const handleBack = (ev: React.MouseEvent) => {
        ev.stopPropagation();
        ev.preventDefault();

        dispatch(updateStep({
            step: SignupStep.InitialReview,
            direction: StepDirection.Left,
        }))
    }
 
    const handleSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();
        setShowError(false)
        setIsInputDisabled(true)

        const isEmailValid = validateEmail(email) 

        if (isEmailValid) {
            dispatch(updateLead({ leadUuid, application: { email } }))
        } else {
            setIsInputDisabled(false)
            setShowError(true)
        }
    }

    // Focus email input on mount
    useEffect(() => {
        (inputRef.current as any)?.focus()
    }, [])

    // When isLoading changes, and after isInputDisabled is set to true, 
    // check patch status and either show error OR progress to next step
    useEffect(() => {
        if (!isFirstRender && !isLoading && isInputDisabled) {
            if (patchFailed) {
                setIsInputDisabled(false)   
                setShowError(true) 
            } else {
                const isCreditApplication = selectedProduct.productType === ProductType.Credit
                const isBusinessApplication = selectedProduct.productCategory === ProductCategory.Business

                dispatch(updateApplication({ email }))
                dispatch(updateStep({
                    step: isCreditApplication ? SignupStep.Income : (isBusinessApplication ? SignupStep.BizType : SignupStep.FinalReview),
                    direction: StepDirection.Right,
                }))
            }
        }
    }, [isLoading]) // eslint-disable-line

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='Email'
    >
        <form className="d-flex flex-column align-items-center" onSubmit={handleSubmit}>
            <Title>Just a few more questions...</Title>
            <Text>Where should we send you emails about your Taekus account?</Text>
            <Input errorMessage={showError ? emailErrorMessage : undefined} value={email} onChange={handleInputChange} parentRef={inputRef} label="Email"/>
            <ButtonContainer>
                <BackButtonContainer>
                    <ArrowButton onClick={handleBack} direction={ArrowDirection.Left}/>
                </BackButtonContainer>
                <Button isLoading={isButtonDisabled} type="submit">Next</Button>
            </ButtonContainer>
        </form>
    </StepContainer>
}

const BackButtonContainer = styled.div`
    margin-right: 16px;
`

const ButtonContainer = styled.div`
    margin-top: 32px;
    display: flex;
`

const Text = styled.div`
    color: ${styles.Color.TaekusGrey2};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    margin-bottom: 16px;
    ${styles.MediaQueries.Mobile} {
        font-size: 14px;
    }
`

const Title = styled.div`
    color: ${styles.Color.TaekusGrey1};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-bottom: 32px;
    ${styles.MediaQueries.Mobile} {
        font-size: 24px;
    }
`

export default CaptureEmail