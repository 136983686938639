import React from "react"
import { useDispatch, useSelector } from "react-redux";

import { styled } from "styled-components"

import { SignupStep } from "components/signup/constants";
import { updateStep } from "components/signup/signupSlice";
import { StepDirection } from "components/signup/types";

import Button from "components/signup/components/Button";
import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";

import styles from "styles/styles";

const VPN = () => {
    const dispatch = useDispatch()

    const user = useSelector((state: any) => state.currentUser)
    const stepDirection = useSelector((state: any) => state.signup.direction)
    
    const handleSubmit = (ev: React.FormEvent) => {
        ev.preventDefault()
        dispatch(updateStep({
            step: user.userLoaded ? SignupStep.LoadingProve : SignupStep.FindInfo,
            direction: StepDirection.Right
        }))
    }
    
    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='VPN'
    >
        <form className="d-flex flex-column align-items-center" onSubmit={handleSubmit}>
            <Title>Are you on a VPN?</Title>
            <Text>We don’t support application submissions on a VPN, so please disable your VPN before continuing. Thank you!</Text>
            <ButtonContainer>
                <Button type="submit">I've disabled my VPN</Button>
            </ButtonContainer>
        </form>
    </StepContainer>
}

const ButtonContainer = styled.div`
    margin-top: 32px;
    display: flex;
`

const Text = styled.div`
    color: ${styles.Color.TaekusGrey2};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    margin-bottom: 16px;
`

const Title = styled.div`
    color: ${styles.Color.TaekusGrey1};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-bottom: 32px;
`

export default VPN