import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"

import moment from "moment"

import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"

import { LinkedAccount } from "types/LinkedAccount"

import { AppPath } from "components/appRouter/constants"
import { Locale, USDCurrencyOptions } from "utils/constants"

import { Actions as TransferActions } from 'redux/features/banking/transfers'
import { DropdownKeys } from "redux/features/global/constants"
import { updateDropdownKey } from "redux/features/global/globalSlice"

import Button, { ButtonSize, ButtonType } from "components/common/Button"
import Spinner from "components/common/Spinner"
import DatePicker from "components/common/DatePicker/DatePicker"
import StatusDisplay from "components/common/StatusDisplay"

import AccountsDropdown from "components/pages/Funding/Payment/AccountsDropdown"
import { defaultDebitPaymentOptionErrors } from "components/pages/Funding/Payment/constants"
import { messages } from "components/pages/Funding/Payment/messages"
import { getAccountLabel, sortPaymentAccounts } from "components/pages/Funding/Payment/util"

import { fadeInOutMotionProps } from "styles/motionConstants"
import styles from "styles/styles"

import { ReactComponent as ArrowRightSVG } from "assets/svg/ArrowRight.svg";
import { ReactComponent as CalendarSVG } from "assets/svg/Calendar.svg";
import { ReactComponent as CaretDown } from "assets/svg/CaretDown.svg";
import { ReactComponent as Reverse } from "assets/svg/Reverse.svg";
import { CardAccountType } from "types/CardAccount"

enum DebitPaymentSteps {
    Options,
    Review,
    Confirm
}

const DebitPayment = () => {
    const dispatch = useDispatch()
    
    const [currentStepIndex, setCurrentStepIndex] = useState(DebitPaymentSteps.Options)
    const [transferDate, setTransferDate] = useState(moment())
    const [amount, setAmount] = useState('-.--')
    const [sourceAccount, setSourceAccount] = useState<any | undefined>(undefined)
    const [destinationAccount, setDestinationAccount] = useState<any | undefined>(undefined)

    const incrementStep = () => {
        // Reset options when user is done
        if (currentStepIndex === DebitPaymentSteps.Confirm) {
            setAmount('-.--')
            setTransferDate(moment())
            setSourceAccount(undefined)
            setDestinationAccount(undefined)
            dispatch(TransferActions.clearCancelTransfer())
        }

        setCurrentStepIndex(currentStepIndex < 2 ? currentStepIndex + 1 : 0)
    }

    const decrementStep = () => {
        setCurrentStepIndex(currentStepIndex - 1)
    }

    const getStepContent = (step: number) => {
        switch (step) {
            case DebitPaymentSteps.Confirm:
                return <ConfirmStep
                    amount={amount}
                    transferDate={transferDate}
                    incrementStep={incrementStep}
                    sourceAccount={sourceAccount}
                    destinationAccount={destinationAccount}
                />
            case DebitPaymentSteps.Review:
                return <ReviewStep
                    amount={amount}
                    sourceAccount={sourceAccount}
                    destinationAccount={destinationAccount}
                    transferDate={transferDate}
                    decrementStep={decrementStep}
                    incrementStep={incrementStep}
                />
            case DebitPaymentSteps.Options:
            default:
                return <OptionsStep
                    amount={amount}
                    updateAmount={setAmount}
                    destinationAccount={destinationAccount}
                    updateDestinationAccount={setDestinationAccount}
                    sourceAccount={sourceAccount}
                    updateSourceAccount={setSourceAccount}
                    transferDate={transferDate}
                    updateTransferDate={setTransferDate}
                    incrementStep={incrementStep}
                />
        }
    }

    // Reset cancel state on mount
    useEffect(() => {
        dispatch(TransferActions.clearCancelTransfer())
    }, [dispatch])


    return <Container {...fadeInOutMotionProps}>
        <AnimatePresence mode='wait'>
            {getStepContent(currentStepIndex)}
        </AnimatePresence>
    </Container>
}

type OptionProps = {
    amount: string,
    updateAmount: (amount: string) => void,
    sourceAccount?: LinkedAccount,
    updateSourceAccount: (account?: LinkedAccount) => void,
    destinationAccount?: LinkedAccount
    updateDestinationAccount: (account?: LinkedAccount) => void,
    transferDate: moment.Moment,
    updateTransferDate: (date: moment.Moment) => void,
    incrementStep: () => void,
}

const OptionsStep = (props: OptionProps) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const {
        amount,
        updateAmount,
        sourceAccount,
        updateSourceAccount,
        destinationAccount,
        updateDestinationAccount,
        transferDate,
        updateTransferDate,
        incrementStep,
    } = props;

    const isMobile = useSelector((state: any) => state.global.isMobile)
    const isAccountDropdownOpen = useSelector((state: any) => state.global.dropdownKey) === DropdownKeys.Funding.Account;
    const externalAccounts = useSelector((state: any) => state.linkedAccounts.linkedAccounts)
    const internalAccounts = useSelector((state: any) => state.linkedAccounts.internalAccounts)

    const [isSourceDropdownSelected, setIsSourceDropdownSelected] = useState(true)
    const [optionsErrors, setOptionsErrors] = useState(defaultDebitPaymentOptionErrors)

    const isInternalTransfer = internalAccounts.includes(sourceAccount) && internalAccounts.includes(destinationAccount)
    const topOptionError = Object.values(optionsErrors).find(error => !!error)

    const getVisibleAccounts = () => {
        const oppositeAccount = isSourceDropdownSelected ? destinationAccount : sourceAccount

        return [...externalAccounts, ...internalAccounts]
            // filter out the account selected in the opposite dropdown
            .filter(account => account.uuid !== oppositeAccount?.uuid && account.type !== CardAccountType.Credit)
            .sort(sortPaymentAccounts)
    }

    const onAmountFocus = () => {
        if (isNaN(Number(amount.replaceAll(',', '')))) {
            updateAmount('')
        } else {
            // Remove commas while editing value
            updateAmount(amount.replaceAll(',',''))
        }
    }

    const onAmountBlur = () => {
        if (amount === '') {
            updateAmount('-.--')
        } else {
            // Add commas back on blur
            updateAmount(Number(amount.replaceAll(',','')) === 0 ? '' : Number(amount.replaceAll(',','')).toLocaleString(Locale.English, USDCurrencyOptions).slice(1)) 
        }
    }

    const onAmountChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = ev.target.value.replaceAll(',', '').replaceAll(' ', '')

        const valueIsANumber = !isNaN(Number(newValue))
        const valueIsInRange = Number(newValue) >= 0 && Number(newValue) < 10000000
        const valueHasUpTo2DecimalPlaces = (!newValue.includes('.') || newValue.split('.')[1]?.length <= 2)

        if (valueIsANumber && valueIsInRange && valueHasUpTo2DecimalPlaces){
            updateAmount(newValue)
        }
    }

    const swapAccounts = () => {
        const tmp = sourceAccount
        updateSourceAccount(destinationAccount)
        updateDestinationAccount(tmp)
    }

    const toggleAccountDropdown = (ev: React.MouseEvent) => {
        const selectedOtherDropdown = isSourceDropdownSelected !== ((ev.target as HTMLInputElement).id === 'source')
        dispatch(updateDropdownKey(isAccountDropdownOpen && !selectedOtherDropdown ? '' : DropdownKeys.Funding.Account))
        setIsSourceDropdownSelected((ev.target as HTMLInputElement).id === 'source')
        
        ev.stopPropagation()
    }

    const handleAccountClick = (account: any) => {
        const oppositeAccount = isSourceDropdownSelected ? destinationAccount : sourceAccount

        // If both the selected and opposite accounts are external, unselect the opposite account
        if (!account.isInternalAccount && !oppositeAccount?.isInternalAccount) { 
            isSourceDropdownSelected ? updateDestinationAccount(undefined) : updateSourceAccount(undefined)
        }
        // If both accounts are internal, set the transfer date to today
        if (account.isInternalAccount && oppositeAccount?.isInternalAccount) {
            updateTransferDate(moment())
        }
        isSourceDropdownSelected ? updateSourceAccount(account) : updateDestinationAccount(account) 
    }

    /*
     *  This function returns a moment object that represents a given holiday
     *  Provide the year, month, and day of week of the holiday (eg, Tues June 11, 2024 would be 2024, 05, 02)
     *  weekNumber is the number of weeks from the first weekday of the month. 
     *      (eg, to get the third monday of the month you would provide a 2 for weekNumber)
     *  isWeeksFromEnd denotes if weekNumber counts forward or back in the month. For example, if you want to get the 2nd
     *  to last monday of the month, you would provide 1 for weekday, 1 for weekNumber, and true for isWeeksFromEnd
     */
    const getHoliday = (year: number, month: number, weekday: number, weekNumber: number, isWeeksFromEnd?: boolean) => {
        let holiday
        if (isWeeksFromEnd) {
            holiday = moment().year(year).month(month + 1).startOf('month').day(weekday).startOf('day')
            if (holiday.month() !== month) holiday.subtract(1, 'week')
            holiday.subtract(weekNumber, 'weeks')
        } else {
            holiday = moment().year(year).month(month).startOf('month').day(weekday).startOf('day')
            if (holiday.month() !== month) holiday.add(1, 'week')
            holiday.add(weekNumber, 'weeks')
        }
        
        return holiday
    }

    const isHoliday = (date: moment.Moment) => {
        const nearbyDates = Array(5).fill(0).map((value, index) => (moment(date.format('YYYY-MM-DD')).add(index, 'days')).format('YYYY-MM-DD') )

        return nearbyDates.some(date => {
            const dateMoment = moment(date)
            // New Year's
            if (date.includes('01-01')) return true;
            // Martin Luther's Birthday (Third monday of Jan)
            const mlk = getHoliday(dateMoment.year(), 0, 1, 2)
            if (date === mlk.format('YYYY-MM-DD')) return true
            // President's Day (Third monday of Feb)
            const presidents = getHoliday(dateMoment.year(), 1, 1, 2)
            if (date === presidents.format('YYYY-MM-DD')) return true
            // Memorial Day (Last monday of May)
            const memorial = getHoliday(dateMoment.year(), 4, 1, 0, true)
            if (date === memorial.format('YYYY-MM-DD')) return true
            // Juneteenth
            if (date.includes('06-19')) return true;
            // Independence Day
            if (date.includes('07-04')) return true;
            // Labor Day (First monday of Sep)
            const labor = getHoliday(dateMoment.year(), 8, 1, 0)
            if (date === labor.format('YYYY-MM-DD')) return true
            // Columbus Day (Second monday of Oct)
            const columbus = getHoliday(dateMoment.year(), 9, 1, 1)
            if (date === columbus.format('YYYY-MM-DD')) return true
            // Veteran's Day
            if (date.includes('11-11')) return true;
            // Thanksgiving (Fourth Thursday of November)
            const thanksgiving = getHoliday(dateMoment.year(), 10, 4, 3)
            if (date === thanksgiving.format('YYYY-MM-DD')) return true
            // Christmas
            if (date.includes('12-25')) return true;
            
            return false
        })
    }

    const validateAmount = (amount: string) => {
        if (amount === '' || amount === '-.--') {
            return messages.DebitPayment.Options.Errors.EmptyAmount
        }
        if (isNaN(Number(amount.replaceAll(',', '')))) {
            return messages.DebitPayment.Options.Errors.InvalidAmount
        }
        if ((sourceAccount?.availableBalance !== undefined && Number(amount.replaceAll(',', '')) > sourceAccount?.availableBalance)) {
            return messages.DebitPayment.Options.Errors.InsufficientFunds
        }

        return null;
    }

    const validateOptions = () => {
        const errors = {
            amount: validateAmount(amount),
            sourceAccount: sourceAccount === undefined ? messages.DebitPayment.Options.Errors.EmptySourceAccount : null,
            destinationAccount: destinationAccount === undefined ? messages.DebitPayment.Options.Errors.EmptyDestinationAccount : null,
            date: null,
        }

        setOptionsErrors(errors)
        return !Object.values(errors).some(error => !!error)
    }

    const handleButtonClick = () => {
        if (validateOptions()) {
            incrementStep();
        }
    }

    return <Options {...fadeInOutMotionProps} key='options'>
        <OptionsContent>
            {isHoliday(transferDate) && <StatusDisplayContainer>
                <StatusDisplay isLoading={false} hideIcon isError={false} label={'Your transfer may be delayed by an upcoming bank holiday.'}/>
            </StatusDisplayContainer>}
            <div>
                <InputWrapper>
                    <InputContainer>
                        <Label>Amount</Label>
                        <AmountInputContainer>
                            <AmountInputSymbol>$</AmountInputSymbol>
                            <AmountInput
                                tabIndex={-1}
                                data-testid='amount'
                                type="text"
                                inputMode={isMobile ?? "decimal"}
                                onFocus={onAmountFocus}
                                onBlur={onAmountBlur}
                                value={amount}
                                onChange={onAmountChange}
                            />
                        </AmountInputContainer>
                    </InputContainer>
                </InputWrapper>
                <InputWrapper className="flex-column">
                    <AccountsRow>
                        <InputContainer>
                            <Label>From</Label>
                            <DropdownInputWrapper>
                                <DropdownInput
                                    data-testid='sourceAccount'
                                    id='source'
                                    account={sourceAccount}
                                    onClick={toggleAccountDropdown}
                                    onChange={(ev: any) => { ev.stopPropagation() }}
                                >
                                    {getAccountLabel(sourceAccount, true) || "Source Account"}
                                </DropdownInput>
                                <CaretContainer>
                                    <StyledCaretDown/>
                                </CaretContainer>
                            </DropdownInputWrapper>
                            {(isAccountDropdownOpen && isSourceDropdownSelected) && <AccountsDropdown
                                accounts={getVisibleAccounts()}
                                onClick={handleAccountClick}
                            />}
                        </InputContainer>
                        {!isMobile && <StyledReverseIcon onClick={swapAccounts}/>}
                        <InputContainer>
                            <Label>To</Label>
                            <DropdownInputWrapper>
                                <DropdownInput
                                    data-testid='destinationAccount'
                                    id='destination'
                                    account={destinationAccount}
                                    onClick={toggleAccountDropdown}
                                    onChange={(ev: any) => { ev.stopPropagation() }}
                                >
                                    {getAccountLabel(destinationAccount, true) || "Destination Account"}
                                </DropdownInput>
                                <CaretContainer>
                                    <StyledCaretDown/>
                                </CaretContainer>
                            </DropdownInputWrapper>
                            {isAccountDropdownOpen && !isSourceDropdownSelected && <AccountsDropdown
                                accounts={getVisibleAccounts()}
                                onClick={handleAccountClick}
                            />}
                        </InputContainer>
                    </AccountsRow>
                    <ExternalAccountLink onClick={() => { history.push(AppPath.ExternalAccounts) }}>Manage External Accounts</ExternalAccountLink>
                </InputWrapper>
                <InputWrapper>
                    <InputContainer>
                        <Label>Date</Label>
                        <DatePicker
                            disabled={isInternalTransfer}
                            id='datePicker'
                            selected={transferDate}
                            startDate={transferDate}
                            onChange={(date) => { updateTransferDate(moment(date)) }}
                            customInput={<DateInput>
                                {transferDate.format('MMM D, YYYY')}
                                <StyledCalendarIcon/>
                            </DateInput>}
                            minDate={moment()}
                        />
                        <DisabledDatePickerText isInternalTransfer={isInternalTransfer}>Internal transfers are instant and cannot be scheduled.</DisabledDatePickerText>
                    </InputContainer>
                </InputWrapper>
            </div>
            <OptionsErrorContainer>
                {topOptionError && <StatusDisplay isLoading={false} isError={true} label={topOptionError || ''}/>}
            </OptionsErrorContainer>
            <ButtonRow>
                <Button
                    data-testid='optionsButton'
                    size={ButtonSize.Wide}
                    buttonType={ButtonType.Purple}
                    label='Make Transfer'
                    onClick={handleButtonClick}
                />
            </ButtonRow>
        </OptionsContent>
        <OptionFooter>
            <div>Standard ACH typically take 3 business days for your payment to be reflected on your account.</div>
            <div>A business day is a non-holiday weekday. Payments not made before 3:35 pm CT on a business day will be dated the next day.</div>
            <Whitespace>{' '}</Whitespace>
            <div>NOTE: Scheduled transfers will be initiated at 12:00 am CT on the requested date.</div>
        </OptionFooter>
    </Options>
}

type ReviewStepProps = {
    amount: string,
    sourceAccount?: LinkedAccount,
    destinationAccount?: LinkedAccount,
    transferDate: moment.Moment,
    decrementStep: () => void,
    incrementStep: () => void,
}

const ReviewStep = (props: ReviewStepProps) => {
    const dispatch = useDispatch()

    const { amount, sourceAccount, destinationAccount, transferDate, incrementStep, decrementStep } = props;

    const isMobile = useSelector((state: any) => state.global.isMobile)
    const newTransferPending = useSelector((state: any) => state.transfers.newTransferPending)

    const [isAwaitingTransfer, setIsAwaitingTransfer] = useState(false)
    const [isConfirmButtonDebounced, setIsConfirmButtonDebounced] = useState(false)

    const isExternalToInternal = !sourceAccount?.isInternalAccount && destinationAccount?.isInternalAccount
    const isFullyInternal = !!sourceAccount?.isInternalAccount && !!destinationAccount?.isInternalAccount

    const submitTransfer = () => {
        setIsAwaitingTransfer(true)
        dispatch(TransferActions.createTransfer({
            transferDirection: isExternalToInternal ? 'inbound' : 'outbound',
            transferDate: transferDate.format('YYYY-MM-DD'),
            transferValue: amount.replaceAll(',', ''),
            externalAccountUuid:  (isExternalToInternal ? sourceAccount : destinationAccount)?.uuid,
            isInternalAccount: (isExternalToInternal ? sourceAccount : destinationAccount)?.isInternalAccount,
            cardAccountUuid:  (isExternalToInternal ? destinationAccount : sourceAccount)?.uuid,
            autoReload: false,
            acceptTerms: true
        }))
    }

    // Debounce the confirm button to prevent accidental clicks
    useEffect(() => {
        setIsConfirmButtonDebounced(true);
        setTimeout(() => setIsConfirmButtonDebounced(false), 2000);
    }, []) // eslint-disable-line

    useEffect(() => {
        if (isAwaitingTransfer && newTransferPending === false) {
            incrementStep()
            setIsAwaitingTransfer(false)
        }
    }, [newTransferPending]) // eslint-disable-line

    return <Review {...fadeInOutMotionProps} key='review'>
        <ReviewSubtitle>Review Your {!isFullyInternal && (isExternalToInternal ? 'Inbound' : 'Outbound')} Transfer</ReviewSubtitle>
        <ReviewRow>
            <Label>Amount</Label>
            <ReviewValue>{Number(amount.replaceAll(',', '')).toLocaleString(Locale.English, USDCurrencyOptions)}</ReviewValue>
        </ReviewRow>
        <ReviewRow>
            <AccountsRow>
                <ReviewAccountContainer>
                    <Label>From</Label>
                    <ReviewValue>{getAccountLabel(sourceAccount)}</ReviewValue>
                </ReviewAccountContainer>
                {!isMobile && <StyledArrowRight/>}
                <ReviewAccountContainer>
                    <Label>To</Label>
                    <ReviewValue>{getAccountLabel(destinationAccount)}</ReviewValue>
                </ReviewAccountContainer>
            </AccountsRow>
        </ReviewRow>
        <ReviewRow>
            <Label>Date</Label>
            <ReviewValue>{transferDate.format('MMM D, YYYY')}</ReviewValue>
        </ReviewRow>
        <Terms>
            {isFullyInternal ? <>
                <div>By selecting confirm transfer, I authorize Taekus to debit and credit my accounts as set forth, subject to the Electronic Funds Transfer Terms.</div>
                <Whitespace>{' '}</Whitespace>
                <div>Internal transfers between Taekus accounts are instant.</div>
            </> : <>
                <div>By selecting confirm transfer, I authorize Taekus to debit or credit my linked account as set forth, subject to the Electronic Funds Transfer Terms.</div>
                <Whitespace>{' '}</Whitespace>
                <div>Standard ACH typically take 3 business days for your payment to be reflected on your account. A business day is a non-holiday weekday. Payments not made before 3:35 pm CT on a business day will be dated the next day.</div>
                <Whitespace>{' '}</Whitespace>
                <div>NOTE: Scheduled transfers will be initiated at 12:00 am CT on the requested date.</div>
            </>}
        </Terms>
        <ReviewButtonRow>
            <EditTransferButtonContainer>
                <Button
                    size={ButtonSize.Fit}
                    buttonType={ButtonType.SecondaryPurple}
                    label='Edit Transfer'
                    onClick={decrementStep}
                    disabled={isAwaitingTransfer}
                />
            </EditTransferButtonContainer>
            {isConfirmButtonDebounced || isAwaitingTransfer ? <ConfirmButtonLoadingContainer {...fadeInOutMotionProps}>
                <Spinner size={20}/>
            </ConfirmButtonLoadingContainer> : <ReviewButtonContainer {...fadeInOutMotionProps}>
                <Button
                    data-testid='confirmButton'
                    size={ButtonSize.Wide}
                    buttonType={ButtonType.Purple}
                    label='Confirm Transfer'
                    onClick={submitTransfer}
                />
            </ReviewButtonContainer>}
        </ReviewButtonRow>
    </Review>
}

type ConfirmStepProps = {
    amount: string,
    sourceAccount?: LinkedAccount,
    destinationAccount?: LinkedAccount,
    transferDate: moment.Moment,
    incrementStep: () => void,
}

const ConfirmStep = (props: ConfirmStepProps) => {
    const dispatch = useDispatch()

    const { amount, sourceAccount, destinationAccount, transferDate, incrementStep } = props;

    const isMobile = useSelector((state: any) => state.global.isMobile)
    const cancelTransferData = useSelector((state: any) => state.transfers.cancelTransfer)
    const transferError = useSelector((state: any) => state.transfers.createError)
    const newTransferUuid = useSelector((state: any) => state.transfers.newTransferUuid)

    const isCancelAvailable = cancelTransferData.success === null && !(sourceAccount?.isInternalAccount && destinationAccount?.isInternalAccount)

    const getStatusMessage = () => {
        if (transferError) {
            return 'We could not complete your transfer at this time.'
        }

        if (cancelTransferData.submitting) {
            return 'Cancelling your transfer...'
        }

        if (cancelTransferData.success !== null) {
            return cancelTransferData.success ? 'Your transfer was successfully cancelled.' : 'We were unable to cancel your transfer.'
        }

        return 'Your transfer is being initiated.'
    }

    const cancelTransfer = () => {
        dispatch(TransferActions.cancelTransfer({
            transferId: newTransferUuid,
            cardAccountUuid: (sourceAccount?.isInternalAccount ? sourceAccount : destinationAccount)?.uuid,
        }))
    }

    return <Review {...fadeInOutMotionProps} key='confirm'>
        <StatusDisplayContainer>
            <StatusDisplay isLoading={cancelTransferData.submitting} isError={transferError || cancelTransferData.success === false} label={getStatusMessage()}/>
        </StatusDisplayContainer>
        {!transferError && <>
            <ReviewRow>
                <Label cancelled={cancelTransferData.success}>Amount</Label>
                <ReviewValue cancelled={cancelTransferData.success}>{Number(amount.replaceAll(',', '')).toLocaleString(Locale.English, USDCurrencyOptions)}</ReviewValue>
            </ReviewRow>
            <ReviewRow>
                <AccountsRow>
                    <ReviewAccountContainer>
                        <Label cancelled={cancelTransferData.success}>From</Label>
                        <ReviewValue cancelled={cancelTransferData.success}>{getAccountLabel(sourceAccount)}</ReviewValue>
                    </ReviewAccountContainer>
                    {!isMobile && <StyledArrowRight/>}
                    <ReviewAccountContainer>
                        <Label cancelled={cancelTransferData.success}>To</Label>
                        <ReviewValue cancelled={cancelTransferData.success}>{getAccountLabel(destinationAccount)}</ReviewValue>
                    </ReviewAccountContainer>
                </AccountsRow>
            </ReviewRow>
            <ReviewRow>
                <Label cancelled={cancelTransferData.success}>Date</Label>
                <ReviewValue cancelled={cancelTransferData.success}>{transferDate.format('MMM D, YYYY')}</ReviewValue>
            </ReviewRow>
        </>}
        <ConfirmButtonRow>
            <Button 
                data-testid='resetButton'
                size={ButtonSize.Wide}
                buttonType={ButtonType.Purple}
                label='Make Another Transfer'
                onClick={incrementStep}
            />
            {!transferError && isCancelAvailable && <CancelLinkContainer>
                {cancelTransferData.submitting ? <Spinner size={20}/> : <CancelLink onClick={cancelTransfer}>Cancel this transfer</CancelLink>}
            </CancelLinkContainer>}
        </ConfirmButtonRow>
    </Review>
}

const StyledCalendarIcon = styled(CalendarSVG)`
    height: 18px;
    width: auto;
    margin-right: 12px;
`

const OptionsContent = styled.div`
    flex: 1;
`

const ConfirmButtonLoadingContainer = styled(motion.div)`
    width: 310px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Whitespace = styled.div`
    white-space: pre-wrap;
`

const ButtonRow = styled.div`
    ${styles.MediaQueries.Desktop} {
        margin-top: 48px;
    }
    ${styles.MediaQueries.Mobile} {
        margin: 32px 0;
    }
`

const CancelLinkContainer = styled.div`
    display: flex;
    align-items: center;
    height: 42px;
    ${styles.MediaQueries.Desktop} {
        width: 194px;
        margin-left: 16px;
        justify-content: center;
    }
    ${styles.MediaQueries.Mobile} {
        margin-top: 8px;
    }
`

const CancelLink = styled.a`
    cursor: pointer;
    color: ${styles.Color.TaekusPurple} !important;
    text-decoration: underline;
`

const ConfirmButtonRow = styled.div`
    margin-bottom: 20px;
    ${styles.MediaQueries.Desktop} {
        margin-top: 48px;
    }
    display: flex;
    ${styles.MediaQueries.Mobile} {
        margin-top: 8px;
        flex-direction: column;
    }
`

const OptionsErrorContainer = styled.div`
    height: 24px;
    ${styles.MediaQueries.Mobile} {
        margin-top: 24px;
    }
`

const StatusDisplayContainer = styled.div`
    min-height: 24px;
    ${styles.MediaQueries.Desktop} {
        margin-bottom: 40px;
    }
    ${styles.MediaQueries.Mobile} {
        margin-bottom: 24px;
    }
`

const EditTransferButtonContainer = styled.div`
    height: 40px;
    ${styles.MediaQueries.Desktop} {    
        width: 194px;
        margin-right: 16px;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
        margin: 8px 0 20px;
    }
`

const ReviewButtonContainer = styled(motion.div)`
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const ReviewButtonRow = styled.div`
    display: flex;
    align-items: center;
    ${styles.MediaQueries.Mobile} {
        flex-direction: column-reverse;
    }
`

const AccountsRow = styled.div`
    display: flex;
    ${styles.MediaQueries.Desktop} {
        align-items: end;
        margin-bottom: 12px;
    }
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
    }
`

const OptionFooter = styled.div`
    font-family: ${styles.Font.Family};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
    letter-spacing: 0.12px;
    ${styles.MediaQueries.Desktop} {
        border-top: 1px solid #999898;
        padding: 20px 0 42px;
        color: #999898;
    }
    ${styles.MediaQueries.Mobile} {
        padding: 13px 0 80px;
        color: #4D4D4D;
    }
`

const StyledArrowRight = styled(ArrowRightSVG)`
    fill: ${styles.Color.TaekusPurple};
    height: auto;
    min-width: 24px;
    margin: 0 16px 9px;
`

const ReviewValue = styled.div<CancelProps>`
    ${props => props.cancelled && `
        opacity: 0.5;
        text-decoration: line-through;
    `}
    color: ${styles.Color.NearBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 29.76px */
    letter-spacing: 0.48px;
    width: 310px;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
`

const ReviewAccountContainer = styled.div`
    ${styles.MediaQueries.Mobile} {
        &:first-child {
            margin-bottom: 24px;
        }
    }
`

const Terms = styled.div`
    border-top: 1px solid ${styles.Color.Grey};
    padding-top: 16px;
    ${styles.MediaQueries.Desktop} {
        margin-top: 32px;
        margin-bottom: 36px;
    }
    ${styles.MediaQueries.Mobile} {
        margin-bottom: 34px;
        margin-top: 10px;
    }
    color: #4D4D4D;
    font-family: ${styles.Font.Family};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    letter-spacing: 0.12px;
`

const ReviewRow = styled.div`
    display: flex;
    flex-direction: column;
    ${styles.MediaQueries.Desktop} {
        margin-bottom: 32px;
    }
    ${styles.MediaQueries.Mobile} {
        margin-bottom: 24px;
    }
`

const DateInput = styled.div`
    color: ${styles.Color.NearBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 29.76px */
    letter-spacing: 0.48px;
    cursor: pointer;
    border-bottom: 1px solid ${styles.Color.Black};
    padding-bottom: 8px;
    ${styles.MediaQueries.Desktop} {
        width: 353px;
        font-size: 24px;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
        font-size: 20px;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${styles.Animation.transitionStyles}
    &:hover {
        border-bottom: 1px solid ${styles.Color.TaekusPurple};
    }
`

const CaretContainer = styled.div`
    position: absolute;
    pointer-events: none;
    right: 0;
    top: 0;
    height: 100%;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const StyledCaretDown = styled(CaretDown)`
    margin-bottom: 8px;
    width: 12px;
    height: 12px;
`

const DropdownInputWrapper = styled.div`
    position: relative;
    overflow: hidden;
`

type DropdownInputProps = {
    account: any,
}

const DropdownInput = styled.div<DropdownInputProps>`
    color: ${props =>  props.account === undefined ? 'rgba(0,0,0,0.5)' : styles.Color.Black};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    ${styles.MediaQueries.Desktop} {
        font-size: 24px;
        width: 353px;
    }
    ${styles.MediaQueries.Mobile} {
        font-size: 20px;
        width: 100%
        max-width: 100%;
    }
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 29.76px */
    letter-spacing: 0.48px;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: 0;
    border-bottom: 1px solid ${styles.Color.Black};
    caret-color: transparent;
    padding-bottom: 8px;
    padding-right: 24px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    ${styles.Animation.transitionStyles}
    &:hover, &:focus {
        border-bottom: 1px solid ${styles.Color.TaekusPurple};
    }
`

const StyledReverseIcon = styled(Reverse)`
    fill: ${styles.Color.TaekusPurple};
    height: auto;
    width: 30px;
    margin: 0 16px 9px;
    cursor: pointer;
`

const ExternalAccountLink = styled.a`
    color: ${styles.Color.TaekusPurple};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 19.32px */
    letter-spacing: 0.28px;
    cursor: pointer;
    user-select: none;
    width: min-content;
    white-space: nowrap;
    &:hover {
        color: ${styles.Color.TaekusPurple};
    }
`

const InputWrapper = styled.div`
    display: flex;
    ${styles.MediaQueries.Desktop} {
        margin-bottom: 48px;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
        &:not(&:last-child) {
            margin-bottom: 40px;
        }
    }
    
`

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 100%;
    ${styles.MediaQueries.Mobile} {
        width: 100%;
        &:not(&:only-child) {
            margin-bottom: 16px;
        }
    }
`

type CancelProps = {
    cancelled?: boolean,
}

const Label = styled.div<CancelProps>`
    color: ${styles.Color.NearBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.14px;
    margin-bottom: 8px;
    ${props => props.cancelled && 'opacity: 0.5;'}
`

const Options = styled(motion.div)`
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    ${styles.MediaQueries.Desktop} {
        padding-top: ${styles.Spacing.M};
    }
    ${styles.MediaQueries.Mobile} {
        padding-top: 2px;
    }
`

const ReviewSubtitle = styled.div`
    color: ${styles.Color.Black};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 138%; /* 27.6px */
    letter-spacing: 0.2px;
    margin-bottom: 24px;
`

const Review = styled(motion.div)`
    display: flex;
    flex-direction: column;
    ${styles.MediaQueries.Desktop} {
        padding-top: 39px;
    }
    ${styles.MediaQueries.Mobile} {
        padding-top: 2px;
        padding-bottom: 16px;
    }
    width: 660px;
    max-width: 100%;
    ${styles.Animation.transitionStyles}
`

const AmountInputContainer = styled.div`
    position: relative;
    ${styles.MediaQueries.Mobile} {
        height: 32px;
    }
`

const AmountInputSymbol = styled.div`
    position: absolute;
    left: 0;
    color: ${styles.Color.NearBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    height: 100%;
    ${styles.MediaQueries.Desktop} {
        font-size: 48px;
        padding-top: 1px;
    }
    ${styles.MediaQueries.Mobile} {
        font-size: 20px;
        display: flex;
        align-items: center;
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 8px;
        max-height: 32px;
    }
    font-style: normal;
    font-weight: 400;
    line-height: 112%; /* 35.84px */
    letter-spacing: 0.32px;
`

const AmountInput = styled.input`
    color: ${styles.Color.NearBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    ${styles.MediaQueries.Desktop} {
        font-size: 48px;
        padding-left: 34px;
        padding-bottom: 8px;
    }
    ${styles.MediaQueries.Mobile} {
        text-align: start;
        font-size: 20px;
        padding-top: 0;
        margin: 0 !important;
        padding-right: 0;
        padding-bottom: 8px;
        max-height: 32px;
        padding-left: 16px;
    }
    font-style: normal;
    font-weight: 400;
    line-height: 112% !important; /* 35.84px */
    letter-spacing: 0.32px;
    background-color: transparent;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid ${styles.Color.NearBlack};
    outline: 0;
    width: 768px;
    max-width: 100%;
    
    ${styles.Animation.transitionStyles}
    &:hover, &:focus {
        border-bottom: 1px solid ${styles.Color.TaekusPurple};
    }
`

const DisabledDatePickerText = styled.div<any>`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    color: rgba(0,0,0,0.5);
    padding: 12px 0;
    opacity: ${props => props.isInternalTransfer ? 1 : 0};
    font-size: 14px;
    ${styles.Animation.transitionStyles}
`

const Container = styled(motion.div)`
    width: 100%;
    height: 100%;
`

export default DebitPayment