import React from "react";

import { motion } from "framer-motion";
import { styled } from "styled-components";

import styles from "styles/styles";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    secondary?: boolean,
    isLoading?: boolean,
}

const Button = (props: ButtonProps) => {
    const animateProps = { width: 6, height: 6 }

    const getTransitionProps = (index: number) => {
        return {
            delay: 0.25 * index,
            duration: 0.5, 
            type: 'Inertia', 
            repeatType: 'reverse', 
            repeat: Infinity 
        } as any
    }

    return <ButtonEl onClick={props.onClick} type={props.type} $secondary={props.secondary} $isloading={props.isLoading} disabled={props.disabled || props.isLoading} $isdisabled={props.disabled}>
        {props.isLoading ? <LoadingContainer>
            <DotContainer>
                <LoadingDot secondary={props.secondary} animate={animateProps} transition={getTransitionProps(0)}/>
            </DotContainer>
            <DotContainer>
                <LoadingDot secondary={props.secondary} animate={animateProps} transition={getTransitionProps(1)}/>
            </DotContainer>
            <DotContainer>
                <LoadingDot secondary={props.secondary} animate={animateProps} transition={getTransitionProps(2)}/>
            </DotContainer>
        </LoadingContainer> : props.children}
    </ButtonEl>
}

const getButtonOutline = (props: ButtonElProps) => {
    if (props.$secondary) {
        return styles.Color.TaekusPurple
    }
    return styles.Color.TaekusPurple
}

const getButtonBackground = (props: ButtonElProps) => {
    if (props.$secondary) {
        return 'transparent'
    }
    return props.$isdisabled ? styles.Color.TaekusGrey4 : styles.Color.TaekusPurple
}

const getHoverButtonBackground = (props: ButtonElProps) => {
    if (props.$secondary) {
        return 'rgba(124, 61, 118, 0.20)'
    }
    return props.$isdisabled ? styles.Color.TaekusGrey4 : '#63315E'
}

const getPressedButtonBackground = (props: ButtonElProps) => {
    if (props.$secondary) {
        return 'rgba(124, 61, 118, 0.40)'
    }
    return props.$isdisabled ? styles.Color.TaekusGrey4 : `#4A2547`
}

const getButtonBorderColor = (props: ButtonElProps) => {
    if (props.$secondary) {
        return 'rgba(124, 61, 118, 0.25)'
    }
    return 'transparent'
}

const getButtonColor = (props: ButtonElProps) => {
    if (props.$secondary) {
       return styles.Color.TaekusPurple
    }
    return props.$isdisabled ? styles.Color.TaekusGrey3 : 'white'
}

const getLoadingDotColor = (props: ButtonProps) => {
    if (props.secondary) {
        return styles.Color.TaekusPurple
    }
    return 'rgba(255, 255, 255, 0.30)';
}

const LoadingDot = styled(motion.div)<ButtonProps>`
    border-radius: 50%;
    width: 4px;
    height: 4px;
    background-color: ${props => getLoadingDotColor(props)};
`

const DotContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8px;
    height: 8px;
    &:not(&:last-child) {
        margin-right: 4px;
    }
`

const LoadingContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 32px;
`

type ButtonElProps = {
    $isdisabled?: boolean
    $secondary?: boolean,
    $isloading?: boolean,
}

const ButtonEl = styled.button<ButtonElProps>`
    display: flex;
    user-select: none;
    min-width: 120px;
    height: 40px;
    padding: 10px 25px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    white-space: nowrap;
    border-radius: 2px;
    border: 1px solid ${props => getButtonBorderColor(props)};
    background: ${props => getButtonBackground(props)};
    color: ${props => getButtonColor(props)};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 19.32px */
    letter-spacing: 0.28px;
    outline-offset: 1px;
    ${props => !(props.disabled || props.$isloading) && `&:hover {
        ${props.$isdisabled ? '' : 'box-shadow: 0px 0px 16px 0px rgba(124, 61, 118, 0.15);'}
        background: ${getHoverButtonBackground(props)};
    }`}
    &:active {
        ${props => !props.$secondary && 'color: rgba(255, 255, 255, 0.30);'}
        ${props => props.$isdisabled ? '' : 'box-shadow: 0px 0px 16px 0px rgba(124, 61, 118, 0.15);'}
        background: ${props => getPressedButtonBackground(props)};
    }
    &:focus {
        outline: 1px solid ${props => getButtonOutline(props)};
    }
    -moz-transition: box-shadow .2s ease-in, color .2s ease-in, background .2s ease-in;
    -o-transition: box-shadow .2s ease-in, color .2s ease-in, background .2s ease-in;
    -webkit-transition: box-shadow .2s ease-in, color .2s ease-in, background .2s ease-in;
    transition: box-shadow .2s ease-in, color .2s ease-in, background .2s ease-in;
`

export default Button