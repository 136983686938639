import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import { useIsFirstRender } from 'hooks/useIsFirstRender';

import PhoneInput from 'react-phone-number-input'

import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"

import StatusDisplay from "components/common/StatusDisplay"

import { StepUpText } from "components/pages/Rewards/Flights/constants"
import { TravelerDetails, TravelerInfo, TravelerInfoErrors } from "components/pages/Rewards/Flights/types"

import ManageTraveler from "components/pages/Rewards/Flights/FlightBooking/ManageTraveler"
import Traveler from "components/pages/Rewards/Flights/FlightBooking/Traveler"

import { Actions as BookingActions } from 'redux/features/flightBook'

import { fadeInOutMotionProps } from "styles/motionConstants"
import styles from "styles/styles"

import { ReactComponent as Plus } from "assets/svg/Plus.svg";
import { ReactComponent as Reload } from "assets/svg/Reload.svg";

import 'components/pages/Rewards/phoneInputStyles.css'
import 'react-phone-number-input/style.css'


type ManageTravelerInfoProps = {
    travelerInfo: TravelerInfo,
    updateTravelerInfo: (travelerInfo: TravelerInfo) => void,
    showValidationErrors: boolean
    validationErrors: TravelerInfoErrors,
    isAddingPassenger: boolean,
    updateIsAddingPassenger: (isAddingPassenger: boolean) => void,
}

const ManageTravelerInfo = (props: ManageTravelerInfoProps) => {
    const dispatch = useDispatch()

    // Redux state
    const isMobile = useSelector((state: any) => state.global.isMobile)
    const priceDetail = useSelector((state: any) => state.flightBook.priceDetail)
    const numberOfPassengers = useSelector((state: any) => state.flightSearch.searchParams.numPax.value)
    const flightBook = useSelector((state: any) => state.flightBook)

    // Component state
    const [inspectedTraveler, setInspectedTraveler] = useState<any>(undefined)
    const isFirstRender = useIsFirstRender()

    const policyLink = 'https://taekus.com/legal/privacy-policy'
    const tsaLink = 'https://www.tsa.gov/travel/security-screening'

    const clearContactInfo = () => {
        const { updateTravelerInfo } = props;

        updateTravelerInfo({
            phone: '+1',
            email: '',
            selectedTravelerUuids: []
        })
    }

    const inspectPassenger = (passenger: TravelerDetails) => {
        setInspectedTraveler(passenger);
        updateIsAddingPassenger(true);
    }

    const handleSelectPassenger = (passengerUuid: string) => {
         const { updateTravelerInfo, travelerInfo } = props;
        /*
         *  If the traveler is selected, unselected them
         *  Else, if the # of selected travelers is less than 
         *      the # of required travelers, select the passenger
         *  Else, if it's a single passenger journey, 
         *      overwrite the selection with the current traveler
         */
        if (travelerInfo.selectedTravelerUuids.includes(passengerUuid)) {
            // JS splice operates in-place so we should make a copy
            const copyOfPassengers = [...travelerInfo.selectedTravelerUuids]
            copyOfPassengers.splice(travelerInfo.selectedTravelerUuids.indexOf(passengerUuid), 1)
            updateTravelerInfo({
                ...travelerInfo,
                selectedTravelerUuids: copyOfPassengers
            })
        } else if (travelerInfo.selectedTravelerUuids.length < numberOfPassengers) {
            updateTravelerInfo({
                ...travelerInfo,
                selectedTravelerUuids: [...travelerInfo.selectedTravelerUuids, passengerUuid]
            })
        } else if (numberOfPassengers === 1) {
            updateTravelerInfo({
                ...travelerInfo,
                selectedTravelerUuids: [passengerUuid]
            })
        }
    }

    const addNewPassenger = (passenger: any) => {
        dispatch(BookingActions.createTraveler(passenger))
    }

    useEffect(() => {
        const travelerSuccessfullyCreated = !flightBook.createTravelerIsLoading && !flightBook.createTravelerErrors
        if (!isFirstRender && travelerSuccessfullyCreated) {
            updateIsAddingPassenger(false)
            if (!!flightBook.bookingTravelers.length) {
                handleSelectPassenger((flightBook.bookingTravelers.length - 1).toString())
            }
        }
    }, [flightBook.createTravelerIsLoading, flightBook.createTravelerErrors]); // eslint-disable-line

    useEffect(() => {
        const travelerSuccessfullyUpdated = !flightBook.updateTravelerIsLoading && !flightBook.updateTravelerErrors
        if (travelerSuccessfullyUpdated) {
            updateIsAddingPassenger(false)
        }
    }, [flightBook.updateTravelerErrors, flightBook.updateTravelerIsLoading]); // eslint-disable-line

    const updatePassenger = (passenger: any) => {
        const traveler = flightBook.bookingTravelers.find((traveler: any) => traveler.uuid === inspectedTraveler?.uuid)
        const travelerIndex = flightBook.bookingTravelers.findIndex((traveler: any) => traveler.uuid === inspectedTraveler?.uuid)
        if (traveler && traveler?.uuid) {
            const deleteTravellerParams = { uuid: traveler.uuid }
            dispatch(BookingActions.updateTraveler(deleteTravellerParams, passenger, travelerIndex))
        }
    }

    const {
        showValidationErrors,
        validationErrors,
        updateIsAddingPassenger,
        isAddingPassenger,
        travelerInfo
    } = props;

    return <AnimatePresence mode="wait">
        {isAddingPassenger ? <ManageTraveler
            inspectedTraveler={inspectedTraveler}
            onClose={() => { updateIsAddingPassenger(false) }}
            onSave={inspectedTraveler ? updatePassenger : addNewPassenger}
            isBooking
        /> : <Container key='overview' {...fadeInOutMotionProps}>
            <Title>
                Contact Information
                {!isMobile && <ClearContactInfoButton onClick={clearContactInfo}>
                    <StyledReload/>
                    <div>Clear Contact Info</div>
                </ClearContactInfoButton>}
            </Title>
            <Description>We pass this information to airlines so they can send updates about your booking and flight. We need one set per itinerary.</Description>
            {showValidationErrors && <div style={{marginTop: '20px'}}>
                {Object.values(validationErrors).some((error?: string) => !!error) && <StatusDisplay
                    isLoading={false}
                    isError={true}
                    label={Object.values(validationErrors).find((error?: string) => !!error)!}
                />}
            </div>}
            <ContactInfo>
                <PassengerInputContainer error={showValidationErrors && !!validationErrors.phone} marginRight="5%">
                    <PhoneInputContainer error={showValidationErrors && !!validationErrors.phone}>
                        <PhoneInput
                            defaultCountry="US"
                            value={props.travelerInfo.phone as any}
                            onChange={(value) => {
                                props.updateTravelerInfo({
                                    ...props.travelerInfo,
                                    phone: value,
                                })
                            }}
                        />
                    </PhoneInputContainer>
                    <PassengerLabel>Phone Number</PassengerLabel>
                </PassengerInputContainer>
                <PassengerInputContainer error={showValidationErrors && !!validationErrors.email}>
                    <PassengerInput error={showValidationErrors && !!validationErrors.email} value={props.travelerInfo.email} onChange={(ev: any) => {
                        props.updateTravelerInfo({
                            ...props.travelerInfo,
                            email: ev.target.value,
                        })
                    }} id='email' name='email'/>
                    <PassengerLabel>Email</PassengerLabel>
                </PassengerInputContainer>
            </ContactInfo>
            <AddTravelersHeader>
                <Title>{isMobile ? "Add Traveler Information" : "Add Travelers + Traveler Information"}</Title>
                <PassengerCount error={showValidationErrors && !!validationErrors.passengers} maxSelected={travelerInfo.selectedTravelerUuids.length === numberOfPassengers}>
                    ({travelerInfo.selectedTravelerUuids.length} / {numberOfPassengers} selected)
                </PassengerCount>
            </AddTravelersHeader>
            <Description>You will not be able to change the names on the ticket after booking. Please ensure that details match government-issued IDs.</Description>
            <LinksContainer>
                <Link rel="noopener noreferrer" target='_blank' href={tsaLink}>TSA Secure Flight rules</Link>
                {!isMobile && <LinkSeparator>•</LinkSeparator>}
                <Link rel="noopener noreferrer" target='_blank' href={policyLink}>Taekus Privacy Policy</Link>
            </LinksContainer>
            {priceDetail.stepUpRequired && <InformationText>{StepUpText.header}</InformationText>}
            <div>
                {flightBook.bookingTravelers.map((passenger: any, index: number) => <Traveler
                    key={index.toString()}
                    uuid={index.toString()}
                    selected={travelerInfo.selectedTravelerUuids.includes(index.toString())}
                    selectTraveler={handleSelectPassenger}
                    traveler={passenger}
                    editTraveler={inspectPassenger}
                />)}
                <AddTravelerButton onClick={() => {
                    setInspectedTraveler(undefined)
                    updateIsAddingPassenger(true)
                }}>
                    <StyledPlusIcon/>
                    <div>Add New Traveler</div>
                </AddTravelerButton>
            </div>
        </Container>}
    </AnimatePresence>
}

const StyledReload = styled(Reload)`
    width: 12px;
    height: 12px;
    margin-right: 10px;
`

const ClearContactInfoButton = styled.div`
    cursor: pointer;
    display: flex;
    font-size: 16px;
    height: 30px;
    align-items: center;
    border-bottom: 1px solid transparent;
    ${styles.Animation.transitionStyles}
    ${styles.MediaQueries.Desktop} {
        &:hover {
            border-bottom: 1px solid black;
        }
    }
    ${styles.MediaQueries.Mobile} {
        padding-top: 10px;
        flex: 1;
        justify-content: end;
    }
`

const StyledPlusIcon = styled(Plus)`
    width: 18px;
    height: 18px;
    margin-right: 20px;
`

const LinksContainer = styled.div`
    display: flex;
    margin-bottom: 20px;
    color: #6B6B6B;
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
        font-size: 14px;
        margin-top: 20px;
    }
`

const LinkSeparator = styled.div`
    margin: 0 4px;
`

const Link = styled.a`
    color: ${styles.Color.TaekusPurple};
    text-decoration: underline;
`

const AddTravelersHeader = styled.div`
    ${styles.MediaQueries.Desktop} {
        display: flex;
        align-items: end;
    }
`

const ContactInfo = styled.div`
    display: flex;
    padding-top: 20px;
    padding-bottom: 40px;
    ${styles.MediaQueries.Mobile} {
        flex-wrap: wrap;
    }
`

const Container = styled(motion.div)`
    ${styles.MediaQueries.Mobile} {
        padding: 15px;
        display: flex;
        flex-direction: column;
    }
`

type PassengerCountProps = {
    maxSelected: boolean,
    error: boolean,
}

const PassengerCount = styled.div<PassengerCountProps>`
    font-size: 14px;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    opacity: ${props => props.maxSelected ? 1 : 0.5};
    color: ${props => props.error ? styles.Color.FailureRed : (props.maxSelected ? styles.Color.TaekusPurple : styles.Color.Black)};
    white-space: nowrap;
    ${styles.Animation.transitionStyles}
    ${styles.MediaQueries.Desktop} {
        margin: 10px;
    }
    ${styles.MediaQueries.Mobile} {
        margin-bottom: 10px;
    }
`

const AddTravelerButton = styled.div`
    height: 90px;
    display: flex;
    align-items: center;
    border: 1px solid #7D7D7D;
    padding: 3px 23px;
    margin-bottom: 10px;
    ${styles.Animation.transitionStyles}
    cursor: pointer;
    &:hover {
        border: 1px solid ${styles.Color.TaekusPurple};
        fill: ${styles.Color.TaekusPurple};
        color: ${styles.Color.TaekusPurple};
    }
`

const Title = styled.div`
    color: #0E0E0E;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 39.68px */
    letter-spacing: 0.32px;
    margin-bottom: 4px;
    display: flex;
    ${styles.MediaQueries.Desktop} {
        font-size: 32px;
        justify-content: space-between;
        align-items: center;
    }
    ${styles.MediaQueries.Mobile} {
        font-size: 24px;
        flex-direction: column;
    }
`

const Description = styled.div`
    color: #6B6B6B;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    ${styles.MediaQueries.Desktop} {
        font-size: 16px;
    }
    ${styles.MediaQueries.Mobile} {
        font-size: 14px;
    }
`

const InformationText = styled.div`
    font-size: ${styles.Font.Size.Small};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    color: rgb(107, 107, 107);
    ${styles.MediaQueries.Desktop} {
        padding-bottom: 20px;
    }
    ${styles.MediaQueries.Mobile} {
        padding-bottom: 15px;
    }
`

const PhoneInputContainer = styled.div<PassengerInputProps>`
    border-bottom: 1px solid ${props => props.error ? 'red' : styles.Color.Grey};
    padding-bottom: 7px;
`

type PassengerInputContainerProps = {
    marginRight?: string,
    error?: boolean,
}

const PassengerInputContainer = styled.div<PassengerInputContainerProps>`
    overflow: hidden;
    ${props => props.marginRight && `margin-right: ${props.marginRight};`}
    color: ${props => props.error ? 'red' : styles.Color.NearBlack};
    ${styles.MediaQueries.Mobile} {
        width: 100%;
        margin-top: ${styles.Spacing.XS};
    }
    ${styles.MediaQueries.Desktop} {
        width: ${1 / 3 * 100}%;
        min-width: 300px;
        padding-right: 40px;
    }
    ${styles.Animation.transitionStyles}
`

const PassengerLabel = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: ${styles.Font.Size.Small};
    line-height: 140%;
    letter-spacing: 0.02em;
    margin-top: 4px;
    opacity: 0.5;
`

type PassengerInputProps = {
    error?: boolean;
}

const PassengerInput = styled.input<PassengerInputProps>`
    background-color: rgba(0,0,0,0);
    padding: 1px 2px 7px;
    font-size: 20px;
    line-height: 140%;
    height: 38px;
    letter-spacing: 0.02em;
    width: 100%;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid ${props => props.error ? 'red' : styles.Color.Grey};
    color: ${props => props.error ? 'red' : styles.Color.Black};
    outline: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    &::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
    ${styles.Animation.transitionStyles}
`

export default ManageTravelerInfo