import React, { useEffect, useRef, useState } from "react"

import { AnimatePresence, motion } from "framer-motion";
import { styled } from "styled-components"

import { expandVerticallyMotionProps, fadeInOutMotionProps } from "styles/motionConstants";
import styles from "styles/styles"

import { ReactComponent as LockIcon } from "assets/svg/Lock.svg";
import { useIsFirstRender } from "hooks/useIsFirstRender";

type SSNInputProps = {
    onChange: (ssn: string) => void,
    lastFour?: boolean,
    value?: string,
    errorMessage?: string,
}

const SSNInput = (props: SSNInputProps) => {
    const ref = useRef(null)
    const isFirstRender = useIsFirstRender()

    const [ssn, setSSN] = useState(props.value?.slice(0, 5) || '')
    const [currentInput, setCurrentInput] = useState(props.value?.slice(-4) || '')
    const [isInputFocused, setIsInputFocused] = useState(false)

    const isLabelMinified = isInputFocused || (ssn + currentInput !== '')
    const firstThree =  ssn.length >= 3 ? '●●●' : undefined
    const middleTwo = ssn.length >= 5 ? '●●' : undefined 

    const focus = (ev: React.FocusEvent<HTMLInputElement>) => {
        setIsInputFocused(true);
    }

    const blur = (ev: React.FocusEvent<HTMLInputElement>) => {
        setIsInputFocused(false);
    }

    // Handle backspacing when current input is empty
    const handleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if (currentInput === '' && ssn.length > 0 && ev.key === 'Backspace') {
            if (ssn.length === 3) {
                setCurrentInput(ssn.slice(0,3))
                setSSN('')
            } else if (ssn.length === 5 || ssn.length === 9) {
                setCurrentInput(ssn.slice(3,5))
                setSSN(ssn.slice(0,3))
            }
        }
    }

    // Handle update to SSN value, and clear/set current input value
    const handleInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const isInteger = /^\d+$/.test(ev.target.value)
        if ((ev.target.value !== '' && !isInteger && !ev.target.value.includes('⦁')) || ev.target.value.length > 4) { return }

        let updatedInput = ''
        if (ssn.length === 0 && ev.target.value.length === 3) {
            setSSN(ev.target.value)
            setCurrentInput(updatedInput)
        } else if (ssn.length === 3 && ev.target.value.length === 2) {
            setSSN(ssn + ev.target.value)
            setCurrentInput(updatedInput)
        } else {
            updatedInput = ev.target.value
            setCurrentInput(updatedInput)
        }
    }

    useEffect(() => {
        if (!isFirstRender) {
            // propagate ssn changes to parent onChange
            props.onChange(ssn + currentInput)
        }
    }, [ssn, currentInput]) // eslint-disable-line

    useEffect(() => {
        if (!isFirstRender && !props.lastFour) {
            setSSN('')
            setCurrentInput('')
        }
    }, [props.lastFour]) // eslint-disable-line

    return <>
        <Container $error={Boolean(props.errorMessage)} $isfocused={isInputFocused} onClick={() => { (ref.current as any).focus() }}>
            <Icon $isactive={Boolean(isInputFocused || (ssn && ssn !== ''))}/>
            <PreviewContainer $firstthree={Boolean(firstThree)} $middleTwo={Boolean(middleTwo)} $lastfour={Boolean(props.lastFour)}>
                {(firstThree || props.lastFour) && <DotContainer {...fadeInOutMotionProps}>
                    <Dot/>
                    <Dot/>
                    <Dot/>
                    -
                </DotContainer>}
                {(middleTwo || props.lastFour) && <DotContainer {...fadeInOutMotionProps}>
                    <Dot/>
                    <Dot/>
                    -
                </DotContainer>}
            </PreviewContainer>
            <InputContainer>
                <Label 
                    $isinputfocused={isInputFocused} 
                    $islabelminified={isLabelMinified}
                    $firstthree={Boolean(firstThree)}
                    $middleTwo={Boolean(middleTwo)}
                    $lastfour={Boolean(props.lastFour)}
                    $error={props.errorMessage}
                >
                    SSN
                </Label>
                <Input
                    inputMode='numeric'
                    onFocus={focus}
                    onBlur={blur}
                    onKeyDown={handleKeyDown}
                    ref={ref}
                    value={currentInput}
                    onChange={handleInputChange}
                    $reducepaddingonblur={Boolean(firstThree || props.lastFour)}
                    $error={props.errorMessage}
                />
            </InputContainer>
        </Container>
        <AnimatePresence mode='wait'>
            {props.errorMessage && <ErrorMessageContainer {...expandVerticallyMotionProps}>
                <ErrorMessage>{props.errorMessage}</ErrorMessage>
            </ErrorMessageContainer>}
        </AnimatePresence>
    </>
}

const ErrorMessageContainer = styled(motion.div)`
    min-width: 100%;
    width: min-content;
`

const ErrorMessage = styled.div`
    color: ${styles.Color.TaekusRed};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 14.88px */
    letter-spacing: 0.24px;
    padding: 8px 16px 0;
`

const DotContainer = styled(motion.div)`
    display: flex;
    align-items: center;
    height: 15px;
    margin-right: 2px;
`

const Dot = styled.div`
    min-width: 8px;
    min-height: 8px;
    background-color: ${styles.Color.TaekusBlack};
    border-radius: 50%;
    margin-right: 2px;
`

type LabelProps = {
    $error?: string,
    $isinputfocused: boolean,
    $islabelminified: boolean,
    $firstthree: boolean,
    $middleTwo: boolean,
    $lastfour: boolean,
}

const Label = styled.div<LabelProps>`
    pointer-events: none;
    position: absolute;
    padding: 9px 8px;
    display: flex;
    align-items: center;
    min-width: 308px;
    width: 100%;
    height: ${props => props.$islabelminified ? '30px' : '52px'};
    top: 0;
    left: ${props => props.$isinputfocused ? '0' : (props.$middleTwo || props.$lastfour) ? '-80px' : (props.$firstthree ? '-50px' : 0)};
    ${styles.Animation.transitionStyles}
    color: ${props => props.$error ? styles.Color.TaekusRed : (props.$isinputfocused ? styles.Color.TaekusPurple : styles.Color.TaekusGrey3)};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: ${props => props.$islabelminified ? '10px' : '16px'};
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
`

type PreviewContainerProps = {
    $firstthree: boolean,
    $middleTwo: boolean,
    $lastfour: boolean,
}

const PreviewContainer = styled.div<PreviewContainerProps>`
    display: flex;
    align-items: center;
    transition: all 0.2s ease-in;
    color: ${styles.Color.TaekusBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    padding: 23px 0 9px;
    letter-spacing: 0.32px;
    overflow: hidden;
    height: 100%;
    width: ${props => (props.$middleTwo || props.$lastfour) ? '72px' : (props.$firstthree ? '42px' : 0)};
    flex: 0 0 ${props => (props.$middleTwo || props.$lastfour) ? '72px' : (props.$firstthree ? '42px' : 0)};
`

const InputContainer = styled.div`
    position: relative;
    height: 100%;
    flex: 1 1 0;
    display: flex;
`

type InputProps = {
    $error?: string,
    $reducepaddingonblur: boolean,
}

const Input = styled.input<InputProps>`
    padding: 23px ${props => props.$reducepaddingonblur ? 0 : 8}px 9px;
    height: 100%;
    flex: 1 1 0;
    border: 1px solid transparent;
    border-radius: 2px;
    outline: 2px solid transparent;
    &:focus {
        background: linear-gradient(0deg, rgba(124, 61, 118, 0.05) 0%, rgba(124, 61, 118, 0.05) 100%), ${styles.Color.White};
        outline: 1px solid ${props => props.$error ? styles.Color.TaekusRed : styles.Color.TaekusPurple};
        border: 1px solid transparent;
        padding: 23px 8px 9px;
    }
    ${styles.Animation.transitionStyles}
`

type IconProps = {
    $isactive: boolean,
}

const Icon = styled(LockIcon)<IconProps>`
    min-width: 32px;
    max-width: 32px;
    min-height: 32px;
    max-height: 32px;
    fill: ${props => props.$isactive ? styles.Color.TaekusPurple : styles.Color.TaekusGrey3};
    margin-right: 4px;
    ${styles.Animation.transitionStyles}
`

type ContainerProps = {
    $isfocused: boolean,
    $error: boolean,
}

const Container = styled.div<ContainerProps>`
    min-width: 308px;
    width: 100%;
    height: 52px;
    border: 1px solid ${props => (props.$error && !props.$isfocused) ? `${styles.Color.TaekusRed} !important` : styles.Color.TaekusGrey4};
    border-radius: 2px;
    background-color: ${styles.Color.White};
    padding-left: 8px;
    display: flex;
    align-items: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    color: ${styles.Color.TaekusBlack};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 19.84px */
    letter-spacing: 0.32px;
    cursor: text;
    &:hover {
        box-shadow: 0px 0px 16px 0px rgba(124, 61, 118, 0.15);
        border: 1px solid transparent;
    }
    ${props => props.$isfocused && `
        box-shadow: 0px 0px 16px 0px rgba(124, 61, 118, 0.15);    
    `}
    ${styles.Animation.transitionStyles}
`

export default SSNInput