import Button, { ButtonSize, ButtonType } from 'components/common/Button';
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import styles from 'styles/styles';
import { ReactComponent as Close } from "assets/svg/Close.svg";
import { ReactComponent as AuthCode } from "assets/svg/AuthCode.svg";
import { ReactComponent as Phone } from "assets/svg/Phone.svg";
import { ReactComponent as QR } from "assets/svg/QRCode.svg";
import { ReactComponent as Copy } from "assets/svg/Copy.svg";
import QRCode from 'react-qr-code';
import { AnimatePresence, motion } from 'framer-motion';
import Input from 'components/pages/Settings/components/Input';
import { Actions } from 'redux/currentUser';
import Spinner from 'components/common/Spinner';
import { fadeInOutMotionProps } from 'styles/motionConstants';

interface AuthenticatorSetupProps {
    onClose: () => void;
    onComplete: (success: boolean, message: string) => void;
}

const messages = {
    DownloadApp: 'Install an authenticator application of your choice (i.e., Google Authenticator, Twilio Authy, or the like)',
    ScanQRCode: 'Scan the QR code with your authenticator application, or copy the authentication key code to add it manually. Then, follow the prompts in the authenticator app.',
    EnterCode: 'Once you\'ve added Taekus to your authenticator app, enter the verification code generated to receive Taekus 2FA codes via app.',
}

export const AuthenticatorSetup: React.FC<AuthenticatorSetupProps> = ({ onClose, onComplete }) => {
    const dispatch = useDispatch();
    const { 
        isFetchingMFASecret, 
        mfaSecretError,
        isFetchingMFAConfirm,
        mfaConfirmError,
        configUrl,
        isMFAConfirmed
    } = useSelector((state: any) => state.currentUser);
    const [copyMessage, setCopyMessage] = useState<string | null>(null);
    const [code, setCode] = useState('');

    const mfaSecret = useMemo(() => {
        if (!configUrl) return '';
        return new URL(configUrl).searchParams.get('secret') || '';
    }, [configUrl]);

    useEffect(() => {
        if (isMFAConfirmed) {
            onComplete(true, 'You will now receive 2FA codes through your linked authenticator application.');
        }
    }, [isMFAConfirmed]);

    useEffect(() => {
        dispatch(Actions.getMFASecret());
    }, [dispatch]);

    useEffect(() => {
        if (mfaSecret) {
            setCode('');
        }
    }, [mfaSecret]);

    useEffect(() => {
        dispatch(Actions.clearMFAStates());
        
        return () => {
            dispatch(Actions.clearMFAStates());
        };
    }, [dispatch]);

    const getDisplayValue = (value: string) => {
        return value.replace(/(.{3})/g, '$1 ').trim();
    };

    const handleCopyClick = async () => {
        try {
            await navigator.clipboard.writeText(mfaSecret);
            setCopyMessage('Copied!');
            // Clear message after 2 seconds
            setTimeout(() => {
                setCopyMessage(null);
            }, 2000);
        } catch (err) {
            setCopyMessage('Failed to copy');
        }
    };

    const handleVerifyCode = () => {
        dispatch(Actions.confirmMFAAuthenticator({
            authentication_token: code
        }));
    };

    const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (mfaConfirmError) {
            dispatch(Actions.clearMFAConfirmError());
        }
        
        const newValue = e.target.value.replace(/\s/g, '').replace(/\D/g, '');
        setCode(newValue);
    };

    const renderSecretKeyContent = () => {
        return (
            <AnimatePresence mode="wait">
                {mfaSecretError ? (
                    <motion.div key="error" {...fadeInOutMotionProps}>
                        <div>Error loading MFA setup. Please try again later.</div>
                    </motion.div>
                ) : isFetchingMFASecret || !configUrl ? (
                    <LoadingContainer key="loading" {...fadeInOutMotionProps}>
                        <Spinner size={40}/>
                    </LoadingContainer>
                ) : (
                    <motion.div key="content" {...fadeInOutMotionProps}>
                        <SecretKeyContainer>
                            <QRCodeContainer>
                                <QRCode value={configUrl} size={120} />
                            </QRCodeContainer>
                            <QRCodeKeyContainer>
                                <KeyForManualEntry>Key for Manual Entry:</KeyForManualEntry>
                                <SecretKeyCopyContainer onClick={handleCopyClick}>
                                    <SecretKeyText>{mfaSecret}</SecretKeyText>
                                    <CopyButton>
                                        <CopyIcon/>
                                    </CopyButton>
                                </SecretKeyCopyContainer>
                                <AnimatePresence>
                                    {copyMessage && (
                                        <CopyMessage
                                            initial={{ opacity: 0, y: -10 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            exit={{ opacity: 0 }}
                                            transition={{ duration: 0.2 }}
                                        >
                                            {copyMessage}
                                        </CopyMessage>
                                    )}
                                </AnimatePresence>
                            </QRCodeKeyContainer>
                        </SecretKeyContainer>
                    </motion.div>
                )}
            </AnimatePresence>
        );
    };

    return (
        <MobileContainer onClick={(e) => {
            if (e.target === e.currentTarget) {
                onClose();
            }
        }}>
            <Container onClick={(e) => e.stopPropagation()}>
                <HeadingContainer>
                    <HeadingText>Set up your authenticator application</HeadingText>
                    <CloseButton onClick={onClose}/>
                </HeadingContainer>
                <ContentContainer>
                    <InstructionsContainer>
                        <InstructionsTextAndImageContainer>
                            <div><PhoneIcon/></div>
                            <InstructionsText>
                                {messages.DownloadApp}
                            </InstructionsText>
                        </InstructionsTextAndImageContainer>
                        <Line/>
                        <InstructionsTextAndImageContainer>
                            <div><QRCodeIcon/></div>
                            <InstructionsText>
                                {messages.ScanQRCode}
                            </InstructionsText>
                        </InstructionsTextAndImageContainer>
                        <MobileQRWrapper>
                            {renderSecretKeyContent()}
                        </MobileQRWrapper>
                        <Line/>
                        <InstructionsTextAndImageContainer>
                            <div><AuthCodeIcon/></div>
                            <InstructionsText>
                                {messages.EnterCode}
                            </InstructionsText>
                        </InstructionsTextAndImageContainer>
                    </InstructionsContainer>
                    <SetupContainer>
                        <MobileQRHiddenWrapper>
                            {renderSecretKeyContent()}
                        </MobileQRHiddenWrapper>
                        <Input
                            label="Verification Code"
                            value={getDisplayValue(code)}
                            onChange={handleCodeChange}
                            showsError={!!mfaConfirmError}
                            errorMessage={mfaConfirmError ? "Invalid code. Please try again." : undefined}
                        />
                        <ButtonContainer>
                            <Button 
                                buttonType={ButtonType.Purple}
                                size={ButtonSize.Auto}
                                onClick={handleVerifyCode}
                                disabled={code.length < 6 || isFetchingMFAConfirm}
                                label="Save"
                            />
                        </ButtonContainer>
                    </SetupContainer>
                </ContentContainer>
            </Container>
        </MobileContainer>
    );
};

const MobileQRHiddenWrapper = styled.div`
    width: 100%;
${styles.MediaQueries.Mobile} {
    display: none;
}
`;

const MobileContainer = styled.div`
    ${styles.MediaQueries.Mobile} {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1000;
    }
`;

const ButtonContainer = styled.div`
    width: 200px;
`;

const CopyIcon = styled(Copy)`
    width: 10px;
    height: 10px;
    margin-top: -5px;
`

const SecretKeyText = styled.div`
    color: ${styles.Color.TaekusPurple};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`

const SecretKeyCopyContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    cursor: pointer;
     &:hover {
        opacity: 0.8;
    }
`

const KeyForManualEntry = styled.div`
    color: ${styles.Color.TaekusGrey2};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.5px;
`

const QRCodeKeyContainer = styled.div`
    display: flex;
    height: 40px;
    align-items: flex-start;
    align-self: stretch;
    flex-direction: column;
`

const QRCodeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
`

const SecretKeyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
`

const SetupContainer = styled.div`
    display: flex;
    padding: 24px 40px 40px 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
    ${styles.MediaQueries.Mobile} {
        border-top: 1px solid ${styles.Color.TaekusGrey4};
        padding-top: 48px;
    }
`

const Line = styled.div`
    width: 344px;
    height: 1px;
    background: ${styles.Color.TaekusGrey4};
`

const IconStyles = `
    width: 32px;
    height: 32px;
`

const PhoneIcon = styled(Phone)`${IconStyles}`
const QRCodeIcon = styled(QR)`${IconStyles}`
const AuthCodeIcon = styled(AuthCode)`${IconStyles}`

const InstructionsText = styled.div`
    color: ${styles.Color.TaekusBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
`

const InstructionsContainer = styled.div`
    display: flex;
    padding: 32px 40px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    flex: 1 0 0;
    align-self: stretch;
    ${styles.MediaQueries.Mobile} {
        padding: 32px 24px;
        width: 100%;
        gap: 16px;
    }
`;

const MobileQRWrapper = styled.div`
    display: none;
    ${styles.MediaQueries.Mobile} {
        display: block;
        width: 100%;
        padding: 0 24px;
    }
`;

const InstructionsTextAndImageContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
`

const ContentContainer = styled.div`
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
    }
`

const Container = styled.div`
    display: flex;
    width: 848px;
    flex-direction: column;
    align-items: flex-start;
    background: ${styles.Color.White};
    ${styles.MediaQueries.Mobile} {
        width: 90%;
        height: 90%;
        position: absolute;
        top: 5%;
        left: 5%;
        overflow-y: auto;
        max-height: 90vh;
    }
`;

const HeadingContainer = styled.div`
    display: flex;
    padding: 40px 40px 16px 40px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`;

const HeadingText = styled.div`
    color: ${styles.Color.TaekusBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 125% */
    letter-spacing: 0.32px;
`;

const CloseButton = styled(Close)`
    width: 24px;
    height: 24px;
    fill: ${styles.Color.TaekusBlack};
    cursor: pointer;
    ${styles.MediaQueries.Mobile} {
        display: none;
    }
`

const CopyButton = styled.button`
    background: none;
    border: none;
    padding: 4px;
    display: flex;
    align-items: center;
`;

const CopyMessage = styled(motion.div)`
    color: ${styles.Color.TaekusGrey2};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    line-height: 16px;
    margin-top: 8px;
`;

const LoadingContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    height: 176px;
    justify-content: center;
    width: 100%;
`;