import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OverlayTrigger, Popover } from 'react-bootstrap'

import moment from 'moment'

import styled from 'styled-components'

import { Locale, USDCurrencyOptions } from 'utils/constants'

import { Actions as AutoPayActions } from 'redux/features/banking/autopay'
import { Actions as LinkedAccountActions } from 'redux/features/banking/linkedAccounts'
import { Actions as PayeeActions } from 'redux/features/banking/payees'

import Spinner from 'components/common/Spinner'

import styles from 'styles/styles'

import { ReactComponent as Reload } from "assets/svg/Reload.svg";
import { useAccountTypeInformation } from 'hooks/useAccountTypeInformation'

const SIDEBAR_WIDTH = 240
const CONTENT_WIDTH = 890

type Metric = {
    label: string;
    value: string | number;
    withOverlay?: boolean;
    overlayContent?: React.ReactElement;
};

const MetricDisplay = ({ label, value, isLoading }: { label: string, value: string | number, isLoading: boolean }) => (
    <Flex>
        <Label>{label}</Label>
        {isLoading ? (
            <SmallSpinnerContainer>
                <Spinner />
            </SmallSpinnerContainer>
        ) : (
            <Value>{value}</Value>
        )}
    </Flex>
);

const FundingHeader = () => {
    const dispatch = useDispatch();

    // Redux state
    const currentUser = useSelector((state: any) => state.currentUser.currentUser)
    const banking = useSelector((state: any) => state.banking)
    const autopay = useSelector((state: any) => state.autopay)
    const transfers = useSelector((state: any) => state.transfers.transfers)
    const shouldUpdateLinkedAccounts = useSelector((state: any) => state.linkedAccounts.shouldUpdateLinkedAccounts)
    const isMobile = useSelector((state: any) => state.global.isMobile)
    const { hasCreditAccount, hasDebitAccount, isSelectedAccountCredit, isLoading: isLoadingAccountTypes } = useAccountTypeInformation();
    const selectedAccount = useSelector((state: any) => 
        state.banking.account
    );

    const balanceAmount = Number(banking?.account.balance.availableBalance)?.toLocaleString(Locale.English, USDCurrencyOptions)
    const monthlySpendAmount = banking?.account.mtdSpending?.toLocaleString(Locale.English, USDCurrencyOptions)
    const transferAmount = banking.account.mtdTransfers?.toLocaleString(Locale.English, USDCurrencyOptions)
    const creditLimitAmount = banking.account.transferLimit?.toLocaleString(Locale.English, USDCurrencyOptions)

    const currentBalance = Number(selectedAccount?.balance?.currentBalance)?.toLocaleString(Locale.English, USDCurrencyOptions)
    const creditLimit = Number(selectedAccount?.creditLimit)?.toLocaleString(Locale.English, USDCurrencyOptions)
    const paymentDueDay = selectedAccount?.paymentDueDate
    const minimumPaymentDue = isSelectedAccountCredit ? moment()
        .date(paymentDueDay)
        // If current date is past due date, show next month
        .add(moment().date() >= paymentDueDay ? 1 : 0, 'months')
        .format('MMMM D, YYYY') : 'N/A'

    // On component render, fetch linked accounts
    useEffect(() => {
        dispatch(LinkedAccountActions.getLinkedAccounts())
        dispatch(LinkedAccountActions.getInternalAccounts())
        dispatch(PayeeActions.getPayees())
    }, [dispatch])

    // If Plaid Modal creates or verifies an account, check if we should re-fetch linked accounts
    useEffect(() => {
        if (shouldUpdateLinkedAccounts){
            dispatch(LinkedAccountActions.getLinkedAccounts())
        }
    }, [shouldUpdateLinkedAccounts, dispatch])

    const creditMetrics: Metric[] = [
        { label: 'Current Balance', value: currentBalance },
        { label: 'Credit Limit', value: creditLimit },
        { label: 'Minimum Payment Due', value: minimumPaymentDue },
    ];

    const debitMetrics: Metric[] = [
        { label: 'Balance', value: balanceAmount },
        { label: 'Spend this Month', value: monthlySpendAmount },
        { 
            label: 'ACH Transfer Limit', 
            value: creditLimitAmount,
            withOverlay: true,
            overlayContent: (
                <CustomPopover id='popover'>
                    <div><strong>Used:</strong> {transferAmount} / {creditLimitAmount}</div>
                    <div><strong>Remaining:</strong> {(banking.account.transferLimit - banking.account.mtdTransfers).toLocaleString(Locale.English, USDCurrencyOptions)}</div>
                </CustomPopover>
            )
        },
    ];

    if (isLoadingAccountTypes) {
        return (
            <Details>
                <SmallSpinnerContainer>
                    <Spinner />
                </SmallSpinnerContainer>
            </Details>
        );
    }

    const metrics = isSelectedAccountCredit ? creditMetrics : debitMetrics;

    return (
        <Details>
            {metrics.map((metric, index) => (
                metric.withOverlay ? (
                    <OverlayTrigger
                        key={metric.label}
                        trigger={['hover', 'focus']}
                        placement="bottom"
                        overlay={!banking.isLoading && metric.overlayContent ? metric.overlayContent : <Popover id="empty"/>}
                    >
                        <div>
                            <MetricDisplay 
                                key={metric.label} 
                                label={metric.label} 
                                value={metric.value} 
                                isLoading={banking.isLoading} 
                            />
                        </div>
                    </OverlayTrigger>
                ) : (
                    <MetricDisplay 
                        key={metric.label} 
                        label={metric.label} 
                        value={metric.value} 
                        isLoading={banking.isLoading} 
                    />
                )
            ))}
            {!isSelectedAccountCredit && (
                <AutoReloadContainer>
                    <AutoReloadLabel>
                        <div className='d-flex align-items-center'>
                            <div>Auto reload</div>
                            <ReloadIconWrapper><StyledReload/></ReloadIconWrapper>
                        </div>
                        <div>{autopay.workingCopy.active ? <GreenText>ON</GreenText> : 'OFF'}</div>
                    </AutoReloadLabel>
                </AutoReloadContainer>
            )}
        </Details>
    );
};

const CustomPopover = styled(Popover)`
    padding: ${styles.Spacing.XS};
    background-color: ${styles.Color.TaekusPurple};
    color: white;
    white-space: nowrap;
    .arrow { display: none; }
`

const AutoReloadContainer = styled.div`
    display: flex;
    align-items: center;
    user-select: none;
    margin: 0;
    ${styles.MediaQueries.Mobile} {
        padding-bottom: ${styles.Spacing.XS};
    }
`

const AutoReloadLabel = styled.div`
    display: flex;
    justify-content: space-between;
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
    ${styles.MediaQueries.Desktop} {
        width: 120px;
        margin-right: ${styles.Spacing.S};
    }
`

const StyledReload = styled(Reload)`
    width: 12px;
    height: 12px;
`

const ReloadIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
`

const GreenText = styled.span`
    color: #008e00;
`

const SmallSpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 14px;
`

const Flex = styled.div`
    display: flex;
    align-items: center;
    ${styles.MediaQueries.Mobile} {
        width: 100%;
        justify-content: space-between;
    }
    ${styles.MediaQueries.Desktop} {
        padding-right: ${styles.Spacing.M};
    }
`

const Label = styled.div`
    margin-right: ${styles.Spacing.XXS};
`

const Value = styled.div`
    font-style: normal;
    font-weight: ${styles.Font.Weight[400]};
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: ${styles.Color.TaekusBlue};
`

const Details = styled.div`
    display: flex;
    justify-content: end;
    white-space: nowrap;
    ${styles.MediaQueries.Desktop} {
        min-width: ${SIDEBAR_WIDTH + CONTENT_WIDTH + 40}px;
        align-items: center;
        margin-top: 30px;
        margin-bottom: ${styles.Spacing.XS};
        margin-right: ${styles.Spacing.M};
        height: 24px;
    }
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
        margin: 0 ${styles.Spacing.S};
    }
`
export default FundingHeader