import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import { styled } from "styled-components"

import { SignupStep } from "components/signup/constants";
import { updateApplication, updateLead, updateStep } from "components/signup/signupSlice";
import { CompanyStructure, StepDirection } from "components/signup/types";

import ArrowButton, { ArrowDirection } from "components/signup/components/ArrowButton";
import Button from "components/signup/components/Button";
import Input from "components/signup/components/Input";
import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";

import styles from "styles/styles";

const CaptureBizTaxNum = () => {
    const dispatch = useDispatch()
    
    const inputRef = useRef(null)

    const application = useSelector((state: any) => state.signup.application)
    const leadUuid = useSelector((state: any) => state.signup.leadUuid)
    const stepDirection = useSelector((state: any) => state.signup.direction)

    const [ein, setEin] = useState(application.taxIdentificationNumber || '')
    const [isInputDisabled, setIsInputDisabled] = useState(false)

    const isEinValid = ein.replace(/\D/g, '').length === 9
    const isButtonDisabled = !isEinValid || isInputDisabled

    const handleBack = (ev: any) => {
        ev.stopPropagation();
        dispatch(updateStep({
            step: SignupStep.BizDBA,
            direction: StepDirection.Left
        }))
    }

    const handleSkip = (ev: React.MouseEvent) => {
        ev.preventDefault();
        setIsInputDisabled(true)
        const applicationChanges = { taxIdentificationNumber: undefined }

        dispatch(updateApplication(applicationChanges))
        dispatch(updateLead({ leadUuid, application: applicationChanges }))
        dispatch(updateStep({ step: SignupStep.BizNAICS, direction: StepDirection.Right }))
    }

    const handleSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();
        setIsInputDisabled(true)
        const applicationChanges = { taxIdentificationNumber: ein }

        dispatch(updateApplication(applicationChanges))
        dispatch(updateLead({ leadUuid, application: applicationChanges }))
        dispatch(updateStep({ step: SignupStep.BizNAICS, direction: StepDirection.Right }))
    }

    const handleInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (/[^\d-]/.test(ev.target.value)) return;

        if (ein.includes('-') && !ev.target.value.includes('-')) {
            setEin(ev.target.value.slice(0,1))
            return
        }

        const cleaned = ev.target.value.replaceAll('-', '')
        const formatted = cleaned.length >= 2 ? cleaned.slice(0, 2) + '-' + cleaned.slice(2) : cleaned

        if (cleaned.length <= 9) {
            setEin(formatted)
        }
    }

    // Focus EIN input on mount
    useEffect(() => {
        (inputRef.current as any)?.focus()
    }, [])

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='BusinessEIN'
    >
        <form className="d-flex flex-column align-items-center" onSubmit={handleSubmit}>
            <Title>What’s {application.companyName || 'your business'}’s EIN?</Title>
            {application.companyStructure === CompanyStructure.SoleProp && <Text>An EIN is registered directly with the IRS. You may or may not have one.</Text>}
            <Input inputmode="numeric" onChange={handleInputChange} value={ein} parentRef={inputRef} label="EIN"/>
            <ButtonContainer>
                <BackButtonContainer>
                    <ArrowButton onClick={handleBack} direction={ArrowDirection.Left}/>
                </BackButtonContainer>
                {application.companyStructure === CompanyStructure.SoleProp && <BackButtonContainer>
                    <Button secondary onClick={handleSkip}>I don't have an EIN</Button>
                </BackButtonContainer>}
                <Button disabled={isButtonDisabled} type="submit">Next</Button>
            </ButtonContainer>
        </form>
    </StepContainer>
}

const BackButtonContainer = styled.div`
    margin-right: 16px;
`

const ButtonContainer = styled.div`
    margin-top: 32px;
    display: flex;
`

const Text = styled.div`
    color: ${styles.Color.TaekusGrey2};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    margin-bottom: 32px;
    ${styles.MediaQueries.Mobile} {
        font-size: 14px;
    }
`

const Title = styled.div`
    color: ${styles.Color.TaekusBlack};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-bottom: 16px;
    ${styles.MediaQueries.Mobile} {
        font-size: 24px;
    }
`

export default CaptureBizTaxNum