import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import { styled } from "styled-components"

import { useIsFirstRender } from "hooks/useIsFirstRender";

import { validateInviteCode } from "components/signup/signupSlice";

import Button from "components/signup/components/Button";
import Input from "components/signup/components/Input";
import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";

import { fadeInOutMotionProps } from "styles/motionConstants";
import styles from "styles/styles";

const InviteCode = () => {
    const dispatch = useDispatch()

    const isFirstRender = useIsFirstRender()
    const isLoading = useSelector((state: any) => state.signup.isLoading)
    const isLoadingUser = useSelector((state: any) => state.currentUser.isFetching)
    const isInviteCodeValid = useSelector((state: any) => state.signup.isInviteCodeValid)
    const isMobile = useSelector((state: any) => state.global.isMobile)
    const stepDirection = useSelector((state: any) => state.signup.direction)

    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);

    const inputRef = useRef(null)

    const [inviteCode, setInviteCode] = useState(params.get('invite') || '')
    const [isInputDisabled, setIsInputDisabled] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

    const isInputValid = inviteCode !== ''
    const isButtonDisabled = !isInputValid || isInputDisabled

    const handleSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();
        setIsInputDisabled(true)
        dispatch(validateInviteCode(inviteCode))
    }

    const handleInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (!/[^a-zA-Z0-9]/.test(ev.target.value)) {
            setInviteCode(ev.target.value)
        }
    }

    useEffect(() => {
        if (isFirstRender && isInputValid && !isLoadingUser) {
            setIsInputDisabled(true)
            dispatch(validateInviteCode(inviteCode))
        }
    }, [dispatch]) // eslint-disable-line

    useEffect(() => {
        if (!isFirstRender && !isLoading && !isInviteCodeValid) {
            setErrorMessage('Invite code invalid.')
            setIsInputDisabled(false)
        }
    }, [isLoading]) // eslint-disable-line

    // Focus input on mount
    useEffect(() => {
        if (!isMobile) {
            (inputRef.current as any)?.focus()
        }
    }, []) // eslint-disable-line

    return <StepContainer
        {...(isFirstRender ? fadeInOutMotionProps : getStepContainerMotionProps(stepDirection))}
        key='InviteCode'
    >
        <form className="d-flex flex-column align-items-center" onSubmit={handleSubmit}>
            <Title>Enter your invite code</Title>
            <Text>Taekus is an invite-only community. To get started with your application for a Taekus product, enter your invite code. </Text>
            <Input showsError={isInviteCodeValid === false} errorMessage={errorMessage} onChange={handleInputChange} value={inviteCode} setValue={setInviteCode} parentRef={inputRef} label="Invite Code"/>
            <ButtonRow>
                <Button isLoading={isLoading} disabled={isButtonDisabled} type="submit">Next</Button>
            </ButtonRow>
        </form>
    </StepContainer>
}

const ButtonRow = styled.div`
    margin-top: 32px;
`

const Text = styled.div`
    color: ${styles.Color.TaekusGrey2};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    margin-bottom: 32px;
    ${styles.MediaQueries.Mobile} {
        font-size: 14px;
    }
`

const Title = styled.div`
    color: ${styles.Color.TaekusGrey1};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-bottom: 16px;
    ${styles.MediaQueries.Mobile} {
        font-size: 24px;   
    }
`

export default InviteCode