import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import moment from "moment";

import { Toast as BootstrapToast } from 'react-bootstrap'

import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";

import { updateToast } from "redux/features/global/globalSlice";

import { debounce } from "utils/debounce";

import { AppPath } from "components/appRouter/constants";

import { ReactComponent as ArrowRight } from "assets/svg/ArrowRight.svg";
import { ReactComponent as Close } from "assets/svg/Close.svg";

import { fadeInOutMotionProps } from "styles/motionConstants";
import styles from "styles/styles";

const Toast = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    // Redux state
    const banking = useSelector((state: any) => state.banking)
    const toast = useSelector((state: any) => state.global.toast)
    const isMobile = useSelector((state: any) => state.global.isMobile)
    
    // Component state
    const [key, setKey] = useState(moment().unix())

    const clearToast = () => {
        dispatch(updateToast({
            message: '',
            isError: false,
            isOpen: false,
        }))
        setKey(0)
    }

    const debouncedClearToast = useCallback(debounce(clearToast, 30000), []) // eslint-disable-line

    const setPriorityToast = () => {
        const goToCardSettings = () => { history.push(AppPath.CardSettings) }
        const setPinToast = {
            disableTimeout: true,
            isOpen: true,
            isError: false,
            message: 'Set your card PIN',
            action: goToCardSettings
        }

        if (banking.account.needsPinSet) {
          dispatch(updateToast(setPinToast))
        }
    }

    useEffect(() => {
        if (toast.isOpen && !toast.isError && !toast.disableTimeout) {
            // Auto-clear toast after 30s
            debouncedClearToast()
        }
        setKey(moment().unix())
    }, [toast]) // eslint-disable-line

    useEffect(() => {
        if (!banking.isLoading) {
            setPriorityToast()
        }
    }, [banking.isLoading]) // eslint-disable-line

    const handleAction =  () => {
        toast.action && toast.action()
        clearToast()
    }

    return <AnimatePresence mode="wait">
        {toast.isOpen && !isMobile && <ToastContainer key={key} {...fadeInOutMotionProps} onClick={handleAction}>
            <CustomToast>
                <Body iserror={toast.isError.toString()}>
                    <div>{toast.message}</div>
                    {toast.action ? <CustomArrow fill='white'/> : <CustomClose fill='white' onClick={clearToast}/>}
                </Body>
            </CustomToast>
        </ToastContainer>}
    </AnimatePresence>
}

const CustomArrow = styled(ArrowRight)`
    height: ${styles.Spacing.XS};
    width: auto;
`

const CustomClose = styled(Close)`
    cursor: pointer;
    height: 24px;
    width: 24px;
    fill: white;
`

type BodyProps = {
    iserror: string,
}

const Body = styled(BootstrapToast.Body)<BodyProps>`
    background-color: ${props => props.iserror === 'true' ? 'red' : styles.Color.TaekusPurple};
    color: ${styles.Color.White};
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const CustomToast = styled(BootstrapToast)`
    background-color: transparent;
`

type ToastContainerProps = {
    onClick?: any
}

const ToastContainer = styled(motion.div)<ToastContainerProps>`
    position: absolute;
    width: 350px;
    bottom: 20px;
    left: 20px;
    z-index: 10;
    ${props => props.onClick !== undefined && 'cursor: pointer;'}
    ${styles.MediaQueries.Print} {
        display: none;
    }
`

export default Toast