import React from "react"
import { useHistory } from "react-router";

import { styled } from "styled-components"

import styles from "styles/styles";

import Button from "components/signup/components/Button";

import { ReactComponent as TaekusLogoSVG } from "assets/svg/TaekusLogo.svg";

const VerifyFailed = () => {
    const history = useHistory()
    const redirect = () => {
        history.push('/login')
    }

    return <Container>
        <IconContainer>
            <StyledTaekusLogo/>
        </IconContainer>
        <Title>Oops, something went wrong.</Title>
        <Text>An error occured while verifying your account. Please contact support at <PurpleText>support@taekus.com</PurpleText>.</Text>
        <Button onClick={redirect}>Back to Login</Button>
    </Container>
}

const PurpleText = styled.span`
    color: ${styles.Color.TaekusPurple};
`

const StyledTaekusLogo = styled(TaekusLogoSVG)`
    width: 130px;
    height: 24px;
    ${styles.Animation.transitionStyles}
`

const IconContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    padding: 25px 40px;
`

const Text = styled.div`
    color: ${styles.Color.TaekusGrey2};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    margin-bottom: 16px;
`

const Title = styled.div`
    color: ${styles.Color.TaekusGrey1};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-bottom: 32px;
`

const Container = styled.div`
    width: 100dvw;
    height: 100dvh;
    background: var(--Color-Taekus-Cream, #F9F8F5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export default VerifyFailed