import React, { useEffect, useRef, useState } from "react"
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { parseResponse, refreshTokenGetWebsocketEndpoint, wsRequest } from "components/common/Websocket/WsUtilities";
import { FlightSearchTypes, WsJobStatus, WsMessage } from 'components/common/Websocket/WsMessageConstants'
import { v4 as uuidv4 } from 'uuid';
import {ItinerarySearchDataKeys, SearchSource, TicketType} from "components/pages/Rewards/constants";

type FlightSearchWsProps = {
	// Calendar search
	calendarSearchParams: any;
	calendarResponse: any[];
	isCalendarLoading: boolean;
	setCalendarResponse: (arg: any) => void;
	setFetchedCalendarWeeks: (arg: any) => void;
	setErroredCalendarWeeks: (arg: any) => void;
	setIsCalendarLoading: (arg: any) => void;
	calendarRequestIdToParams: React.MutableRefObject<any>;

	// Common
	itinerarySearchParams: any;
	itinerarySearchResponse: any[];
	itinerarySearchData: any;
	setItinerarySearchResponse: (arg: any) => void;
	setItinerarySearchData: (arg: any) => void;

	// Itinerary search
	setIsItineraryLoading: (arg: any) => void;
	setIsItineraryError: (arg: any) => void;
	itineraryRequestIdToParams: React.MutableRefObject<any>;
	setItinerarySearchSessionId: (arg: any) => void;
	itinerarySearchSessionId: string | null;

	// Award search
	setIsAwardError: (arg: any) => void;
	setIsAwardLoading: (arg: any) => void;
	awardRequestIdToParams: React.MutableRefObject<any>;
	setAwardSearchSessionId: (arg: any) => void;
	awardSearchSessionId: string | null;

	// NDC search
	setIsNdcError: (arg: any) => void;
	setIsNdcLoading: (arg: any) => void;
	ndcRequestIdToParams: React.MutableRefObject<any>;
	setNdcSearchSessionId: (arg: any) => void;
	ndcSearchSessionId: string | null;
}

const FlightSearchWs = (props: FlightSearchWsProps) => {
	const heartbeatIntervalRef = useRef<number | null>(null);
	const previousHeartbeats: Record<string, number> = {};
	const socketUrl = refreshTokenGetWebsocketEndpoint("flights/search/");
	const [resendMessage, setResendMessage] = useState<boolean>(false);
	const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
		shouldReconnect: (closeEvent: CloseEvent) => {
			setResendMessage(true)
			return true 
		},
		onReconnectStop: (numAttempts: number) => {
			props.setIsCalendarLoading(false)
			props.setIsItineraryLoading(false)
			props.setIsAwardLoading(false)
			props.setIsNdcLoading(false)
		},
		reconnectAttempts: 3,
		reconnectInterval: 3000,
	});

	const setLowestPrice = (results: any[], priceKey: string, hasAwardTicket: boolean) => {
		const lowestFare = results.reduce((a: any, b: any) => a[priceKey] < b[priceKey] ? a : b)
		const lowestFareDate = lowestFare.slices[0].departureTimeDate.slice(0, 10)
		const currentLowest = props.calendarResponse.find((option: any) => {
			return option.date === lowestFareDate
		})
		const lowestPrice= currentLowest ? Math.min(currentLowest.price, lowestFare[priceKey]) : lowestFare[priceKey]
		props.setCalendarResponse([{
			date: lowestFareDate,
			price: lowestPrice,
			hasAwardTicket: hasAwardTicket
		}])
	}


	const fetchAmadeus = () => {
		const itineraryRequestId = uuidv4()
		const itineraryParams = {
			...props.itinerarySearchParams,
			searchType: FlightSearchTypes.Itinerary
		}
		if (props.itinerarySearchSessionId) {
			itineraryParams.sessionId = props.itinerarySearchSessionId
		}
		props.setIsItineraryLoading(true)
		wsRequest(sendMessage, WsMessage.Booking.FlightSearch, itineraryParams, itineraryRequestId)
		props.itineraryRequestIdToParams.current[itineraryRequestId] = itineraryParams
	}

	const fetchNdc = () => {
		const ndcRequestId = uuidv4()
		const ndcParams = {
			...props.itinerarySearchParams,
			searchType: FlightSearchTypes.Ndc
		}
		if (props.ndcSearchSessionId) {
			ndcParams.sessionId = props.ndcSearchSessionId
		}
		props.setIsNdcLoading(true)
		wsRequest(sendMessage, WsMessage.Booking.FlightSearch, ndcParams, ndcRequestId)
		props.ndcRequestIdToParams.current[ndcRequestId] = ndcParams
	}

	const fetchAward = () => {
		const awardRequestId = uuidv4()
		const awardParams = {
			...props.itinerarySearchParams,
			searchType: FlightSearchTypes.Award
		}
		if (props.awardSearchSessionId) {
			awardParams.sessionId = props.awardSearchSessionId
		}
		props.setIsAwardLoading(true)
		wsRequest(sendMessage, WsMessage.Booking.FlightSearch, awardParams, awardRequestId)
		props.awardRequestIdToParams.current[awardRequestId] = awardParams
	}
	
	useEffect(() => {
		if (resendMessage) {
			// Resend with the same request_id. First one that returns is the one we want. 
			Object.entries(props.calendarRequestIdToParams.current).forEach(([requestId, params]) => {
				wsRequest(sendMessage, WsMessage.Booking.FlightSearch, params, requestId)
			})
			Object.entries(props.itineraryRequestIdToParams.current).forEach(([requestId, params]) => {
				wsRequest(sendMessage, WsMessage.Booking.FlightSearch, params, requestId)
			})
			Object.entries(props.awardRequestIdToParams.current).forEach(([requestId, params]) => {
				wsRequest(sendMessage, WsMessage.Booking.FlightSearch, params, requestId)
			})
			Object.entries(props.ndcRequestIdToParams.current).forEach(([requestId, params]) => {
				wsRequest(sendMessage, WsMessage.Booking.FlightSearch, params, requestId)
			})
			setResendMessage(false)
		}

	}, [resendMessage]) // eslint-disable-line

	useEffect(() => {
		// Makes requests for calendar search
		if (!!Object.keys(props.calendarSearchParams).length) {
			const requestId = uuidv4()
			const params = {
				...props.calendarSearchParams,
				searchType: FlightSearchTypes.Calendar
			}
			props.setIsCalendarLoading(true)
			wsRequest(sendMessage, WsMessage.Booking.FlightSearch, params, requestId)
			props.setFetchedCalendarWeeks((existing: any[]) => [...existing, ...params.weeksToFetch])
			props.calendarRequestIdToParams.current[requestId] = params
		}
	}, [props.calendarSearchParams]) // eslint-disable-line


	const searchSourceValidator = (callback: () => void, foundSearchSource: string, expectedSearchSource: string) => {
		return (!foundSearchSource || foundSearchSource === expectedSearchSource) && callback()
	}

	useEffect(() => {
		// Makes requests for itinerary search
		const outboundSearchSource = props.itinerarySearchParams?.outboundItinerary?.searchSource
		if (!!Object.keys(props.itinerarySearchParams).length) {
			if (props.itinerarySearchParams?.ticketType?.value !== TicketType.Award) {
				// Itinerary requests
				searchSourceValidator(fetchAmadeus, outboundSearchSource, SearchSource.Amadeus)
				searchSourceValidator(fetchNdc, outboundSearchSource, SearchSource.Airgateway)
			}
			if (props.itinerarySearchParams?.ticketType?.value !== TicketType.Regular) {
				// Award requests
				searchSourceValidator(fetchAward, outboundSearchSource, SearchSource.Award)
			}
		}
	}, [props.itinerarySearchParams]) // eslint-disable-line

	useEffect(() => {
		// Callback useEffect that is called whenever the server responds with a message over WS
		if (lastMessage !== null) {
			const extractedJob = parseResponse(lastMessage)
			if (extractedJob.jobType === WsMessage.Booking.FlightSearch) {
				if (extractedJob.requestId in props.calendarRequestIdToParams.current) {					
					// Calendar search response processing
					const currentWeeks = props.calendarRequestIdToParams.current[extractedJob.requestId]?.weeksToFetch
					delete props.calendarRequestIdToParams.current[extractedJob.requestId]
					if (!Object.keys(props.calendarRequestIdToParams.current).length) {
						props.setIsCalendarLoading(false)
					}
					if (extractedJob.status === WsJobStatus.Complete) {
						props.setCalendarResponse(extractedJob.data)
					} else {
						props.setErroredCalendarWeeks((existingWeeks: any[]) => [...existingWeeks, ...currentWeeks])
					}
				}
				else if (extractedJob.requestId in props.itineraryRequestIdToParams.current) {
					// Itinerary search response processing
					delete props.itineraryRequestIdToParams.current[extractedJob.requestId]
					if (!Object.keys(props.itineraryRequestIdToParams.current).length) {
						props.setIsItineraryLoading(false)
					}
					if (extractedJob.status === WsJobStatus.Complete) {
						setItineraryData(extractedJob.data?.itineraryOptions)
						setSearchData(extractedJob.data?.searchData)
						props.setItinerarySearchSessionId(extractedJob.data?.sessionId)
					} else {
						props.setIsItineraryError(true)
					}
				} 
				else if (extractedJob.requestId in props.ndcRequestIdToParams.current) {
					// NDC search response processing
					delete props.ndcRequestIdToParams.current[extractedJob.requestId]
					if (!Object.keys(props.ndcRequestIdToParams.current).length) {
						props.setIsNdcLoading(false)
					}
					if (extractedJob.status === WsJobStatus.Complete) {
						setItineraryData(extractedJob.data?.itineraryOptions)
						setSearchData(extractedJob.data?.searchData)
						props.setNdcSearchSessionId(extractedJob.data?.sessionId)
						const ndcResults = extractedJob.data?.itineraryOptions
						if (ndcResults.length && !props.isCalendarLoading) {
							setLowestPrice(ndcResults, "priceTotal", false)
						}
					} else {
						props.setIsNdcError(true)
					}
				}
				else if (extractedJob.requestId in props.awardRequestIdToParams.current) {
					// Award search response processing
					delete props.awardRequestIdToParams.current[extractedJob.requestId]
					if (!Object.keys(props.awardRequestIdToParams.current).length) {
						props.setIsAwardLoading(false)
					}
					if (extractedJob.status === WsJobStatus.Complete) {
						const awardResults = extractedJob.data?.itineraryOptions
						setItineraryData(awardResults)
						setSearchData(extractedJob.data?.searchData)
						props.setAwardSearchSessionId(extractedJob.data?.sessionId)
						if (awardResults.length) {
							setLowestPrice(awardResults, "priceBase", true)
						}
					} else {
						props.setIsAwardError(true)
					}
				}
			}	
		}
	}, [lastMessage]); // eslint-disable-line

	const setSearchData = (incomingSearchData: any) => {
		if (!incomingSearchData || !Object.keys(incomingSearchData).length) {
			return
		}
		const searchDataCopy = JSON.parse(JSON.stringify(props.itinerarySearchData))
		if (ItinerarySearchDataKeys.Airports in incomingSearchData) {
			searchDataCopy[ItinerarySearchDataKeys.Airports] = {
				...searchDataCopy[ItinerarySearchDataKeys.Airports],
				...incomingSearchData[ItinerarySearchDataKeys.Airports]
			}
		}
		if (ItinerarySearchDataKeys.Carriers in incomingSearchData) {
			searchDataCopy[ItinerarySearchDataKeys.Carriers] = {
				...searchDataCopy[ItinerarySearchDataKeys.Carriers],
				...incomingSearchData[ItinerarySearchDataKeys.Carriers]
			}
		}
		if (ItinerarySearchDataKeys.Cities in incomingSearchData) {
			searchDataCopy[ItinerarySearchDataKeys.Cities] = {
				...searchDataCopy[ItinerarySearchDataKeys.Cities],
				...incomingSearchData[ItinerarySearchDataKeys.Cities]
			}
		}
		props.setItinerarySearchData(searchDataCopy)
	}

	const setItineraryData = (incomingItineraries: any[]) => {
		const itineraryCopy = JSON.parse(JSON.stringify(props.itinerarySearchResponse)).concat(incomingItineraries).sort((a: any, b: any) => (a?.priceTotal < b?.priceTotal ? -1 : 1))
		props.setItinerarySearchResponse(itineraryCopy)
	}

	useEffect(() => {
		// from https://github.com/robtaussig/react-use-websocket/issues/133 as we are not on react18 + react-use-websocket 4.0
		if (readyState === ReadyState.OPEN) {
			heartbeatIntervalRef.current = window.setInterval(() => {
				if (socketUrl) {
					const lastHeartbeat = previousHeartbeats[socketUrl];
					const deltaFromNow = (Date.now() - lastHeartbeat) / 1000;
					// Send a heartbeat message if it hasn't already been sent within the last 10 seconds.
					if (!lastHeartbeat || deltaFromNow > 10) {
						// Send the heartbeat message and update the heartbeat history.
						wsRequest(sendMessage, WsMessage.Common.Heartbeat, {});
						previousHeartbeats[socketUrl] = Date.now();
					}
				}
			}, 5000); // check every 5 seconds
			return () => {
				if (heartbeatIntervalRef.current) {
					clearInterval(heartbeatIntervalRef.current);
				}
			};
		}
	}, [socketUrl, readyState, sendMessage]) // eslint-disable-line

	return  <></>
}

export default FlightSearchWs
