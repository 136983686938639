import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { geocodeByPlaceId } from "react-google-places-autocomplete";

import { AnimatePresence, motion } from "framer-motion";
import { styled } from "styled-components"

import { useIsFirstRender } from "hooks/useIsFirstRender";

import { parseGoogleAddress } from "components/common/util";

import { SignupStep } from "components/signup/constants";
import { StringError } from "components/signup/errors";
import messages from "components/signup/messages";
import { updateApplication, updateLead, updateStep } from "components/signup/signupSlice";
import { CompanyStructure, StepDirection } from "components/signup/types";
import { validateAddress, validateFormationDate, validatePercentOwnership } from "components/signup/validators";

import AddressInput from "components/signup/components/AddressInput";
import ArrowButton, { ArrowDirection } from "components/signup/components/ArrowButton";
import Button from "components/signup/components/Button";
import Checkbox from "components/signup/components/Checkbox";
import Input from "components/signup/components/Input";
import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";

import styles from "styles/styles";

const CaptureBizInfo = () => {
    const dispatch = useDispatch()
    
    const dateRef = useRef(null)
    const isFirstRender = useIsFirstRender()

    const application = useSelector((state: any) => state.signup.application)
    const leadUuid = useSelector((state: any) => state.signup.leadUuid)
    const stepDirection = useSelector((state: any) => state.signup.direction)

    const [showErrors, setShowErrors] = useState(false)
    const [isSameAddress, setIsSameAddress] = useState(
        application.businessAddressStreet === application.addressStreet &&
        application.businessAddressCity === application.addressCity &&
        application.businessAddressState === application.addressState &&
        application.businessAddressZip === application.addressZip
    )
    const [address1, setAddress1] = useState<any>(application.businessAddressStreet ? {
        label: `${application.businessAddressStreet}, ${application.businessAddressCity}, ${application.businessAddressState}`,
        value: undefined,
    } : undefined)
    const [address2, setAddress2] = useState(application.businessAddressLineTwo || '')
    const [date, setDate] = useState(application.formationDate ? moment(application.formationDate).format('YYYY-MM-DD') : '')
    const [percent, setPercent] = useState(Number(application.businessPercentOwnership) ? Number(application.businessPercentOwnership).toLocaleString() : '')

    const isSoleProp = application.companyStructure === CompanyStructure.SoleProp
    const todaysDate = moment().format('YYYY-MM-DD')

    const dateError: any =  validateFormationDate(date)
    const addressError: any = !isSameAddress ? validateAddress(address1) : undefined
    const address2Error: any = address2.length > 40 ? StringError.TooLong : undefined
    const percentError: any = !isSoleProp ? validatePercentOwnership(percent) : undefined
    const isInputInvalid = [dateError, addressError, address2Error, percentError].some(error => error !== undefined)

    const handleBack = (ev: any) => { 
        ev.stopPropagation();
        dispatch(updateStep({ step: SignupStep.BizNAICS, direction: StepDirection.Left }))
    }

    const handleSubmit = async (ev: React.FormEvent) => {
        ev.preventDefault();

        if (isInputInvalid) {
            setShowErrors(true)
        } else {
            const parsedBizAddress = address1?.value?.place_id && await geocodeByPlaceId(address1?.value?.place_id)
            .then((gAddress) => parseGoogleAddress(gAddress))
            .catch(() => undefined)

            const applicationChanges = {
                businessAddressStreet: isSameAddress ? application.addressStreet : parsedBizAddress?.street || application.businessAddressStreet,
                businessAddressCity: isSameAddress ? application.addressCity : parsedBizAddress?.city || application.businessAddressCity,
                businessAddressState: isSameAddress ? application.addressState : parsedBizAddress?.state || application.businessAddressState,
                businessAddressZip: isSameAddress ? application.addressZip : parsedBizAddress?.zip || application.businessAddressZip,
                businessAddressLineTwo: isSameAddress ? application.addressLineTwo : address2,
                formationDate: date !== '' ? date : undefined,
                businessPercentOwnership: isSoleProp ? 100 : Number(percent)
            }

            dispatch(updateApplication(applicationChanges))
            dispatch(updateLead({ leadUuid, application: applicationChanges }))
            dispatch(updateStep({ step: SignupStep.FinalReview, direction: StepDirection.Right }))
        }
    }

    const toggleSameAddress = (ev?: React.MouseEvent<Element>) => {
        ev?.preventDefault()
        setIsSameAddress(!isSameAddress)
    }

    const handleAddress1Change = (option: any) => {
        setAddress1(option)
    }

    const handleAddress2Change = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setAddress2(ev.target.value)
    }

    const handleDateChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setDate(ev.target.value)
    }

    const handlePercentChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const asNumber = Number(ev.target.value)
        if (isNaN(asNumber) || asNumber > 100 || asNumber < 0 || ev.target.value.length > 5) return;

        setPercent(ev.target.value)
    }

    const addressMotionProps = {
        initial: isFirstRender ? { opacity: 1, height: 'auto', marginBottom: '8px' } : { opacity: 0, height: 0, marginBottom: 0 },
        exit: { opacity: 0, height: 0, marginBottom: 0 },
        animate: { opacity: 1, height: 'auto', marginBottom: '8px' }
    }

    // Autofocus the date input if isSameAddress
    useEffect(() => {
        if (isSameAddress) {
            (dateRef.current as any)?.focus()
        }
    }, []) // eslint-disable-line

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='BusinessDetails'
    >
        <form className="d-flex flex-column align-items-center" onSubmit={handleSubmit}>
            <Title>We just need the last few details about {application.companyName || 'your business'}.</Title>
            <Content>
                <CheckboxContainer htmlFor='check'>
                    <Checkbox
                        id='check'
                        checked={isSameAddress}
                        onClick={toggleSameAddress}
                    />
                    <CheckLabel>My personal/home address is the same as my business address.</CheckLabel>
                </CheckboxContainer>
                <AnimatePresence mode='wait'>
                    {!isSameAddress && <motion.div {...addressMotionProps}>
                        <InputMargin>
                            <AddressInput 
                                errorMessage={showErrors && messages.Errors.Address[addressError]} 
                                autoFocus 
                                onChange={handleAddress1Change} 
                                value={address1} 
                                placeholder='Business Address'
                            />
                        </InputMargin>
                        <Input errorMessage={showErrors && messages.Errors.Address2[address2]} onChange={handleAddress2Change} value={address2} label="Business Address Line 2"/>
                    </motion.div>}
                </AnimatePresence>
                <InputMargin>
                    <Input errorMessage={showErrors && messages.Errors.FormationDate[dateError]} parentRef={dateRef} type='date' max={todaysDate} onChange={handleDateChange} value={date} label="Date of Formation"/>
                </InputMargin>
                {application.companyStructure !== CompanyStructure.SoleProp && <Input errorMessage={showErrors && messages.Errors.PercentOwnership[percentError]} onChange={handlePercentChange} value={percent} label="% of Your Ownership"/>}
            </Content>
            <ButtonContainer>
                <BackButtonContainer>
                    <ArrowButton onClick={handleBack} direction={ArrowDirection.Left}/>
                </BackButtonContainer>
                <Button type="submit">Next</Button>
            </ButtonContainer>
        </form>
    </StepContainer>
}

const CheckboxContainer = styled.label`
    margin-bottom: 32px;
    display: flex;
    align-items: start;
    flex: 1;
    border-radius: 4px;
    border: 1px solid ${styles.Color.TaekusGrey5};
    background: ${styles.Color.White};
    padding: 16px;
    color: ${styles.Color.TaekusGrey3};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    cursor: pointer;
    user-select: none;
    &:hover {
        box-shadow: 0px 0px 16px 0px rgba(124, 61, 118, 0.15);
        border: 1px solid transparent;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
    ${styles.Animation.transitionStyles}
`

const InputMargin = styled.div`
    margin-bottom: 8px;
`

const BackButtonContainer = styled.div`
    margin-right: 16px;
`

const ButtonContainer = styled.div`
    margin-top: 32px;
    display: flex;
`

const Content = styled.div`
    width: 308px;
`

const CheckLabel = styled.div`
    min-width: 0;
    color: ${styles.Color.TaekusGrey3};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    text-wrap: wrap;
    white-space: wrap;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%;
    letter-spacing: 0.24px;
    margin: 0;
    display: flex;
    flex: 1 1 auto;
    overflow: hidden:
    pointer-events: none;
    margin-left: 16px;
    cursor: pointer;
`

const Title = styled.div`
    color: ${styles.Color.TaekusBlack};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 39.68px */
    letter-spacing: 0.32px;
    margin-bottom: 16px;
    ${styles.MediaQueries.Mobile} {
        font-size: 24px;
    }
`

export default CaptureBizInfo