import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { AnimatePresence, motion } from "framer-motion";
import { styled } from "styled-components"

import { Actions as UserActions } from 'redux/currentUser'
import Session from "services/session";

import { updateStep } from "components/signup/signupSlice";

import { SignupStep } from "components/signup/constants";
import { StepDirection } from "components/signup/types";

import Spinner from "components/common/Spinner";
import CTAButton from "components/signup/components/CTAButton";

import InviteCode from "components/signup/steps/InviteCode";
import ChooseProduct from "components/signup/steps/ChooseProduct";
import FindInfo from "components/signup/steps/FindInfo";
import VerifyPhone from "components/signup/steps/VerifyPhone";
import LoadingInfo from "components/signup/steps/LoadingInfo";
import ReviewInfo from "components/signup/steps/ReviewInfo";
import Email from "components/signup/steps/Email";
import Income from "components/signup/steps/Income";
import Housing from "components/signup/steps/Housing";
import LoadingOffers from "components/signup/steps/LoadingOffers";
import SignIn from "components/signup/steps/SignIn";
import BusinessStructure from "components/signup/steps/BusinessStructure";
import BusinessName from "components/signup/steps/BusinessName";
import BusinessDBA from "components/signup/steps/BusinessDBA";
import BusinessEIN from "components/signup/steps/BusinessEIN";
import BusinessIndustry from "components/signup/steps/BusinessIndustry";
import BusinessDetails from "components/signup/steps/BusinessDetails";
import EditInfo from "components/signup/steps/EditInfo";
import UserDetails from "components/signup/steps/UserDetails";
import VPN from "components/signup/steps/VPN";
import Denied from "components/signup/steps/Denied";
import Disclosure from "components/signup/steps/Disclosure";
import Offer from "components/signup/steps/Offer";
import Username from "components/signup/steps/Username";
import Password from "components/signup/steps/Password";
import VerifyEmail from "components/signup/steps/VerifyEmail";
import Upsell from "components/signup/steps/Upsell";
import EditBusinessInfo from "components/signup/steps/EditBusinessInfo";
import KYCUpload from "components/signup/steps/KYCUpload";

import { fadeInOutMotionProps } from "styles/motionConstants";
import styles from "styles/styles";

import { ReactComponent as TaekusLogoSVG } from "assets/svg/TaekusLogo.svg";
import { ReactComponent as CaretRight } from "assets/svg/ThinCaretRight.svg";
import { ReactComponent as CaretDown } from "assets/svg/CaretDown.svg";
import { ReactComponent as TaekusIcon } from "assets/svg/TaekusIcon.svg";


const Signup = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const user = useSelector((state: any) => state.currentUser)
    const application = useSelector((state: any) => state.signup.application)
    const products = useSelector((state: any) => state.signup.products)
    const isMobile = useSelector((state: any) => state.global.isMobile)
    const step = useSelector((state: any) => state.signup.step)

    const isHeaderLight = [SignupStep.ChooseProduct, SignupStep.Upsell, SignupStep.ChooseProduct].includes(step)
    const showUserInfo = step !== undefined && [SignupStep.InviteCode, SignupStep.ChooseProduct, SignupStep.FindInfo, SignupStep.VerifyPhone, SignupStep.UserDetected, SignupStep.LoadingProve].includes(step)
    
    const redirectHome = () => {
        history.push('/')
    }

    const getHeaderContent = () => {
        // Loading content
        if (user.isFetching) {
            return <motion.div key='spinner' {...fadeInOutMotionProps}>
                <Spinner size={16}/>
            </motion.div>
        }

        // Show product name if not showing user info
        if (!showUserInfo) { 
            return <motion.div key='product' {...fadeInOutMotionProps}>
                <MemberText>{products?.find((product: any) => product.productCode === application.productCode).displayName}</MemberText>
            </motion.div>
        }

        // Show logged-in user name
        if (user.userLoaded) {
            return <motion.div {...fadeInOutMotionProps} key='user' className="d-flex align-items-center">
                <div style={{color: isHeaderLight ? 'white !important' : undefined}}>
                    <CTAButton onClick={() => { Session.logout(); dispatch(UserActions.clearCurrentUser()) }}>
                        {user.currentUser.firstName} {user.currentUser.lastName} <CaretDown style={{marginLeft: '8px'}} width={8} height={5}/>
                    </CTAButton>
                </div>
            </motion.div>
        }

        // default - show 
        return <motion.div {...fadeInOutMotionProps} key='login' className="d-flex align-items-center">
            {!isMobile && <MemberText>Already a Member?</MemberText>}
            <div style={{marginLeft: '8px' }}>
                <CTAButton 
                    color={isHeaderLight ? styles.Color.White : undefined}
                    onClick={() => { dispatch(updateStep({ step: SignupStep.SignIn, direction: StepDirection.Right })) }}
                >
                    Log In <CaretRight stroke={isHeaderLight ? styles.Color.White : undefined} width={16} height={16}/>
                </CTAButton>
            </div>
        </motion.div>     
    }

    const getStep = () => {
        switch (step) {
            case SignupStep.KYCUpload:
                return <KYCUpload/>
            case SignupStep.VerifyEmail:
                return <VerifyEmail key='verifyEmail'/>
            case SignupStep.CreatePassword:
                return <Password key='password'/>
            case SignupStep.CreateUsername:
                return <Username key='username'/>
            case SignupStep.Offer:
                return <Offer key='offer'/>
            case SignupStep.Upsell:
                return <Upsell key='upsell'/>
            case SignupStep.ReviewDisclosure:
                return <Disclosure key='disclosure'/>
            case SignupStep.Denied:
                return <Denied key='denied'/>
            case SignupStep.VPNDetected:
                return <VPN key='vpn'/>
            case SignupStep.UserDetails:
                return <UserDetails key='userDetails'/>
            case SignupStep.SignIn:
            case SignupStep.UserDetected:
                return <SignIn key='signIn' />
            case SignupStep.EditInfo:
                return <EditInfo key='editInfo' />
            case SignupStep.EditBusinessInfo:
                return <EditBusinessInfo key='editBusinessInfo' />
            case SignupStep.LoadingOffer:
                return <LoadingOffers key='loadingOffer'/>
            case SignupStep.BizDetails:
                return <BusinessDetails key='bizDetails'/>
            case SignupStep.BizNAICS:
                return <BusinessIndustry key='bizIndustry'/>
            case SignupStep.BizEIN:
                return <BusinessEIN key='bizEIN'/>
            case SignupStep.BizDBA:
                return <BusinessDBA key='bizDBA'/>
            case SignupStep.BizName:
                return <BusinessName key='bizName'/>
            case SignupStep.BizType:
                return <BusinessStructure key='bizStructure'/>
            case SignupStep.Housing:
                return <Housing key='housing'/>
            case SignupStep.Income:
                return <Income key='income'/>
            case SignupStep.Email:
                return <Email key='email'/>
            case SignupStep.InitialReview:
            case SignupStep.FinalReview:
                return <ReviewInfo key='reviewInfo'/>
            case SignupStep.LoadingProve:
                return <LoadingInfo key='loadingInfo'/>
            case SignupStep.VerifyPhone:
                return <VerifyPhone key='verifyPhone'/>
            case SignupStep.FindInfo:
                return <FindInfo key='findInfo'/>
            case SignupStep.ChooseProduct:
                return <ChooseProduct key='choose'/>
            case SignupStep.InviteCode:
            default:
                return <InviteCode key='invite'/>
        }
    }

    useEffect(() => {
        // Fetch user on mount if accessToken is present
        const accessToken = localStorage.getItem('accessToken')
        
        if (accessToken) {
            dispatch(UserActions.fetchCurrentUser())
        }
    }, [dispatch])

    const isDebugMode = (window as any).env.REACT_APP_DEBUG === 'true'
    return <Container>
        <Scrollable>
            <Header>
                <ProgressBar>
                    <motion.div initial={{width: 0}} animate={{ width: `${((step || 0)/20) * 100}%` }}  style={{height: '2px', backgroundColor: styles.Color.TaekusPurple, maxWidth: '100%'}} />
                </ProgressBar>
                <HeaderRow>
                    {isMobile ? <div style={{display: 'flex'}}>
                        <TaekusIcon onClick={redirectHome}  style={{pointer: 'cursor'} as any}/>
                        {isDebugMode && <StepSelect value={step} onChange={(ev) => { dispatch(updateStep({ step: Number(ev.target.value) })) }}>
                            {Object.values(SignupStep).filter(step => !isNaN(Number(step))).map(step => <option key={step} value={step}>{SignupStep[step as number]}</option>)}
                        </StepSelect>}
                    </div> : <div style={{display: 'flex'}}>
                        <StyledTaekusLogo onClick={redirectHome} fill={isHeaderLight ? styles.Color.White : styles.Color.Black}/>
                        {isDebugMode && <StepSelect value={step} onChange={(ev) => { dispatch(updateStep({ step: Number(ev.target.value) })) }}>
                            {Object.values(SignupStep).filter(step => !isNaN(Number(step))).map(step => <option key={step} value={step}>{SignupStep[step as number]}</option>)}
                        </StepSelect>}
                    </div>}
                    <AnimatePresence mode='wait'>
                        {getHeaderContent()}
                    </AnimatePresence>
                </HeaderRow>
            </Header>
            <Content>
                <AnimatePresence mode='wait'>
                    {getStep()}
                </AnimatePresence>
            </Content>
        </Scrollable>
    </Container>
}

const StepSelect = styled.select`
    opacity: 0;
    &:hover {
        opacity: 1;
    }
    ${styles.Animation.transitionStyles}
`

const StyledTaekusLogo = styled(TaekusLogoSVG)`
    pointer: cursor;
    width: 130px;
    height: 24px;
    ${styles.Animation.transitionStyles}
`

const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 768px) {
        width: 100dvw;
        height: 100dvh;
    }
    ${styles.MediaQueries.Mobile} {
        align-items: start;
        min-height: min-content;
    }
`

const MemberText = styled.span`
    color: ${styles.Color.TaekusGrey2};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 19.84px */
    letter-spacing: 0.32px;
`

const ProgressBar = styled.div<any>`
    background-color: ${styles.Color.TaekusGrey4};
`

const HeaderRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 80px;
    padding-right: 60px;
    height: 100%;
    width: 100%;
    ${styles.MediaQueries.Mobile} {
        padding: 0 16px;
    }
`

const Header = styled(motion.div)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
`

const Container = styled.div`
    position: relative;
    background: #F9F8F5;
    min-height: 100dvh;
    height: 100dvh;
    min-width: 100dvw;
    width: 100dvw;
    overflow: hidden;
    ${styles.MediaQueries.Mobile} {
        overflow-y: auto;
        overflow-x: hidden;
    }
    ${styles.Scrollbar.defaultScrollbarStyles}
`

const Scrollable = styled.div`
    height: min-content;
`

export default Signup