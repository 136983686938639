import { CompanyStructure, HousingType } from "components/signup/types"
import { 
    AddressError,
    BusinessIndustryError,
    BusinessStructureError,
    EINError,
    EmailError,
    FormationDateError,
    HousingError,
    IncomeError,
    PercentOwnershipError,
    SSNError,
    StringError,
    TermsError,
    UsernameError
} from "components/signup/errors"

const messages = {
    BizTypes: {
        [CompanyStructure.SoleProp]: 'Sole Prop',
        [CompanyStructure.LLC]: 'LLC',
        [CompanyStructure.cCorp]: 'C Corp',
        [CompanyStructure.sCorp]: 'S Corp',
    },
    Housing: {
        [HousingType.Own]: 'Own',
        [HousingType.Rent]: 'Rent',
    },
    Errors: {
        FirstName: {
            [StringError.TooShort]: 'First name must be at least 2 characters.',
            [StringError.TooLong]: 'First name must be 40 characters or less.',
        } as any,
        LastName: {
            [StringError.TooShort]: 'Last name must be at least 2 characters.',
            [StringError.TooLong]: 'Last name must be 40 characters or less.',
        } as any,
        SSN: {
            [SSNError.InvalidLength]: 'SSN must be exactly 9 digits.',
        } as any,
        Address: {
            [AddressError.Required]: 'This field is required.',
        } as any,
        Address2: {
            [AddressError.Required]: 'You must enter a valid address.',
        } as any,
        Email: {
            [EmailError.Required]: 'This field is required.',
            [EmailError.Format]: 'Email address must be correctly formatted.',
            [EmailError.AlreadyExists]: 'This email is already in use.',
        } as any,
        Income: {
            [IncomeError.Required]: 'This field is required.',
        } as any,
        Housing: {
            [HousingError.Required]: 'This field is required.',
            [HousingError.PaymentTooLow]: 'You must enter a monthly payment amount.',
            [HousingError.PaymentTooHigh]: 'Payment amount cannot be above $1,000,000.',
        } as any,
        BusinessStructure: {
            [BusinessStructureError.Required]: 'This field is required.',
        } as any,
        CompanyName: {
            [StringError.TooShort]: "You must enter your business's name.",
        } as any,
        EIN: {
            [EINError.Required]: "You must enter your business's EIN.",
            [EINError.Format]: "Your EIN must include 9 digits. ( e.g 12-3456789 )",
        } as any,
        BusinessIndustry: {
            [BusinessIndustryError.Required]: "You must select an option.",
        } as any,
        DBA: {
            [StringError.TooShort]: "Your DBA must be at least 2 characters, or blank.",
            [StringError.TooLong]: "Your DBA must be 40 charaters or less in length.",
        } as any,
        FormationDate: {
            [FormationDateError.Required]: "This field is required.",
            [FormationDateError.FutureDate]: "You cannot use a future date.",
            [FormationDateError.DatePastMax]: "You cannot use a date more than 200 years in the past."
        } as any,
        PercentOwnership: {
            [PercentOwnershipError.Required]: "You must include how much of the business you own.",
            [PercentOwnershipError.TooSmall]: "The minimum required ownership is 51%.",
            [PercentOwnershipError.TooLarge]: "Percent ownership cannot go above 100%.",
        } as any,
        Terms: {
            [TermsError.Required]: "You must accept the terms to continue",
        } as any,
        Username: {
            [UsernameError.TooSmall]: 'Your username must be at least 2 characters in length.',
            [UsernameError.TooLarge]: 'Your username must be 40 characters or less in length.',
            [UsernameError.Unique]: 'This username is unavailable.',
        } as any,
    }
}

export default messages