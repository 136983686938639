import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import moment from 'moment'

import { motion } from "framer-motion"
import styled from 'styled-components'

import { Locale, USDCurrencyOptions } from 'utils/constants'

import { Actions as RewardsActions } from 'redux/features/banking/rewardsTransactions'
import { updateToast } from 'redux/features/global/globalSlice'

import Button, { ButtonType } from 'components/common/Button'
import DatePicker from 'components/common/DatePicker/DatePicker'
import { FlightLocationDropdownStyleTypes } from 'components/common/FlightLocationDropdown/constants'
import FlightLocationDropdown from 'components/common/FlightLocationDropdown/FlightLocationDropdown'
import Footer from 'components/common/Footer'
import Modal from 'components/common/Modal'
import Navigation, { NavColor } from 'components/navbar/Navigation'
import Spinner from 'components/common/Spinner'
import StatusDisplay from 'components/common/StatusDisplay'

import { 
    SearchOptions,
    enumeratedSearchOptions,
    TripType,
    messages,
    defaultSearchOptions,
} from 'components/pages/Rewards/constants'
import { getSearchOptionsFromLocalStorage, isRewardsSearchOptionsValid } from 'components/pages/Rewards/utils'

import styles from 'styles/styles'
import { fadeInOutMotionProps } from 'styles/motionConstants'

import { ReactComponent as Reverse } from "assets/svg/Reverse.svg";

const Rewards = () => {
    const history = useHistory()

    // Redux state
    const dispatch = useDispatch()
    const currentUser = useSelector((state: any) => state.currentUser.currentUser)
    const isUserLoaded = useSelector((state: any) => state.currentUser.userLoaded)
    const banking = useSelector((state: any) => state.banking)
    const statementCreditRequest = useSelector((state: any) => state.rewardsTransactions.statementCreditRequest)
    const isMobile = useSelector((state: any) => state.global.isMobile)

    // We should merge this functionalilty into getSearchOptionsFromLocalStorage
    const mergeInitialSearchOptions = () => {
        // Reset search params taking only some existing params
        const originalOptions = getSearchOptionsFromLocalStorage()
        if (!!originalOptions){
            localStorage.setItem('searchOptions', JSON.stringify({
                ...defaultSearchOptions,
                arrivalAirport: originalOptions.arrivalAirport || defaultSearchOptions.arrivalAirport,
                departureAirport: originalOptions.departureAirport || defaultSearchOptions.departureAirport,
                departureDate: originalOptions.departureDate || defaultSearchOptions.departureDate,
                returnDate: originalOptions.returnDate || defaultSearchOptions.returnDate,
                numberOfPassengers: originalOptions.numberOfPassengers || defaultSearchOptions.numberOfPassengers,
                travelClass: originalOptions.travelClass || defaultSearchOptions.travelClass,
                tripType: originalOptions.tripType || defaultSearchOptions.tripType,
            }))
            return originalOptions
        } else {
            return defaultSearchOptions
        }
    }

    // Component state
    const [searchOptions, setSearchOptions] = useState<SearchOptions>(mergeInitialSearchOptions())
    const [pointsToRedeem, setPointsToRedeem] = useState("0")
    const [isCashRedemptionModalOpen, setIsCashRedemptionModalOpen] = useState(false)
    const [redemptionError, setRedemptionError] = useState<string | undefined>(undefined)
    const [isBackgroundLoaded, setIsBackgroundLoaded] = useState(false)
    const [isForegroundLoaded, setIsForegroundLoaded] = useState(false)

    const searchOptionsIsValid = isRewardsSearchOptionsValid(searchOptions)
    const containerRef = useRef(null);
    const parallaxBackgroundRef = useRef(null)
    const parallaxForegroundRef = useRef(null)
    const areImagesLoaded = isBackgroundLoaded && isForegroundLoaded


    const swapAirports = () => {
        const departureCopy = { ...searchOptions.departureAirport };
        const arrivalCopy = { ...searchOptions.arrivalAirport };
        setSearchOptions({ 
            ...searchOptions,
            departureAirport: arrivalCopy,
            arrivalAirport: departureCopy
        })
        localStorage.setItem('searchOptions', JSON.stringify({ ...searchOptions }))
    }

    const goToFlightSearch = () => {
        history.push('/rewards/flights')
    }

    const updateSearchOption = (ev: React.ChangeEvent<HTMLSelectElement>) => {
        if (ev.target.id === 'tripType') {
            localStorage.setItem('searchOptions', JSON.stringify({ ...searchOptions, tripType:  ev.target.value, returnDate: undefined }))
            setSearchOptions({ ...searchOptions, tripType: ev.target.value as TripType, returnDate: undefined })

            // If switching from one-way to round trip, prompt for return date
            if (ev.target.value === TripType.RoundTrip) {
                setTimeout(() => { document.getElementById('datePicker')?.click() }, 500) ;
            }
        } else {
            localStorage.setItem('searchOptions', JSON.stringify({ ...searchOptions, [ev.target.id]: ev.target.value }))
            setSearchOptions({ ...searchOptions, [ev.target.id]: ev.target.value })
        }
    }

    const setDepartureAirport = (airportOption: any) => {
        localStorage.setItem('searchOptions', JSON.stringify({ ...searchOptions, departureAirport: airportOption }))
        setSearchOptions({ ...searchOptions, departureAirport: airportOption })
    }

    const setArrivalAirport = (airportOption: any) => { 
        localStorage.setItem('searchOptions', JSON.stringify({ ...searchOptions, arrivalAirport: airportOption }))
        setSearchOptions({ ...searchOptions, arrivalAirport: airportOption })
    }

    const setDepartureDate = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const date = moment(ev.target.value)

        localStorage.setItem('searchOptions', JSON.stringify({ ...searchOptions, departureDate: date }))
        setSearchOptions({ ...searchOptions, departureDate: date });
    }

    const setReturnDate = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const date = moment(ev.target.value)

        localStorage.setItem('searchOptions', JSON.stringify({ ...searchOptions, returnDate: date }))
        setSearchOptions({ ...searchOptions, returnDate: date });
    }

    const handleDateChange = (date: any) => {
        if (searchOptions.tripType === TripType.RoundTrip) {
            const departureDate = date[0] ? moment(date[0]) : undefined;
            const returnDate = date[1] ? moment(date[1]) : undefined

            localStorage.setItem('searchOptions', JSON.stringify({ ...searchOptions, departureDate, returnDate }))
            setSearchOptions({
                ...searchOptions,
                departureDate,
                returnDate
            })
        } else {
            localStorage.setItem('searchOptions', JSON.stringify({ ...searchOptions, departureDate: date }))
            setSearchOptions({
                ...searchOptions,
                departureDate: moment(date),
            })
        }
    }

    const updatePointsToRedeem = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.value === '' || (!isNaN(Number(ev.target.value.replaceAll(',', ''))) && Number(ev.target.value.replaceAll(',', '')) > 0 && Number(ev.target.value.replaceAll(',', '')) <= currentUser.numPointsPending)) {
        if (ev.target.value === '' || /^-?\d+$/.test(ev.target.value))
            setPointsToRedeem(ev.target.value)
        }
    }

    const unformatPointsToRedeem = () => {
        setPointsToRedeem(pointsToRedeem.replaceAll(',', ''))
    }

    const formatPointsToRedeem = () => {
        setPointsToRedeem(Number(pointsToRedeem.replaceAll(',', '')).toLocaleString(Locale.English))
    }

    const redeemPoints = () => {
        const params = {
            amount: Number(pointsToRedeem.replaceAll(',', '')),
            cardAccountUuid: banking.account.uuid,
        }
        dispatch(RewardsActions.createStatementCreditRequest(params))
    }

    const convertPointsToDollars = (point: number) => {
        const conversion = currentUser.pointRedemptionRules?.statementCreditPointValue || 0.01
        return  point * conversion
    }

    const toggleCashRedemptionModal = () => {
        if (isCashRedemptionModalOpen === true || (pointsToRedeem !== '0' && pointsToRedeem !== 'NaN')) {
            setRedemptionError(undefined)
            setIsCashRedemptionModalOpen(!isCashRedemptionModalOpen)
        }
    }

    const updateScrolledValue = () => {
        (parallaxBackgroundRef.current as any).style.backgroundPositionY = `${Math.max((containerRef.current as any)?.scrollTop, 0) / 2}px`;
        (parallaxForegroundRef.current as any).style.backgroundPositionY = `${Math.max((containerRef.current as any)?.scrollTop, 0) / 4}px`;
    }

    const loadBackgroundImages = () => {
        const bgImage = new Image();
        const fgImage = new Image();

        bgImage.onload = () => {
            if (parallaxBackgroundRef.current) {
                (parallaxBackgroundRef?.current as any).style.backgroundImage = `url(${bgImage.src})`;
            }
            setIsBackgroundLoaded(true)
        }
        bgImage.onerror = () => {
            setIsBackgroundLoaded(true)
        }

        fgImage.onload = () => {
            if (parallaxForegroundRef.current) {
                (parallaxForegroundRef?.current as any).style.backgroundImage = `url(${fgImage.src})`;
            } 
            setIsForegroundLoaded(true)
        }
        fgImage.onerror = () => {
            setIsForegroundLoaded(true)
        }        

        bgImage.src = "https://taekus-static.s3.us-west-2.amazonaws.com/img/backgroundImages/rewards.jpg";
        fgImage.src = "https://taekus-static.s3.us-west-2.amazonaws.com/img/backgroundImages/rewardsForeground.png";
    }

    // On component mount
    useEffect(() => {
        (containerRef.current as any)?.addEventListener("scroll", updateScrolledValue);
        
        loadBackgroundImages()
    }, []) 

    useEffect(() => {
        if (statementCreditRequest.loading === false) {
            if (statementCreditRequest.error === true) {
                setRedemptionError('There was a problem submitting your credit request.')
            } else {
                if (pointsToRedeem !== '0') {
                    setIsCashRedemptionModalOpen(false)
                    dispatch(updateToast({ 
                        isOpen: true,
                        isError: false,
                        message: 'Successfully submitted your credit request.'
                    }))
                    setPointsToRedeem('0')
                }
            }
        }
    }, [ statementCreditRequest.loading, dispatch ]) // eslint-disable-line

    return <Container ref={containerRef}>
        <Main>
            <ParallaxImage isImageLoaded={areImagesLoaded} ref={parallaxBackgroundRef} />
            <ParallaxImage isImageLoaded={areImagesLoaded} ref={parallaxForegroundRef} />
            <MainOverlay>
                <Navigation color={NavColor.White}/>
                <Content>
                    <GreetingContainer>
                        {isUserLoaded && <motion.div initial={{opacity: 0, x: 100}} animate={{opacity: 1, x: 0}} transition={{duration: 0.7}}>
                            <Greeting>{`Hi ${currentUser.firstName},\n Plan Your Next Getaway`}</Greeting>
                        </motion.div>}
                    </GreetingContainer>
                    <SearchOptionsContainer>
                        <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 0.5, delay: 0.6}}>
                            {isMobile && <>
                                <MobileFlightLocationContainer>
                                    <FlightLocationDropdown
                                        value={searchOptions.departureAirport}
                                        name="from"
                                        id="departureAirport"
                                        placeholder="From"
                                        styleType={FlightLocationDropdownStyleTypes.MobileRewards}
                                        changeHandler={setDepartureAirport}
                                        blacklist={[searchOptions.arrivalAirport?.value]}
                                    />
                                    <ReverseContainer onClick={swapAirports}>
                                        <StyledReverseIcon/>
                                    </ReverseContainer>
                                    <FlightLocationDropdown
                                        value={searchOptions.arrivalAirport}
                                        name="to"
                                        id="arrivalAirport"
                                        placeholder="To"
                                        styleType={FlightLocationDropdownStyleTypes.MobileRewards}
                                        changeHandler={setArrivalAirport}
                                        blacklist={[searchOptions.departureAirport?.value]}
                                    />
                                </MobileFlightLocationContainer>
                                <MobileDateContainer>
                                    <MobileFlightDatePickerInput
                                        type='date'
                                        min={moment().startOf('day')?.format('YYYY-MM-DD')}
                                        max={moment().add(335, 'days').format('YYYY-MM-DD')}
                                        value={searchOptions.departureDate?.format('YYYY-MM-DD')}
                                        onChange={setDepartureDate}
                                    />
                                    {searchOptions.tripType === TripType.RoundTrip && <>
                                        <div>–</div>
                                        <MobileFlightDatePickerInput
                                            type='date'
                                            min={searchOptions.departureDate?.format('YYYY-MM-DD')}
                                            max={moment().add(335, 'days').format('YYYY-MM-DD')}
                                            value={searchOptions.returnDate?.format('YYYY-MM-DD')}
                                            onChange={setReturnDate}
                                        />
                                    </>}
                                </MobileDateContainer>
                            </>}
                            <TicketSettings>
                                <TicketSettingsDropdown onChange={updateSearchOption} id='tripType' value={searchOptions.tripType}>
                                    {enumeratedSearchOptions.tripType.map(tripTypeOption => <option key={tripTypeOption.label} value={tripTypeOption.value}>{tripTypeOption.label}</option>)}
                                </TicketSettingsDropdown>
                                <TicketSettingsDropdown onChange={updateSearchOption} id='numberOfPassengers' value={searchOptions.numberOfPassengers}>
                                    {enumeratedSearchOptions.numberOfPassengers.map(numberOfPassengersOption => <option key={numberOfPassengersOption.label} value={numberOfPassengersOption.value}>{numberOfPassengersOption.label}</option>)}
                                </TicketSettingsDropdown>
                                <TicketSettingsDropdown onChange={updateSearchOption} id='travelClass' value={searchOptions.travelClass}>
                                    {enumeratedSearchOptions.travelClass.map(travelClassOption => <option key={travelClassOption.label} value={travelClassOption.value}>{travelClassOption.label}</option>)}
                                </TicketSettingsDropdown>
                            </TicketSettings>
                            <Dates>
                                {!isMobile && <>
                                    <FlightLocationContainer>
                                        <FlightLocationDropdown
                                            value={searchOptions.departureAirport}
                                            name="from"
                                            id="departureAirport"
                                            placeholder="From"
                                            styleType={FlightLocationDropdownStyleTypes.Rewards}
                                            changeHandler={setDepartureAirport}
                                            blacklist={[searchOptions.arrivalAirport?.value]}
                                        />
                                        <ReverseContainer onClick={swapAirports}>
                                            <StyledReverseIcon/>
                                        </ReverseContainer>
                                        <FlightLocationDropdown
                                            value={searchOptions.arrivalAirport}
                                            name="to"
                                            id="arrivalAirport"
                                            placeholder="To"
                                            styleType={FlightLocationDropdownStyleTypes.Rewards}
                                            changeHandler={setArrivalAirport}
                                            blacklist={[searchOptions.departureAirport?.value]}
                                        />
                                    </FlightLocationContainer>
                                    <DateContainer>
                                        <DatePicker 
                                            id='datePicker'
                                            selected={searchOptions.departureDate}
                                            startDate={searchOptions.departureDate}
                                            endDate={searchOptions.tripType === TripType.RoundTrip ? searchOptions.returnDate : undefined}
                                            onChange={handleDateChange}
                                            customInput={<FlightDateInput>{`${searchOptions.departureDate?.format('ddd, MMMM D')} ${searchOptions.tripType === TripType.RoundTrip ? (searchOptions.returnDate ? `- ${searchOptions.returnDate?.format('ddd, MMMM D')}` : '- ...') : ''}`}</FlightDateInput>}
                                            minDate={moment().startOf('day')} 
                                            maxDate={moment().add(335, 'days')}
                                            selectsRange={searchOptions.tripType === TripType.RoundTrip}
                                        />
                                    </DateContainer>
                                </>}
                                <Button disabled={!searchOptionsIsValid} onClick={goToFlightSearch} label={messages.Dates.Button}/>
                            </Dates>  
                        </motion.div>
                    </SearchOptionsContainer>
                </Content>
            </MainOverlay>
        </Main>
        <Page>
            {/* <Sidebar>
                <SidebarItem>{messages.Sidebar.Hotels}</SidebarItem>
                <SidebarItem>{messages.Sidebar.CarRentals}</SidebarItem>
                <SidebarItem>{messages.Sidebar.Experiences}</SidebarItem>
                <SidebarItem>{messages.Sidebar.Shopping}</SidebarItem>
                <SidebarItem>{messages.Sidebar.Rewards}</SidebarItem>
            </Sidebar> */}
            <Body>
                {/* <Section>
                    <Title>{messages.Hotel.Title}</Title>
                    <SpaceBetween>
                        <ButtonRow>
                            <SearchContainer>
                                <TextInput onChange={noop} icon={<SearchIcon/>} placeholder={messages.Hotel.Search} />
                            </SearchContainer>
                            <Dropdown placeholder={messages.Hotel.DatePlaceholder} />
                            <Dropdown placeholder={messages.Hotel.DatePlaceholder} />
                            <Dropdown placeholder={messages.Hotel.Dropdown} />
                        </ButtonRow>
                        <Button label={messages.Hotel.Button} buttonType={ButtonType.Purple}/>
                    </SpaceBetween>
                    <HotelSubtitle>{messages.Hotel.Subtitle}</HotelSubtitle>
                    <SpaceBetween>
                        {hotels.map(hotel => <HotelItemContainer>
                            <HotelItem hotel={hotel} />
                        </HotelItemContainer>)}
                    </SpaceBetween>
                </Section>
                <Divider/>
                <Section>
                    <Title>{messages.Car.Title}</Title>
                    <SpaceBetween>
                        <SearchContainer>
                            <TextInput onChange={noop} icon={<SearchIcon/>} placeholder={messages.Car.Search} />
                        </SearchContainer>
                        <Dropdown placeholder={messages.Car.Pickup} />
                        <Dropdown placeholder={messages.Car.Time} />
                        <Dropdown placeholder={messages.Car.Return} />
                        <Dropdown placeholder={messages.Car.Time} />
                    </SpaceBetween>
                    <SpaceBetween style={{marginTop: styles.Spacing.XS}}>
                        <div>
                            <Flex>
                                <input type='checkbox' style={{marginRight: styles.Spacing.XS}}/>
                                <BodySmall>{messages.Car.DifferentLocation}</BodySmall>
                            </Flex>
                            <Flex>
                                <input type='checkbox' style={{marginRight: styles.Spacing.XS}}/>
                                <BodySmall>{messages.Car.DriverAge}</BodySmall>
                            </Flex>
                        </div>
                        <Button label={messages.Car.Button} buttonType={ButtonType.Purple} />
                    </SpaceBetween>
                </Section>
                <Divider/>
                <Section>
                    <Title>{messages.Places.Title}</Title>
                    <Carousel>
                        {places.map(place => <PlaceContainer>
                            <PlaceItem place={place} />
                        </PlaceContainer>)}
                    </Carousel>
                </Section>
                <Divider/>
                <Section>
                    <Title>{messages.Shop.Title}</Title>
                    <SpaceBetween>
                        {shopItems.map(shopItem => <ShopItemContainer>
                            <ShopItem product={shopItem} />
                        </ShopItemContainer>)}
                    </SpaceBetween>
                </Section>
                <Divider/> */}
                <Section>
                    <Title marginBottom={styles.Spacing.XS}>{messages.Rewards.Title}</Title>
                    <BodySmall>{messages.Rewards.Note}</BodySmall>
                    <Redeem>
                        {currentUser.numPointsPending ? <motion.div key='pointsRedemption' {...fadeInOutMotionProps}>
                            <CashRedemptionDetails>
                                <CashRedemptionDetail>
                                    <BodySmall>{messages.Rewards.PointsAvailable}</BodySmall>
                                    <Balance>{currentUser.numPointsPending?.toLocaleString(Locale.English)}</Balance>
                                </CashRedemptionDetail>
                                <CashRedemptionDetail>
                                    <BodySmall>{messages.Rewards.RedeemableCashValue}</BodySmall>
                                    <Balance>{((convertPointsToDollars(currentUser.numPointsPending)) || 0).toLocaleString(Locale.English, USDCurrencyOptions)}</Balance>
                                </CashRedemptionDetail>
                            </CashRedemptionDetails>
                            <CashRedemptionDetails>
                                <CashRedemptionDetail>
                                    <BodySmall>{messages.Rewards.PointsToRedeem}</BodySmall>
                                    <RedeemPointsInput
                                        onFocus={unformatPointsToRedeem}
                                        onBlur={formatPointsToRedeem}
                                        value={Number(pointsToRedeem.replaceAll(',', '')) ? pointsToRedeem : ''}
                                        onChange={updatePointsToRedeem}
                                    />
                                </CashRedemptionDetail>
                                <CashRedemptionDetail>
                                    <BodySmall>{messages.Rewards.CashValue}</BodySmall>
                                    <Balance>{(convertPointsToDollars(Number(pointsToRedeem.replaceAll(',', ''))))?.toLocaleString(Locale.English, USDCurrencyOptions)}</Balance>
                                </CashRedemptionDetail>
                                <CashRedemptionButtonContainer>
                                    {statementCreditRequest.loading ? <SpinnerWrapper>
                                        <SpinnerContainer>
                                            <Spinner/>
                                        </SpinnerContainer>
                                    </SpinnerWrapper> : <Button onClick={toggleCashRedemptionModal} label={messages.Rewards.Button} buttonType={ButtonType.Purple}/>}
                                </CashRedemptionButtonContainer>
                            </CashRedemptionDetails>
                        </motion.div> : <NoPointsNote key='noPointsNote' {...fadeInOutMotionProps}>
                                You don't currently have any points to redeem.
                        </NoPointsNote>}
                    </Redeem>
                </Section>
            </Body>
        </Page>
        <Footer/>
        {isCashRedemptionModalOpen && <Modal title='Confirm Redemption' onClose={toggleCashRedemptionModal}>
            <ModalContent>
                <ModalAccountName>
                    <strong>Account:</strong>
                    <div>{currentUser.cardAccounts.find((account: any) => account.uuid === banking.account?.uuid)?.name} ...{currentUser.cardAccounts.find((account: any) => account.uuid === banking.account?.uuid)?.accountLast4}</div>
                </ModalAccountName>
                <CashRedemptionDetails>
                    <CashRedemptionDetail>
                        <BodySmall>Current Point Balance</BodySmall>
                        <Balance>{currentUser.numPointsPending?.toLocaleString(Locale.English)}</Balance>
                    </CashRedemptionDetail>
                    <CashRedemptionDetail>
                        <BodySmall>Balance after Redemption</BodySmall>
                        <Balance>{(currentUser.numPointsPending - (Number(pointsToRedeem.replaceAll(',', ''))))?.toLocaleString(Locale.English)}</Balance>
                    </CashRedemptionDetail>
                </CashRedemptionDetails>
                <CashRedemptionDetails>
                    <CashRedemptionDetail>
                        <BodySmall>Cost in Points</BodySmall>
                        <Balance>{pointsToRedeem}</Balance>
                    </CashRedemptionDetail>
                    <CashRedemptionDetail>
                        <BodySmall>Redeemable Value</BodySmall>
                        <Balance>{(convertPointsToDollars(Number(pointsToRedeem.replaceAll(',', ''))))?.toLocaleString(Locale.English, USDCurrencyOptions)}</Balance>
                    </CashRedemptionDetail>
                </CashRedemptionDetails>
                {redemptionError && <StatusDisplay isLoading={false} isError label='There was a problem submitting your credit request.'/>}
                <div className='d-flex justify-content-end'>
                    <ModalButton onClick={redeemPoints}>Confirm</ModalButton>
                </div>
            </ModalContent>
        </Modal>}
    </Container>
}

const StyledReverseIcon = styled(Reverse)`
    fill: white;
    height: auto;
    width: 30px;   
`

const MainOverlay = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 3;
`

type ParallaxImageProps = {
    isImageLoaded: boolean,
}

const ParallaxImage = styled.div<ParallaxImageProps>`
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover;
    z-index: 2;
    -moz-transition: opacity .2s ease-in;
    -o-transition: opacity .2s ease-in;
    -webkit-transition: opacity .2s ease-in;
    transition: opacity .2s ease-in;
    opacity: ${props => props.isImageLoaded ? 1 : 0};
`

const GreetingContainer = styled.div`
    min-height: 104px;
`

const NoPointsNote = styled(motion.div)`
    opacity: 0.5,
    display: flex;
    justify-content: center;
    align-items: center;
    margin: ${styles.Spacing.S};
    height: 200px;
`

const ModalAccountName = styled.div`
    margin-bottom: ${styles.Spacing.S};
`

const MobileDateContainer = styled.div`
    border-bottom: 1px solid white;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${styles.Spacing.M};
`

const MobileFlightLocationContainer = styled.div`
    margin-bottom: ${styles.Spacing.S};
    display: flex;
    width: 100;
    align-items: center;
    justify-content: space-between;
`

const ModalContent = styled.div`
    width: 100%;
`

const DateContainer = styled.div`
    min-width: 0;
    margin-right: ${styles.Spacing.M};
    height: 62px;
    flex: 1;
`

const FlightLocationContainer = styled.div`
    flex: 1;
    margin-right: ${styles.Spacing.M};
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`

const ModalButton = styled.button`
    width: 50%;
    height: ${styles.Spacing.M};
    border: 0;
    background-color: ${styles.Color.TaekusPurple};
    color: ${styles.Color.White};
    white-space: nowrap;
`

const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    ${styles.MediaQueries.Mobile} {
        height: 100%;
        width: 100%;
    }
    ${styles.MediaQueries.Desktop} {
        height: ${styles.Spacing.L};
        width: 160px;
    }
`

const CashRedemptionDetail = styled.div`
    ${styles.MediaQueries.Desktop} {
        min-width: 240px;
        width: 25%;
        height: 100px;
    }
`

const CashRedemptionDetails = styled.div`
    display: flex;
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
    }
`

const CashRedemptionButtonContainer = styled.div`
    display: flex;
    align-items: start;
    justify-content: start;
    ${styles.MediaQueries.Mobile} {
        height: ${styles.Spacing.M};
        margin-top: ${styles.Spacing.XS};
    }
`

const MobileFlightDatePickerInput = styled.input`
    ${styles.Text.BodySmall}
    flex: 1;
    color: white;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    border-radius: 0px;
    background-color: transparent;
    cursor: pointer;
    height: 30px;
    outline: 0;
    font-size: 16px;
    &::-webkit-date-and-time-value {
        text-align: start;
    }
    &:nth-child(n+2) {
        text-align: end;
        &::-webkit-date-and-time-value {
            text-align: end;
        }
    }
    &::-webkit-calendar-picker-indicator {
        display: none;
    }
`

const SpinnerContainer = styled.div`
    width: ${styles.Spacing.S};
    height: ${styles.Spacing.S};
`

const RedeemPointsInput = styled.input`
    border-top: 0;
    border-left: 0;
    border-right: 0;
    background-color: transparent;
    border-radius: 0;
    outline: 0;
    ${styles.Text.DisplayMedium}
    border-bottom: 1px solid ${styles.Color.TaekusPurple};
    ${styles.MediaQueries.Desktop} {
        height: ${styles.Spacing.L};
        width: 200px;
    }
    ${styles.MediaQueries.Mobile} {
        width: 50%;
        font-size: ${styles.Spacing.S};
        margin-bottom: ${styles.Spacing.XS};
    }
`

const ReverseContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    ${styles.MediaQueries.Desktop} {
        height: ${styles.Spacing.L};
        margin: 0 ${styles.Spacing.S};
    }
    ${styles.MediaQueries.Mobile} {
        height: ${styles.Spacing.M};
    }
`

const Balance = styled.div`
    ${styles.Text.DisplayMedium}
    width: 187px;
    ${styles.MediaQueries.Mobile} {
        font-size: 20px;
        margin-bottom: ${styles.Spacing.XS};
    }
    ${styles.MediaQueries.Desktop} {
        height: ${styles.Spacing.L};
        display: flex;
        align-items: center;
        &:not(&:last-child) {
            margin-bottom: 53px;
        }    
    }
`

const Redeem = styled.div`
    ${styles.MediaQueries.Desktop} {
        margin-top: ${styles.Spacing.M};
        margin-bottom: 100px;
    }
    ${styles.MediaQueries.Mobile} {
        margin-top: ${styles.Spacing.S};
        margin-bottom: ${styles.Spacing.S};
    }
`

type TitleProps = {
    marginBottom?: string
}

const Title = styled.div<TitleProps>`
    ${styles.Text.DisplayMedium}
    color: ${styles.Color.NearBlack};
    margin-bottom: ${props => props.marginBottom || styles.Spacing.M};
`

const Section = styled.div`
    width: 100%;
`

const Body = styled.div`
    flex: 1;
    min-width: 0;
    padding: 0 ${styles.Spacing.M};
`

type BodySmallProps = {
    marginBottom?: string
}

const BodySmall = styled.div<BodySmallProps>`
    ${styles.Text.BodySmall}
    margin-bottom: ${props => props.marginBottom || 0};
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const Page = styled.div`
    display: flex;
    ${styles.MediaQueries.Desktop} {
        padding: 80px 0;
    }
    ${styles.MediaQueries.Mobile} {
        padding-top: ${styles.Spacing.M};
        padding-bottom: ${styles.Spacing.M};
    }
`

type InputFormProps = {
    width?: string
}

const FlightDateInput = styled.div<InputFormProps>`
    display: flex;
    align-items: end;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: min(2.2vw, 32px);
    height: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    border-bottom: 1px solid white;
    padding: 5px 8px 10px;
    width: ${props => props.width || 'auto'};
`

const Dates = styled.div`
    ${styles.MediaQueries.Desktop} {
        display: flex;
    }
`

const TicketSettings = styled.div`
    display: flex;
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
        margin-bottom: ${styles.Spacing.M};
    }
    ${styles.MediaQueries.Desktop} {
        margin-bottom: ${styles.Spacing.S};
    }
`

const TicketSettingsDropdown = styled.select`
    background-color: ${styles.Color.Transparent};
    color: white;
    border: 0;
    outline: 0;
    border-bottom: 1px solid transparent;
    cursor: pointer;
    &:hover {
        border-bottom: 1px solid white;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
        margin-bottom: ${styles.Spacing.M};
        border-bottom: 1px solid white;
    }
    ${styles.MediaQueries.Desktop} {
        margin-right: ${styles.Spacing.S};
    }
    ${styles.Animation.transitionStyles}
`

const SearchOptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const Greeting = styled.div`
    ${styles.Text.DisplayLarge}
    max-width: 600px;
    flex: 1;
    white-space: pre-line;
    ${styles.MediaQueries.Mobile} {
        font-size: 28px;
    }
    ${styles.Animation.transitionStyles}
`

const Container = styled.div`
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    ${styles.Scrollbar.defaultScrollbarStyles}
`

const Main = styled.div`
    position: relative;
    height: 95%;
    display: flex;
    flex-direction: column;
    background-color: #9FA9A6;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility:    hidden;
    -ms-backface-visibility:     hidden;
    overflow: hidden;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-around;
    color: white;
    ${styles.MediaQueries.Desktop} {
        padding: 0 ${styles.Spacing.M};
    }
    ${styles.MediaQueries.Mobile} {
        padding: 0 ${styles.Spacing.S};
    }
`

export default Rewards