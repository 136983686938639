import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";

import { connect } from "react-redux";

import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import ReactTooltip from "react-tooltip";

import Session from "services/session";

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      rememberMe: false,
      token: "",
      appBased2Fa: false,
      showTwoFAForm: false,
      showLoginError: false,
      loginErrorMessage: "",
      showTwoFAError: false,
      showPassword: false,
      isSubmitting: false,
    };
  }

  toggleShowPassword = (event) => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  generateChangeHandler = (value, fieldName) => {
    let newState = this.state;
    newState[fieldName] = value;

    this.setState({
      ...this.state,
      newState,
    });
  };

  get401ErrorMessage = (error) => {
    const { username } = this.state;
    // If login fails and the username was an email, use a specific error message
    const isUsernameAnEmailValue = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(username);

    const errorData = error.response.data

    if (errorData.verificationNeeded) {
      return `You must verify your email account before you can log in.`
    }
    if (isUsernameAnEmailValue) {
      return `Looks like you're using an email. Verify your username is correct.`
    }
    return "Login attempt unsuccessful. Please try again."
  }

  getErrorMessage = (error) => {
    switch (error?.response?.status) {
      case 429:
        return "Too many failed login attempts. Please try again later..";
      case 401:
      default:
        return this.get401ErrorMessage(error)
    }
  };

  handleLoginFailure = (error) => {
    this.setState({
      showLoginError: true,
      isSubmitting: false,
      loginErrorMessage: this.getErrorMessage(error),
    });
  };

  handleTwoFASuccess = (rememberMe, appBased2Fa) => {
    this.setState({
      showTwoFAForm: true,
      isSubmitting: false,
      rememberMe: rememberMe,
      appBased2Fa: appBased2Fa,
    });
  };

  handleTwoFAFailure = () => {
    this.setState({
      showTwoFAError: true,
      isSubmitting: false,
    });
  };

  handleNeedsTwoFARegistration = (userPhone) => {
    const { history } = this.props;
    history.push({ pathname: "/signup/verify/", state: { phone: userPhone } });
  };

  login = (e) => {
    e.preventDefault();
    const { username, password, rememberMe, appBased2Fa, token } = this.state;

    this.setState(
      { isSubmitting: true, showLoginError: false, loginErrorCode: null },
      () => {
        Session.login(
          username,
          password,
          rememberMe,
          appBased2Fa,
          token,
          this.handleLoginFailure,
          this.handleTwoFASuccess,
          this.handleTwoFAFailure,
          this.handleNeedsTwoFARegistration,
        );
      },
    );
  };

  resendVerification = (e) => {
    e.preventDefault();
    const { username, password, rememberMe } = this.state;

    Session.login(
      username,
      password,
      rememberMe,
      false,
      "",
      this.handleLoginFailure,
      this.handleTwoFASuccess,
      this.handleTwoFAFailure,
      this.handleNeedsTwoFARegistration,
    );
  };

  render() {
    const {
      username,
      password,
      rememberMe,
      appBased2Fa,
      token,
      isSubmitting,
      showLoginError,
      loginErrorMessage,
      showPassword,
      showTwoFAForm,
    } = this.state;

    if (this.props.currentUser.userLoaded) {
      return <Redirect to="/" />;
    }

    if (showTwoFAForm) {
      const cannotSubmit = !/^\d+$/.test(token);
      const isDisabled = cannotSubmit || isSubmitting;

      return (
        <Fragment>
          <div className="StandardBodyDMSans mb-5">
            {appBased2Fa ? (
              <FormattedMessage
                id="signup.app-based-2fa.description"
                defaultMessage={`Please enter the code on your authenticator app.`}
              />
            ) : (
              <>
                {showTwoFAForm ? (
                  <FormattedMessage
                    id="signup.verify-phone.description"
                    defaultMessage={`Please enter the seven-digit code we just sent to your phone.`}
                  />
                ) : (
                  <FormattedMessage
                    id="signup.verify-phone.description"
                    defaultMessage={`Please wait, sending verification code...`}
                  />
                )}
                <button
                  className="StandardButtonLink px-0"
                  onClick={this.resendVerification}
                >
                  <FormattedMessage
                    id="signup.verify-phone.resend-code"
                    defaultMessage={`Didn't receive a code? Click here to resend.`}
                  />
                </button>
              </>
            )}
          </div>
          <Form className="Login-form" onSubmit={this.login}>
            <FormGroup className="login-formGroup">
              <input
                type="text"
                name="token"
                className="Login-Input w-100 mt-4"
                placeholder="Verification Code"
                value={token}
                maxLength="7"
                onChange={(e) =>
                  this.generateChangeHandler(e.target.value, "token")
                }
              />
              {showLoginError && (
                <div className="StandardBodyDMSans text-red text-center mt-3">
                  <FormattedMessage
                    id="verification.error"
                    defaultMessage={`2FA validation attempt unsuccessful. Please try again.`}
                  />
                </div>
              )}
            </FormGroup>
            <div className="d-flex flex-column align-items-center">
              <FormGroup check className="d-flex align-items-center">
                <Input
                  type="checkbox"
                  checked={rememberMe}
                  name="rememberMe"
                  id="rememberMeCheck"
                  onChange={(e) =>
                    this.generateChangeHandler(e.target.checked, "rememberMe")
                  }
                />
                <Label
                  className="form-check-label pl-2"
                  htmlFor="rememberMeCheck"
                >
                  <div
                    className="StandardSubtitleDMSans text-underline mt-1"
                    data-tip
                    data-for="rememberMeToolTip"
                  >
                    <FormattedMessage
                      id="login.remember-device"
                      defaultMessage={`Remember Device`}
                    />

                    <ReactTooltip
                      id="rememberMeToolTip"
                      place="top"
                      effect="solid"
                    >
                      This will remember this device for 30 days and not prompt
                      you for 2FA authentication.
                    </ReactTooltip>
                  </div>
                </Label>
              </FormGroup>
              <Button
                disabled={isDisabled}
                className="StandardButtonPurple font-weight-bold mt-3"
              >
                <FormattedMessage
                  id="signup.verify-phone.submit"
                  defaultMessage={`Submit Code`}
                />
              </Button>
            </div>
          </Form>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Form className="Login-form" onSubmit={this.login}>
          <FormGroup className="login-formGroup">
            <input
              type="text"
              name="username"
              className="Login-Input w-100"
              placeholder="Username"
              value={username}
              autoCorrect="off"
              autoComplete="none"
              onChange={(e) =>
                this.generateChangeHandler(
                  e.target.value.trim(),
                  "username",
                )
              }
            />
          </FormGroup>
          <FormGroup className="login-formGroup">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              className="Login-Input w-100"
              placeholder="Password"
              value={password}
              autoCorrect="off"
              autoComplete="none"
              onChange={(e) =>
                this.generateChangeHandler(e.target.value.trim(), "password")
              }
            />
            <button
              type="button"
              onClick={this.toggleShowPassword}
              className={classNames(
                "Login-glyph",
                { "Login-revealPassword": !showPassword },
                { "Login-hidePassword": showPassword },
              )}
            />
          </FormGroup>
          {showLoginError && (
            <div className="StandardBodyDMSans text-red text-center mt-3">
              <FormattedMessage
                id="login.error"
                defaultMessage={loginErrorMessage}
              />
            </div>
          )}
          <div className="d-flex flex-column align-items-center">
            <Button
              disabled={isSubmitting || !username || !password}
              className="StandardButtonPurple font-weight-bold mt-3"
            >
              <FormattedMessage id="login.sign-in" defaultMessage={`SIGN IN`} />
            </Button>
          </div>
        </Form>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.userAuth,
  currentUser: state.currentUser,
});

export default connect(mapStateToProps)(LoginForm);
