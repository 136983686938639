import React from "react"
import { useSelector } from "react-redux";

import { styled } from "styled-components"

import { ProductType } from "components/signup/types";

import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";

import styles from "styles/styles";

const Denied = () => {
    const application = useSelector((state: any) => state.signup.application)
    const products = useSelector((state: any) => state.signup.products)
    const leadFailed = useSelector((state: any) => state.signup.leadFailed)
    const offer = useSelector((state: any) => state.signup.offer)
    const stepDirection = useSelector((state: any) => state.signup.direction)

    const selectedProduct = products?.find((product: any) => product.productCode === application.productCode)
    const isDebit = selectedProduct?.productType === ProductType.Debit

    const getTextContent = () => {
        if (leadFailed) {
            return [
                'Your application is under review.',
                `You’ve already applied for a ${selectedProduct?.displayName}, but we’re still evaluating your application. We will send you an email shortly with more details.`
            ]
        }

        if (offer.status === 'DENIED') {
            return [
                `We are unable to ${isDebit ? 'open an account for' : 'pre-approve'} you.`,
                `Unfortunately, we are unable to ${isDebit ? 'open an account for you' : 'pre-approve your application'} at this time. ${isDebit ? '' : "This will not harm your credit."} We will provide you with more detailed information via email in the next few days explaining the reasons for this decision.`
            ]
        }

        return [
            'Something went wrong.',
            <>An error occured while trying to submit your application. Please contact support at <PurpleText>support@taekus.com</PurpleText></>
        ]
    }

    // const title = leadFailed ? 'Your application is under review.' : `We are unable to ${isDebit ? 'open an account for' : 'pre-approve'} you.`
    // const text = leadFailed ? `You’ve already applied for a ${selectedProduct?.displayName}, but we’re still evaluating your application. We will send you an email shortly with more details. ` : `Unfortunately, we are unable to ${isDebit ? 'open an account for you' : 'pre-approve your application'} at this time. ${isDebit ? '' : "This will not harm your credit."} We will provide you with more detailed information via email in the next few days explaining the reasons for this decision.`
    const [title, text] = getTextContent()

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='Denied'
    >
        <div className="d-flex flex-column align-items-center">
            <Title>{title}</Title>
            <Text>{text}</Text>
        </div>
    </StepContainer>
}

const PurpleText = styled.span`
    color: ${styles.Color.TaekusPurple};
`

const Text = styled.div`
    color: ${styles.Color.TaekusGrey2};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    margin-bottom: 16px;
    ${styles.MediaQueries.Mobile} {
        font-size: 14px;
    }
`

const Title = styled.div`
    color: ${styles.Color.TaekusGrey1};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-bottom: 32px;
    ${styles.MediaQueries.Mobile} {
        font-size: 24px;
    }
`

export default Denied