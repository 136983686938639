import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Modal } from "react-bootstrap";

import styled from "styled-components";

import { Actions } from "redux/currentUser";

import Spinner from "components/common/Spinner";
import StatusDisplay from "components/common/StatusDisplay";

import { LinkText } from "components/pages/Settings/constants";

import styles from "styles/styles";
import TwoFactorSetup from "components/pages/Settings/TwoFactorSetup/TwoFactorSetup";
import SuccessBanner from "components/common/SuccessBanner";
import { AnimatePresence, motion } from "framer-motion";
import { expandVerticallyMotionProps, fadeInOutMotionProps } from "styles/motionConstants";

const Security = () => {
    // Redux state
    const dispatch = useDispatch()
    const currentUser = useSelector((state: any) => state.currentUser)
    const parentUser = currentUser.currentUser.parentUser


    // Component state
    const [isDirty, setIsDirty] = useState(false)
    const [appBased2Fa, setAppBased2Fa] = useState<boolean>(currentUser.currentUser.appBased2Fa)
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false)
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword1, setNewPassword1] = useState('')
    const [newPassword2, setNewPassword2] = useState('')
    const [passwordErrorMessage, setPasswordErrorMessage] = useState<undefined | string>(undefined)
    const [updateMessage, setUpdateMessage] = useState<string | undefined>(undefined)
    const [showTwoFactorSetup, setShowTwoFactorSetup] = useState(false);
    const [successBannerMessage, setSuccessBannerMessage] = useState<string | undefined>(undefined);

    const getErrorMessage = (error?: string) => {
        switch (error) {
            case 'Old password does not match':
                return 'Your current password was incorrect.'
            case 'New passwords do not match':
                return 'Your new passwords do not match.'
            case 'This password is too short. It must contain at least 12 characters.':
                return 'Your password must contain at least 12 characters.'
            case 'This password does not contain at least two uppercase letters.':
                return 'Your password must contain at least two uppercase letters.'
            default:
                return error;
        }
    }

    const toggleIsPasswordModalOpen = () => {
        setOldPassword('')
        setNewPassword1('')
        setNewPassword2('')
        setPasswordErrorMessage(undefined);
        setIsPasswordModalOpen(!isPasswordModalOpen)
    }

    const openTwoFactorSetup = () => {
        setShowTwoFactorSetup(true);
        setSuccessBannerMessage(undefined);
    }

    const closeTwoFactorSetup = () => {
        setShowTwoFactorSetup(false);
    }

    const handleOldPasswordChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setOldPassword(ev.target.value)
    }

    const handleNewPassword1Change = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword1(ev.target.value)
    }

    const handleNewPassword2Change = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword2(ev.target.value)
    }

    const updatePassword = () => {
        setIsDirty(true)
        dispatch(Actions.saveCurrentUser({
            oldPassword,
            newPassword1,
            newPassword2
        }))
    }

    const handleTwoFactorSetupComplete = (success: boolean, message: string) => {
        closeTwoFactorSetup();
        if (success) {
            setSuccessBannerMessage(message);
        }
    }

    useEffect(() => {
        setAppBased2Fa(currentUser.currentUser.appBased2Fa)
    }, [currentUser.currentUser.appBased2Fa])

    useEffect(() => {
        if (!currentUser.isFetching && isDirty) {
            setPasswordErrorMessage(currentUser.updateErrors?.nonFieldErrors?.[0])
            if (currentUser.updateSuccess) {
                setIsPasswordModalOpen(false)
                setUpdateMessage('Your settings were saved successfully.')
            } else {
                setUpdateMessage('There was an error saving your settings.')
            }
        }
    }, [currentUser.isFetching, dispatch]) // eslint-disable-line

    return <div>
        <Title>Security</Title>
        {parentUser ? <HelpText><b>Acting as an authorized user for {parentUser.firstName} {parentUser.lastName}</b></HelpText>:<></>}
        <div style={{marginTop: '20px'}}>
            {updateMessage && <StatusDisplay isLoading={currentUser.isFetching} isError={!currentUser.updateSuccess} label={updateMessage}/>}
            <AnimatePresence mode="wait">
                {successBannerMessage && (
                    <motion.div
                        {...expandVerticallyMotionProps}
                    >
                        <SuccessBanner message={successBannerMessage} />
                    </motion.div>
                )}
            </AnimatePresence>
            <SettingItem>
                <div>Password</div>
                <LinkText onClick={toggleIsPasswordModalOpen}>Reset</LinkText>
            </SettingItem>
            <SettingItem isEditing={true}>
                <div>Two-Factor Authentication</div>
                <TwoFaEditWrapper>
                    <TwoFASelectedText>{appBased2Fa ? 'Authenticator' : 'Text Message'}</TwoFASelectedText>
                    <LinkText onClick={openTwoFactorSetup}>Edit</LinkText>
                </TwoFaEditWrapper>
            </SettingItem>
            
            <AnimatePresence mode="wait">
                {showTwoFactorSetup && (
                    <motion.div {...fadeInOutMotionProps}>
                        <TwoFactorSetup 
                            onClose={closeTwoFactorSetup}
                            onComplete={handleTwoFactorSetupComplete}
                        />
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
        <Modal show={isPasswordModalOpen} centered>
            <ModalContent>
                <ModalInputContainer>
                    <div>Current Password:</div>
                    <input type='password' value={oldPassword} onChange={handleOldPasswordChange}/>
                </ModalInputContainer>
                <ModalInputContainer>
                    <div>New Password:</div>
                    <input type='password' value={newPassword1} onChange={handleNewPassword1Change}/>
                </ModalInputContainer>
                <ModalInputContainer>
                    <div>New Password (Confirm):</div>
                    <input type='password' value={newPassword2} onChange={handleNewPassword2Change}/>
                </ModalInputContainer>
                <ResetPasswordError>
                    {getErrorMessage(passwordErrorMessage)}
                </ResetPasswordError>
                <ModalButtonsContainer>
                    <CancelButton onClick={toggleIsPasswordModalOpen}>Cancel</CancelButton>
                    <ConfirmButton onClick={updatePassword}>
                        {currentUser.isFetching ? <SpinnerContainer><Spinner/></SpinnerContainer> : 'Confirm'}
                    </ConfirmButton>
                </ModalButtonsContainer>
            </ModalContent>
        </Modal>
    </div>
}

const TwoFASelectedText = styled.div`
    color: ${styles.Color.TaekusGrey2};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
`

const TwoFaEditWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
`

const HelpText = styled.div`
     ${styles.Text.BodySmall}
    font-size: ${styles.Font.Size.Small};
    padding-top: ${styles.Spacing.S};
    text-align: center;
    color: ${styles.Color.NearBlack};
    opacity: 0.75;
`

const SpinnerContainer = styled.div`
    width: ${styles.Spacing.S};
    height: ${styles.Spacing.S};
`

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${styles.Spacing.S};
    min-height: 280px;
    height: min-content;
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

const ModalButtonsContainer = styled.div`
    display: flex;
    height: ${styles.Spacing.M};
    width: 100%;
    marginTop: ${styles.Spacing.S};
`

const ResetPasswordError = styled.div`
    display: flex;
    justifyContent: center;
    padding: ${styles.Spacing.XS} 0;
    min-height: ${styles.Spacing.S};
    color: red;
`

const ModalInputContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: ${styles.Spacing.XS} 0;
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
    }
`

const ConfirmButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${styles.Color.TaekusPurple};
    color ${styles.Color.White};
    border: 0;
    padding: 8px;
    flex: 1;
    margin: 0 ${styles.Spacing.XS};
`

const CancelButton = styled.button`
    background-color: ${styles.Color.Grey};
    color ${styles.Color.Black};
    border: 0;
    padding: 8px;
    flex: 1;
    margin: 0 ${styles.Spacing.XS};
`

const Title = styled.div`
    ${styles.Text.DisplayMedium}
    width: 100%;
    padding-bottom: ${styles.Spacing.XS};
    height: 50px;
    border-bottom: 1px solid grey;
`

const SettingItem = styled.div<{ isEditing?: boolean }>`
    font-family: ${styles.Font.Family.MonumentGrotesk}
    display: flex;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:not(&:last-child) {
        border-bottom: ${props => props.isEditing ? 'none' : '1px solid #D7D7D7'};
    }
`

export default Security;