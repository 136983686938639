import { LinkedAccount } from "types/LinkedAccount"
import moment from "moment"

export enum CreditPaymentSteps {
    Options,
    Review,
    Confirm
}

export enum PaymentOption {
    TotalBalance = 'Total Balance',
    MinimumPayment = 'Minimum Payment Due',
    Other = 'Other'
}

export type OptionProps = {
    amount: string
    updateAmount: (amount: string) => void
    sourceAccount?: LinkedAccount
    updateSourceAccount: (account?: LinkedAccount) => void
    paymentDate: moment.Moment
    updatePaymentDate: (date: moment.Moment) => void
    incrementStep: () => void
    totalBalance: number
    minimumPaymentDue: number
    formattedPaymentDueDate: string
    isMinimumPaymentOverdue: boolean
    selectedOption: PaymentOption
    setSelectedOption: (option: PaymentOption) => void
}

export type ReviewStepProps = {
    amount: string
    sourceAccount?: LinkedAccount
    paymentDate: moment.Moment
    decrementStep: () => void
    incrementStep: () => void
    selectedOption: PaymentOption
    resetForm: () => void
}

export type PaymentAmountOptionProps = {
    isSelected: boolean
    title: string
    subtitle?: string
    amount?: number | string
    onClick: () => void
    isOther?: boolean
    onAmountChange?: (value: string) => void
}

export type PaymentDetailRowProps = {
    label: string
    showDivider?: boolean
    showTaekusIcon?: boolean
    value: React.ReactNode
}