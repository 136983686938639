import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

import { motion } from "framer-motion"
import styled from 'styled-components'

import { Locale, USDCurrencyOptions } from 'utils/constants'

import { Actions as AutoPayActions } from 'redux/features/banking/autopay'
import { Actions as LinkedAccountActions } from 'redux/features/banking/linkedAccounts'
import { Actions as PayeeActions } from 'redux/features/banking/payees'

import FilterButton from 'components/common/FilterButton'
import Footer from 'components/common/Footer'
import Navigation, { NavColor } from 'components/navbar/Navigation'
import Spinner from 'components/common/Spinner'

import WireTransfer from 'components/pages/Funding/AccountInfo'
import AutoFund from 'components/pages/Funding/AutoFund'
import DebitPayment from 'components/pages/Funding/Payment/DebitPayment'
import Statements from 'components/pages/Funding/Statements'
import PeerToPeer from 'components/pages/Funding/TaekusPay'
import TransferActivity from 'components/pages/Funding/TransferActivity'

import { fadeInOutMotionProps } from 'styles/motionConstants'
import styles from 'styles/styles'

import CreditPayment from './Payment/CreditPayment/CreditPayment'
import { useAccountTypeInformation } from 'hooks/useAccountTypeInformation'
import FundingHeader from './components/FundingHeader'

const SIDEBAR_WIDTH = 240
const CONTENT_WIDTH = 890

enum SidebarTab {
    PayYourCard='Pay your Card',
    Payment='Make a Transfer',
    Statements='Statements',
    AutoFund='Auto Reload',
    AccountInfo='Account Info',
    TransferActivity='Transfer Activity',
    PeerToPeer='TaekusPay'
}

const getSelectedTabFromURL = (tab?: string, isSelectedAccountCredit?: boolean) => {
    if (tab) {
        switch (tab) {
            case 'statements':
                return SidebarTab.Statements
            case 'autoreload':
                return SidebarTab.AutoFund
            case 'accountinfo':
                return SidebarTab.AccountInfo
            case 'transferactivity':
                return SidebarTab.TransferActivity
            case 'taekuspay':
                return SidebarTab.PeerToPeer
            case 'payyourcard':
                return SidebarTab.PayYourCard
            case 'payment':
                return SidebarTab.Payment
        }
    }

    return isSelectedAccountCredit ? SidebarTab.PayYourCard : SidebarTab.Payment
}

const Funding = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { tab } = useParams<{ tab?: string }>();

    // Redux state
    const currentUser = useSelector((state: any) => state.currentUser.currentUser)
    const banking = useSelector((state: any) => state.banking)
    const autopay = useSelector((state: any) => state.autopay)
    const transfers = useSelector((state: any) => state.transfers.transfers)
    const shouldUpdateLinkedAccounts = useSelector((state: any) => state.linkedAccounts.shouldUpdateLinkedAccounts)
    const isMobile = useSelector((state: any) => state.global.isMobile)
    const { hasCreditAccount, hasDebitAccount, isSelectedAccountCredit, isLoading: isLoadingAccountTypes } = useAccountTypeInformation();
    // Local state
    const [selectedSidebarTab, setSelectedSidebarTab] = useState(getSelectedTabFromURL(tab, isSelectedAccountCredit))

    const pageBody = useRef(null)

    const pendingTransferCount = transfers.reduce((acc: number, current: any) => acc + (current.status === 'Pending' ? 1 : 0), 0)
    const pendingTransferAmount = transfers.reduce((acc: number, current: any) => acc + (current.status === 'Pending' ? (current.destination.lastFour === banking.account.accountNumber.slice(-4) ? current.amount : -current.amount) : 0), 0)

    const sidebarTabs = useMemo(() => [
        ...((isSelectedAccountCredit) ? [SidebarTab.PayYourCard] : []),
        ...(!isSelectedAccountCredit ? [SidebarTab.Payment] : []),
        SidebarTab.Statements,
        ...(!isSelectedAccountCredit ? [SidebarTab.AutoFund] : []),
        SidebarTab.AccountInfo,
        ...(!isSelectedAccountCredit ? [SidebarTab.TransferActivity] : []),
        ...((currentUser.isDisabledFraud || isSelectedAccountCredit) ? [] : [SidebarTab.PeerToPeer]),
    ], [hasCreditAccount, isSelectedAccountCredit, hasDebitAccount, currentUser.isDisabledFraud])

    // On component render, fetch linked accounts
    useEffect(() => {
        dispatch(LinkedAccountActions.getLinkedAccounts())
        dispatch(LinkedAccountActions.getInternalAccounts())
        dispatch(PayeeActions.getPayees())
    }, [dispatch])

    // If Plaid Modal creates or verifies an account, check if we should re-fetch linked accounts
    useEffect(() => {
        if (shouldUpdateLinkedAccounts){
            dispatch(LinkedAccountActions.getLinkedAccounts())
        }
    }, [shouldUpdateLinkedAccounts, dispatch])

    // fetch Autopay for the sidebar display
    useEffect(() => {
        if (banking.account.uuid){
            dispatch(AutoPayActions.fetchAutopay({ cardAccountUuid: banking.account.uuid }))
        }
    }, [banking, dispatch])

    useEffect(() => {
        if (!isLoadingAccountTypes) {
            setSelectedSidebarTab(getSelectedTabFromURL(tab, isSelectedAccountCredit))
        }
    }, [isLoadingAccountTypes, tab, isSelectedAccountCredit])
    const getTabContent = () => {
        switch (selectedSidebarTab) {
            case SidebarTab.TransferActivity:
                return <TransferActivity/>
            case SidebarTab.Statements:
                return <Statements/>
            case SidebarTab.AutoFund:
                return <AutoFund/>
            case SidebarTab.AccountInfo:
                return <WireTransfer/>
            case SidebarTab.PeerToPeer:
                return <PeerToPeer/>
            case SidebarTab.PayYourCard:
                return <CreditPayment/>
            case SidebarTab.Payment:
            default:
                return isSelectedAccountCredit ? <CreditPayment/> : <DebitPayment/>
        }
    }

    const mapSidebarTabToItem = (sidebarTab: SidebarTab) => {
        const selectSidebarItem = () => {
            setSelectedSidebarTab(sidebarTab);
            history.push(`/funding/${sidebarTab.replaceAll(' ', '').toLowerCase()}`);
            (pageBody.current as any).scrollTo!({ top: 0, behavior: 'smooth' })
        }

        return <SidebarItem 
            onClick={selectSidebarItem}
            isSelected={sidebarTab === selectedSidebarTab}
            key={`tab:${sidebarTab}`}
        >
            {sidebarTab}
        </SidebarItem>
    }

    return <Container ref={pageBody}>
        <div>
            <Inner>
                <Navigation color={NavColor.Black}/>
                <FundingHeader/>
                <Content>
                    {isMobile ? <div>
                        <MobileTitle>Manage Funds</MobileTitle>
                        <FilterButtonContainer>
                            {sidebarTabs.map(tab => <FilterButton 
                                onClick={() => setSelectedSidebarTab(tab)}
                                selected={selectedSidebarTab === tab}
                            >
                                {tab}
                            </FilterButton>)}
                        </FilterButtonContainer>
                    </div> : 
                    <Sidebar>
                        <AnimatePresence mode='wait'>
                            {isLoadingAccountTypes ? (
                                <SpinnerWrapper {...fadeInOutMotionProps}>
                                    <Spinner size={24}/>
                                </SpinnerWrapper>
                            ) : (
                                sidebarTabs.map(mapSidebarTabToItem)
                            )}
                        </AnimatePresence>
                    </Sidebar>}
                    <Funds key={selectedSidebarTab} {...fadeInOutMotionProps}>
                        
                        <AnimatePresence mode='wait'>
                            {isLoadingAccountTypes ? (
                                <SpinnerWrapper {...fadeInOutMotionProps}>
                                    <Spinner size={40}/>
                                </SpinnerWrapper>
                            ) : (
                                <>
                                    {!isMobile && (
                                        <Title>
                                            {selectedSidebarTab}
                                            {selectedSidebarTab === SidebarTab.TransferActivity && pendingTransferCount > 0 && 
                                                <PendingTransferText>
                                                    {pendingTransferCount} transfers pending ({(pendingTransferAmount>0 ? "+" : "") + pendingTransferAmount.toLocaleString(Locale.English, USDCurrencyOptions)})
                                                </PendingTransferText>
                                            }
                                        </Title>
                                    )}
                                    {getTabContent()}
                                </>
                            )}
                        </AnimatePresence>
                    </Funds>
                </Content>
            </Inner>
            <Footer/>
        </div>
    </Container>
}

const Inner = styled.div`
    ${styles.MediaQueries.Mobile} {
        height: min-content;
        min-height: 100vh;
        flex: 1;
        display: flex;
        flex-direction: column;
    }
`

const PendingTransferText = styled.div`
    font-size: ${styles.Font.Size.Small};
    margin-left: ${styles.Spacing.XS};
    margin-bottom: 6px;
    opacity: 0.5;
`

type SidebarItemProps = {
    isSelected: boolean;
}

const SidebarItem = styled.div<SidebarItemProps>`
    cursor: pointer;
    color: ${props => props.isSelected ? styles.Color.TaekusPurple : styles.Color.Black};
    &:hover {
        color: ${styles.Color.TaekusPurple};
    }
`

const Title = styled(motion.div)`
    display: flex;
    align-items: end;
    font-style: normal;
    font-weight: ${styles.Font.Weight[400]};
    font-size: 32px;
    border-bottom: 1px solid ${styles.Color.Black};
`

const MobileTitle = styled.div`
    padding: 0 ${styles.Spacing.S};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 32px;
`

const Container = styled.div`
    height: 100%;
    background-size: cover;
    display: flex;
    overflow-y: scroll;
    flex-direction: column;
    color: ${styles.Color.Black};
    ${styles.Scrollbar.defaultScrollbarStyles}
    ${styles.MediaQueries.Mobile} {
        width: 100vw;
        overflow-x: hidden;
    }
`

const Sidebar = styled.div`
    display: flex;
    flex-direction: column;
    min-width: ${SIDEBAR_WIDTH}px;
    padding-left: ${styles.Spacing.M};
    padding-right: ${styles.Spacing.M};
    padding-top: 48px;
`

const FilterButtonContainer = styled.div`
    display: flex;
    width: 100%;
    overflow-x: auto;
    height: 50px;
    margin: ${styles.Spacing.S} 0;
    padding: 0 ${styles.Spacing.S} ${styles.Spacing.XS};
    mask-image: linear-gradient(to right, transparent, black ${styles.Spacing.S}, black calc(100% - ${styles.Spacing.S}), transparent 100%);
    ${styles.Scrollbar.transparent}
`

const Content = styled.div`
    flex: 1;
    display: flex;
    ${styles.MediaQueries.Desktop} {
        padding-right: ${styles.Spacing.M};
        min-height: 100vh;
    }
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
    }
`

const Funds = styled(motion.div)`
    flex: 1;
    display: flex;
    flex-direction: column;
    ${styles.MediaQueries.Desktop} {
        min-width: ${CONTENT_WIDTH}px;
    }
    ${styles.MediaQueries.Mobile} {
        margin: 0 ${styles.Spacing.S};
    }
`

const SpinnerWrapper = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: ${styles.Spacing.L};
    width: 100%;
`

export default Funding