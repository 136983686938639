import Button, { ButtonSize, ButtonType } from 'components/common/Button';
import { SelectableOption } from 'components/common/SelectableOption/SelectableOption';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import styles from 'styles/styles';
import { AuthenticatorSetup } from 'components/pages/Settings/TwoFactorSetup/AuthenticatorSetup';
import { Actions } from 'redux/currentUser';
import { AnimatePresence, motion } from "framer-motion";
import { fadeInOutMotionProps } from "styles/motionConstants";

interface TwoFactorSetupProps {
    onClose: () => void;
    onComplete: (success: boolean, message: string) => void;
}

interface SetupState {
    step: 1 | 2;
    verificationCode: string;
    authKey: string | null;
}

enum TwoFactorOption {
    Text = 'text',
    Authenticator = 'authenticator'
}

enum TwoFactorStep {
    SelectOption = 1,
    EnterCode = 2
}

const TwoFactorSetup: React.FC<TwoFactorSetupProps> = ({ onClose, onComplete }) => {
    const dispatch = useDispatch();
    const currentUser = useSelector((state: any) => state.currentUser);
    const [setup, setSetup] = useState<SetupState>({
        step: TwoFactorStep.SelectOption,
        verificationCode: '',
        authKey: null
    });

    const [selectedOption, setSelectedOption] = useState<TwoFactorOption>(currentUser.currentUser.appBased2Fa ? TwoFactorOption.Authenticator : TwoFactorOption.Text);

    const handleSubmit = async () => {
        if (setup.step === TwoFactorStep.SelectOption) {
            if (selectedOption === TwoFactorOption.Authenticator) {
                setSetup(prev => ({
                    ...prev,
                    step: TwoFactorStep.EnterCode
                }));
            } else {
                dispatch(Actions.updateIsUsingAppBased2FA(false))
                onComplete(true, 'You will now receive 2FA codes through text messages.');
            }
        }
    };

    return (
        <Container {...fadeInOutMotionProps}>
            <AnimatePresence mode='wait'>
                {setup.step === TwoFactorStep.SelectOption ? (
                    <motion.div key="select-option" {...fadeInOutMotionProps}>
                        <SetupContainer>
                            <HeadingContainer>
                                <HeadingText>How would you like to receive your 2FA login codes?</HeadingText>
                                <SubheadingText>Two-factor authentication protects your account from fraud. Taekus supports two methods to receive 2FA login codes.</SubheadingText>
                            </HeadingContainer>
                            <SelectableOptionContainer>
                                <SelectableOption title="Text Message"
                                subtitle="We'll text you a verification code when you log in."
                                isSelected={selectedOption === TwoFactorOption.Text}
                                onClick={() => setSelectedOption(TwoFactorOption.Text)} 
                                showSubtitleWhenUnselected={true}
                                />
                                <SelectableOption title="Authenticator App"
                                subtitle="You'll use an authenticator app (like Google Authenticator) to get a verification code."
                                isSelected={selectedOption === TwoFactorOption.Authenticator}
                                onClick={() => setSelectedOption(TwoFactorOption.Authenticator)}
                                showSubtitleWhenUnselected={true}
                                />
                            </SelectableOptionContainer>    
                            <ButtonContainer>
                                <Button 
                                    onClick={handleSubmit} 
                                    label="Save" 
                                    buttonType={ButtonType.Purple}     
                                    size={ButtonSize.Auto}        
                                />
                            </ButtonContainer>
                        </SetupContainer>
                    </motion.div>
                ) : setup.step === TwoFactorStep.EnterCode ? (
                    <motion.div key="enter-code" {...fadeInOutMotionProps}>
                        <AuthenticatorSetup onClose={onClose} onComplete={onComplete} />
                    </motion.div>
                ) : null}
            </AnimatePresence>
        </Container>
    );
};

const ButtonContainer = styled.div`
    width: 200px;
`;

const Container = styled(motion.div)`
    width: 100%;
    height: 100%;
`;

// Styled components
const SetupContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
`;

const HeadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
`;

const HeadingText = styled.div`
    color: ${styles.Color.TaekusGrey1};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
`;

const SubheadingText = styled.div`
    color: ${styles.Color.TaekusGrey2};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;

const SelectableOptionContainer = styled.div`
    display: flex;
    width: 632px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`;
export default TwoFactorSetup;
