import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { styled } from "styled-components"

import { formatPhoneNumber, removeCountryCodeFromPhoneNumber } from "utils/utils";

import { SignupStep } from "components/signup/constants";
import { updateApplication, updateStep } from "components/signup/signupSlice";
import { StepDirection } from "components/signup/types";


import ArrowButton, { ArrowDirection } from "components/signup/components/ArrowButton";
import Button from "components/signup/components/Button";
import Input from "components/signup/components/Input";
import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";

import styles from "styles/styles";

const ElectronicCommunicationsDisclosureLink = 'https://taekus.com/legal/electronic-communications-policy'
const PrivacyPolicyLink = 'https://cdn.prod.website-files.com/602af9837050241ae008b45c/66451d35af38c60ee6439aaa_Privacy%20Policy.pdf'

const FindInfo = () => {
    const dispatch = useDispatch()

    const inputRef = useRef(null)

    const isLoading = useSelector((state: any) => state.signup.isLoading)
    const application = useSelector((state: any) => state.signup.application)
    const products = useSelector((state: any) => state.signup.products)
    const prove = useSelector((state: any) => state.signup.prove)
    const isMobile = useSelector((state: any) => state.global.isMobile)
    const leadFailed = useSelector((state: any) => state.signup.leadFailed)
    const stepDirection = useSelector((state: any) => state.signup.direction)

    const [phoneNumber, setPhoneNumber] = useState(application.phoneNumber ? removeCountryCodeFromPhoneNumber(application.phoneNumber) : '')
    const [birthDate, setBirthDate] = useState(application.birthDate ? moment(application.birthDate).format('YYYY-MM-DD') : '')
    const [isFormLocked, setIsFormLocked] = useState(false)
    const [showValidationErrors, setShowValidationErrors] = useState(false)

    const selectedProduct = products?.find((product: any) => product.productCode === application.productCode)
    const yearsAge = moment(birthDate).diff(moment(), 'years') * -1 // invert diff to get years
    const isBirthDateValid = birthDate !== '' && yearsAge > 17 && yearsAge <= 100
    const isPhoneValid = phoneNumber && phoneNumber.length >= 10
    const isInputValid = isBirthDateValid && isPhoneValid
    const isButtonDisabled = isFormLocked

    const handleBack = (ev: React.MouseEvent) => {
        ev.preventDefault();
        ev.stopPropagation();
        dispatch(updateStep({ step: SignupStep.ChooseProduct, direction: StepDirection.Left }))
    }

    const cleanPhoneNumber = (phoneNumber: string) => {
        return phoneNumber.replace(/\D/g, '')
    }

    const handlePhoneChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const replaceBackspace = ev.target.value.includes('(') && !ev.target.value.includes(')')
        const cleanedString = cleanPhoneNumber(replaceBackspace ? `${ev.target.value.slice(0,-1)})` : ev.target.value)

        if (cleanedString.length <= 10) {
            setPhoneNumber(cleanedString)
        }
    }

    const handleSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();
        if (isInputValid) {
            setShowValidationErrors(false)
            setIsFormLocked(true)
            dispatch(updateApplication({ birthDate, phoneNumber: `+1${phoneNumber}` }))
            dispatch(updateStep({ step: SignupStep.LoadingProve, direction: StepDirection.Right }))
        } else {
            setShowValidationErrors(true)
        }
    }

    useEffect(() => {
        if (!isMobile) {
            (inputRef.current as any)?.focus()
        }
    }, []) // eslint-disable-line

    const dobErrorMessage = showValidationErrors && !isBirthDateValid ? ((yearsAge < 18 && yearsAge >= 0) ? 'You must be at least 18 years old.' : `Enter a valid birthday.`) : undefined
    const phoneErrorMessage = leadFailed ? `There was an issue creating your application. Please contact support.` : (showValidationErrors && !isPhoneValid ) ? `Enter a valid phone number.` : (prove.invalidPhone && 'The entered phone number is invalid.')

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='FindInfo'
    >
        <form className="d-flex flex-column align-items-center" onSubmit={handleSubmit}>
            <Title>Let’s start by finding your info.</Title>
            <Text>We might be able to save you time and pre-fill your application if you tell us your date of birth and phone number.</Text>
            <div style={{marginBottom: '8px'}}>
                <Input max={moment().format('YYYY-MM-DD')} errorMessage={dobErrorMessage} type='date' onChange={(ev) => { setBirthDate(ev.target.value) }}  value={birthDate} parentRef={inputRef} label="Date of Birth"/>
            </div>
            <Input 
                inputmode="numeric"
                errorMessage={phoneErrorMessage} 
                prefix={<div style={{opacity: 0.5, marginRight: '8px'}}>+1</div>} 
                onChange={handlePhoneChange} 
                value={formatPhoneNumber(phoneNumber)} 
                label="Phone number"
            />
            <div style={{marginTop: '32px', display: 'flex'}}>
                <div style={{marginRight: '16px'}}>
                    <ArrowButton onClick={handleBack} direction={ArrowDirection.Left}/>
                </div>
                <Button isLoading={isLoading} disabled={isButtonDisabled} type="submit">Next</Button>
            </div>
            <Disclaimer>By selecting ‘Next’, you agree to receive <DocLink href={selectedProduct?.fileBundleElectronicDisclosureUrl || ElectronicCommunicationsDisclosureLink} target="_blank">Electronic Disclosures</DocLink> and to our <DocLink href={selectedProduct?.fileBundlePrivacyPolicyUrl || PrivacyPolicyLink} target="_blank">Privacy Policy</DocLink>.</Disclaimer>
        </form>
    </StepContainer>
}

const DocLink = styled.a`
    color: ${styles.Color.TaekusPurple} !important;
    font-weight: 600;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`

const Disclaimer = styled.div`
    color: ${styles.Color.TaekusGrey2};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 16.56px */
    letter-spacing: 0.24px;
    margin-top: 24px;
`

const Text = styled.div`
    color: ${styles.Color.TaekusGrey2};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    margin-bottom: 16px;
    ${styles.MediaQueries.Mobile} {
        font-size: 14px;   
    }
`

const Title = styled.div`
    color: ${styles.Color.TaekusGrey1};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-bottom: 32px;
    ${styles.MediaQueries.Mobile} {
        font-size: 24px;   
    }
`

export default FindInfo