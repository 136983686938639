import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import { AnimatePresence, motion } from "framer-motion";
import moment from "moment"

import styled from "styled-components"

import { Actions as FlightSearchActions } from 'redux/features/flightSearch'

import { SearchOptions, TravelClass, TripType } from "components/pages/Rewards/constants";
import { buildItineraryPayload } from "components/pages/Rewards/Flights/utils";

import { ReactComponent as ArrowRightSVG } from "assets/svg/ArrowRight.svg";
import { ReactComponent as ArrowLeftSVG } from "assets/svg/ArrowLeft.svg";
import { ReactComponent as Reload } from "assets/svg/Reload.svg";

import { fadeInOutMotionProps } from "styles/motionConstants";
import styles from "styles/styles";

type FlightSearchCalendarProps = {
    isCalendarLoading: boolean,
    fetchedCalendarWeeks: any[],
    erroredCalendarWeeks: any[],
    calendarOptions: any[],
    searchOptions: SearchOptions,
    updateSearchOption: (key: keyof SearchOptions, value: any) => void,
    fetchCalendar: (arg: any, arg2: boolean) => void,
    selectedItinerary?: any,
}

const FlightSearchCalendar = (props: FlightSearchCalendarProps) => {
    const dispatch = useDispatch()

    // Redux state
    const isMobile = useSelector((state: any) => state.global.isMobile)
    const flightSearch = useSelector((state: any) => state.flightSearch)

    // Component state
    const [calendarDisplayMonth, setCalendarDisplayMonth] = useState(moment(props.searchOptions.departureDate?.format('YYYY-MM-DD')).startOf('month').format('YYYY-MM-DD'))
    const [calendarDictionary, setCalendarDictionary] = useState<any>({})
    const [validationErrorMessage, setValidationErrorMessage] = useState<string | undefined>(undefined)

    // For now we don't refire when selecting the return date for round trips
    const hasNotSelectedFullItinerary = props.searchOptions.tripType === TripType.RoundTrip ? !props.selectedItinerary?.length : !props.selectedItinerary
    const formatter = Intl.NumberFormat('en', { notation: 'compact' });

    const incrementDisplayMonth = () => {
        setCalendarDisplayMonth(moment(calendarDisplayMonth).add(1, 'month').format('YYYY-MM-DD'))
    }

    const decrementDisplayMonth = () => {
        setCalendarDisplayMonth(moment(calendarDisplayMonth).subtract(1, 'month').format('YYYY-MM-DD'))
    }

    const setDepartureDate = (date: Date) => {
        const { updateSearchOption } = props;
        updateSearchOption('departureDate', moment(date))
    }

    const setReturnDate = (date: Date) => {
        const { updateSearchOption } = props;
        updateSearchOption('returnDate', moment(date))
    }

    const setTravelClass = (ev: React.MouseEvent | React.ChangeEvent<HTMLSelectElement>) => {
        const { updateSearchOption } = props;
    
        let selectedValue: string;
        if (ev.type === 'click') {
            selectedValue = (ev.target as HTMLElement).textContent || '';
        } else if (ev.type === 'change') {
            selectedValue = (ev.target as HTMLSelectElement).value;
        } else {
            return;
        }
    
        switch (selectedValue) {
            case 'Business':
                updateSearchOption('travelClass', TravelClass.Business);
                break;
            case 'First':
                updateSearchOption('travelClass', TravelClass.FirstClass);
                break;
            case 'Premium Economy':
                updateSearchOption('travelClass', TravelClass.PremiumEconomy);
                break;
            case 'Economy':
            default:
                updateSearchOption('travelClass', TravelClass.Economy);
        }
    }

    const isSearchOptionsValid = () => {
        const { searchOptions } = props;

        // Dates are unselected
        if (!searchOptions.departureDate) {
            setValidationErrorMessage('Select a departure date.')
            return false
        }

        if (searchOptions.tripType === TripType.RoundTrip && !searchOptions.returnDate) {
            setValidationErrorMessage('Select a return date.')
            return false
        }

        // Destinations are unselected
        if (!searchOptions.departureAirport || !Object.keys(searchOptions.departureAirport).length) {
            setValidationErrorMessage('Select a departure airport.')
            return false;
        }

        if (!searchOptions.arrivalAirport || !Object.keys(searchOptions.arrivalAirport).length) {
            setValidationErrorMessage('Select an arrival airport.')
            return false;
        }

        setValidationErrorMessage(undefined)
        return true;
    }

    const getErrorMessage = () => {
        switch (flightSearch.nonFieldErrors?.[0]) {
            case "Taekus cannot sell tickets to countries sanctioned by the US government":
                return "Taekus cannot offer tickets for the route you've selected. Please review your options and try again."
            default:
                return validationErrorMessage || 'There was an error fetching calendar data. Please review your options and try again.'
        }
    }

    const getCalendarContent = () => {
        const { selectedItinerary } = props;
        const firstCalendarWeekNum = moment(calendarDisplayMonth).isoWeek()

        return Array(6).fill(0).map((week, weekIndex) => {
            const currentWeekNum = firstCalendarWeekNum + weekIndex
            const isErrorWeek = props.erroredCalendarWeeks.includes(currentWeekNum)

            return <DateRow key={weekIndex}>
                {Array(7).fill(0).map((day, dayIndex) => {
                    const dateMoment = moment().year(moment(calendarDisplayMonth).year()).startOf('year').week(currentWeekNum).startOf('week').add(dayIndex, 'days');
                    const isMainMonth = dateMoment.month() === moment(calendarDisplayMonth).month()
                    const dateKey = dateMoment.format('YYYY-MM-DD')
                    const isSelectedDate = dateKey === (props.searchOptions.tripType === TripType.RoundTrip && selectedItinerary?.length === 1 ? (props.searchOptions.returnDate?.format('YYYY-MM-DD')) : props.searchOptions.departureDate?.format('YYYY-MM-DD'))
                    const isSecondaryDate = props.searchOptions.tripType === TripType.RoundTrip && dateKey === (selectedItinerary?.length === 1 ? props.searchOptions.departureDate?.format('YYYY-MM-DD') : props.searchOptions.returnDate?.format('YYYY-MM-DD'))
                    const isDateInValidRange = dateMoment.isSameOrAfter(moment(), 'day') && dateMoment.isSameOrBefore(moment().add(336, 'days'), 'day') && 
                        (props.searchOptions.tripType !== TripType.RoundTrip || dateMoment.clone().add(props.searchOptions.returnDate?.diff(props.searchOptions.departureDate, 'days'), 'days').isSameOrBefore(moment().add(336, 'days'), 'day'))

                    const selectDate = () => {
                        if (isErrorWeek) {
                            props.fetchCalendar(buildItineraryPayload(props.searchOptions, props.selectedItinerary, [currentWeekNum]), false)
                        } else {
                            props.searchOptions.tripType === TripType.RoundTrip && selectedItinerary?.length === 1 ? setReturnDate(dateMoment.toDate()) : setDepartureDate(dateMoment.toDate())
                        }
                    }

                    /*
                    *  Currently there is no implemented cancel function for API requests. 
                    *  This means we cannot tell if the current calendar dictionary represents 
                    *  the most recent set of search options unless we cache the search options 
                    *  at the time of the api request and compare it to the current state options.
                    */
                    return <GridItem 
                        key={`${dayIndex}`}
                        ismainmonth={isMainMonth}
                        onClick={selectDate}
                    >
                        {isDateInValidRange && props.isCalendarLoading && !(calendarDictionary[dateKey] || isErrorWeek) ? <Shimmer /> : <GridItemContent
                            {...fadeInOutMotionProps}
                            ismainmonth={isMainMonth}
                            hasAwardTicket={calendarDictionary[dateKey]?.hasAwardTicket}
                            selected={isSelectedDate}
                            ishoverable={isDateInValidRange}
                        >
                            <div className="w-100 h-100">
                                <div className="d-flex justify-content-center">
                                    <GridDate 
                                        isSecondaryDate={isSecondaryDate}
                                        isDateInValidRange={isDateInValidRange}
                                    >
                                        {dateMoment.date()}
                                    </GridDate>
                                </div>
                                <GridPoints>
                                    {isDateInValidRange && (calendarDictionary[dateKey] ? `${formatter.format(calendarDictionary[dateKey].price * 100)}` : (isErrorWeek ? <StyledReload selected={isSelectedDate}/> : '–'))}
                                </GridPoints>
                                {/*
                                    The dollar/tax component of fares should not be displayed on the calendar
                                    unless the lowest fare is an award ticket (and right now they never are, 
                                    so dollar amounts should never be on the calendar).
                                */}
                                {/* <GridPrice>{calendarDictionary[dateKey] && `$${Math.round(calendarDictionary[dateKey])}`}</GridPrice> */}
                                {/* {calendarDictionary[dateKey]?.hasAwardTicket && <AwardCalendarIndicator selected={isSelectedDate}/>} */}
                            </div>
                        </GridItemContent>}
                    </GridItem>
                })}
            </DateRow>
        })
    }

    const diffInDays = props.searchOptions.returnDate?.diff(props.searchOptions.departureDate, 'days')
    // Add calendar options to component cache whenever they update
    useEffect(() => {
        const copyOfCalendarDictionary = { ...calendarDictionary }
        for (const day of props.calendarOptions) {
            const existingDay = copyOfCalendarDictionary[day.date]
            if (existingDay) {
                const containsAward = day.hasAwardTicket || existingDay.hasAwardTicket
                const minPrice = Math.min(day.price, existingDay.price)
                copyOfCalendarDictionary[day.date] = {
                    hasAwardTicket: containsAward,
                    price: minPrice,
                    date: existingDay.date
                }
            } else {
                copyOfCalendarDictionary[day.date] = day
            }
        }
        setCalendarDictionary(copyOfCalendarDictionary)
    }, [ props.calendarOptions ]) // eslint-disable-line

    // clear calendar cache when
    // - display month changes
    // - is round trip changes
    // - passenger count changes
    // - number of stops changes
    // - ticket type changes
    // - selected airlines changes
    // - destination / arrival location changes
    useEffect(() => {
        if (hasNotSelectedFullItinerary && isSearchOptionsValid()) {
            dispatch(FlightSearchActions.clearSearchOptions())
            setCalendarDictionary({})
            if (isSearchOptionsValid()) {
                const firstWeekNum = moment(calendarDisplayMonth).clone().startOf('month').isoWeek()
                const weeks = Array(6).fill(0).map((value, index) => firstWeekNum + index)
                props.fetchCalendar(buildItineraryPayload(props.searchOptions, props.selectedItinerary, weeks), true)
            } else {
                dispatch(FlightSearchActions.cancelCalendarFetches())
            }
        }
        // eslint-disable-next-line
    }, [
        diffInDays,
        props.searchOptions.departureAirport,
        props.searchOptions.arrivalAirport,
        props.searchOptions.tripType,
        props.searchOptions.numberOfPassengers,
        props.searchOptions.ticketType,
        props.searchOptions.maxNumberOfStops,
        props.searchOptions.airlineOption,
        props.searchOptions.travelClass,
        props.searchOptions.isRefundable,
        props.searchOptions.excludeNoCarryOn,
        props.searchOptions.connectingAirport,
        dispatch
    ]) 

    // If not the departure date is updated and not in currently viewed month,
    // change calendar month to fit this date
    useEffect(() => {
        if (props.searchOptions.departureDate) {
            const weekNum = moment(calendarDisplayMonth).isoWeek()
            const beginningOfMonth = moment().week(weekNum).startOf('week')
            const endOfMonth = moment().week(weekNum + 5).endOf('week')

            if (!(props.searchOptions.departureDate.isAfter(beginningOfMonth) && props.searchOptions.departureDate.isBefore(endOfMonth))) {
                setCalendarDisplayMonth(props.searchOptions.departureDate.clone().startOf('month').format('YYYY-MM-DD'))
            }
        }
    }, [props.searchOptions.departureDate]) // eslint-disable-line

    // Fetch new calendar dates and merge them into the existing results
    useEffect(() => {
        if (hasNotSelectedFullItinerary && isSearchOptionsValid()) {
            const firstWeekNum = moment(calendarDisplayMonth).clone().startOf('month').isoWeek()
            const weeks = Array(6).fill(0).map((value, index) => firstWeekNum + index).filter(week => props.fetchedCalendarWeeks.indexOf(week) === -1)

            // Check if we have any attempted to fetch any weeks at this point before merging in more results
            // (this will prevent this effect from firing a second calendar endpoint on app load)
            // and check that we haven't filtered out relevant weeks before fetching
            if (props.fetchedCalendarWeeks.length && weeks.length) {
                props.fetchCalendar(buildItineraryPayload(props.searchOptions, props.selectedItinerary, weeks), false)
            }
        }
    }, [dispatch, calendarDisplayMonth]) // eslint-disable-line

    const { searchOptions, selectedItinerary } = props;

    return <CalendarContainer>
        <CalendarHeader>
            <ArrowContainer onClick={decrementDisplayMonth}>
                {moment(calendarDisplayMonth).isAfter(moment(), 'month') && <ArrowLeftSVG/>}
            </ArrowContainer>
            <div style={{whiteSpace: 'nowrap'}}>
                {moment(calendarDisplayMonth).format('MMM YYYY')}
            </div>
            <ArrowContainer onClick={incrementDisplayMonth}>
                {moment(calendarDisplayMonth).isBefore(moment().add(335, 'day'), 'month') && <ArrowRightSVG/>}
            </ArrowContainer>
        </CalendarHeader>
        <ClassOptionsContainer>
            {isMobile ? <>
                <div>Class:</div>
                <div className='position-relative d-flex align-items-center'>
                <MobileClassSelect onChange={(e) => setTravelClass(e as unknown as React.MouseEvent)}>
                    <option value="Economy" selected={searchOptions.travelClass === TravelClass.Economy}>Economy</option>
                    <option value="Premium Economy" selected={searchOptions.travelClass === TravelClass.PremiumEconomy}>Premium Economy</option>
                    <option value="Business" selected={searchOptions.travelClass === TravelClass.Business}>Business</option>
                    <option value="First" selected={searchOptions.travelClass === TravelClass.FirstClass}>First</option>
                </MobileClassSelect>
                </div>
            </> : <>
                <TicketClassItem onClick={setTravelClass} selected={searchOptions.travelClass === TravelClass.Economy}>
                    Economy
                </TicketClassItem>
                <TicketClassItem onClick={setTravelClass} selected={searchOptions.travelClass === TravelClass.PremiumEconomy}>
                    Premium Economy
                </TicketClassItem>
                <TicketClassItem onClick={setTravelClass} selected={searchOptions.travelClass === TravelClass.Business}>
                    Business
                </TicketClassItem>
                <TicketClassItem onClick={setTravelClass} selected={searchOptions.travelClass === TravelClass.FirstClass}>
                    First
                </TicketClassItem>
            </>}
        </ClassOptionsContainer>
        <CalendarTable>
            <AnimatePresence>
                {!props.isCalendarLoading && (validationErrorMessage) ? <EmptyCalendarContainer>
                    <ItineraryError>{getErrorMessage()}</ItineraryError> 
                </EmptyCalendarContainer> : <>
                    <motion.tbody {...fadeInOutMotionProps}>
                        <HeaderRow>
                            {['S','M','T','W','T','F','S'].map((dayLabel: string, dayIndex: number) => <HeaderGridItem key={dayIndex}>
                                <HeaderGridItemContent>{dayLabel}</HeaderGridItemContent>
                            </HeaderGridItem>)}
                        </HeaderRow>
                        {getCalendarContent()}
                    </motion.tbody>
                    {!props.isCalendarLoading && searchOptions.tripType === TripType.RoundTrip && selectedItinerary?.length === 1 && <BlurredOverlay>
                        Please select your return flight.
                    </BlurredOverlay>}
                </>}
            </AnimatePresence>
        </CalendarTable>
    </CalendarContainer>
}

type StyledReloadProps = {
    selected: boolean
}

const StyledReload = styled(Reload)<StyledReloadProps>`
    width: 12px;
    height: 12px;
    fill: ${props => props.selected ? styles.Color.White : styles.Color.TaekusPurple}
`

const BlurredOverlay = styled(motion.div)`
    position: absolute;
    backdrop-filter: blur(5px);
    background-color: rgba(255,255,255,0.4);
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

const MobileClassSelect = styled.select`
    user-select: none;
    background-color: transparent;
    border: 0;
    color: ${styles.Color.TaekusPurple};
    appearance: none;
    margin: 0 ${styles.Spacing.XS};
    width: 180px;
    font-weight: bold;
`

const GridPoints = styled.div`
    ${styles.MediaQueries.Mobile} {
        font-size: ${styles.Font.Size.Small};
    }
    ${styles.MediaQueries.Desktop} {
        font-size: 24px;
        margin-top: ${styles.Spacing.XS};
    }
`

type GridDateProps = {
    isDateInValidRange: boolean;
    isSecondaryDate: boolean;
}

const GridDate = styled.div<GridDateProps>`
    ${styles.MediaQueries.Desktop} {
        font-size: ${styles.Font.Size.Small};
    }
    ${styles.MediaQueries.Mobile} {
        font-size: 10px;
    }
    user-select: none;
    height: 28px;
    border-bottom: 1px solid transparent;
    transition: border 0.2s ease-in;
    ${props => !props.isDateInValidRange && `opacity: 0.5;`}
    ${props => props.isSecondaryDate && `
        color: ${styles.Color.TaekusPurple};
        border-bottom: 1px solid ${styles.Color.TaekusPurple};
    `}
`

const Shimmer = styled.div`
    width: 100%;
    height: 100%;
    visibility: hidden;
`

type ItineraryErrorProps = {
}

const ItineraryError = styled.div<ItineraryErrorProps>`
    width: 100%;
    margin: ${styles.Spacing.S} 0;
    text-align: center;
    color: black;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    padding: 0 ${styles.Spacing.S};
`

const EmptyCalendarContainer = styled(motion.div)`
    height: ${(123 * 6) + 60}px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const CalendarTable = styled.table`
    width: 100%;
    position: relative;
`

type TicketClassItemProps = {
    selected: boolean
}

const TicketClassItem = styled.div<TicketClassItemProps>`
    cursor: pointer;
    padding: 2px 8px;
    border-bottom: 1px solid transparent;
    ${props => props.selected && `
        color: ${styles.Color.TaekusPurple};
        border-bottom: 1px solid ${styles.Color.TaekusPurple};
    `}
    &:hover {
        color: ${styles.Color.TaekusPurple};
        border-bottom: 1px solid ${styles.Color.TaekusPurple};
    }
    ${styles.Animation.transitionStyles}
`

const HeaderRow = styled.tr`
    height: 56px;
    border-bottom: 1px solid #DFDFDF;
    margin: 0;
`

const DateRow = styled.tr`
    border-bottom: 1px solid ${styles.Color.GreyText};
    background-color: #999999;
    background: linear-gradient(to right,
         ${styles.Color.Grey} 4%,
         #f5f5f5 25%,
         ${styles.Color.Grey} 50%);
    
    ${styles.MediaQueries.Desktop} {
        animation : shimmer 2s infinite;
        background-size: 100% 100%;
    }
    ${styles.MediaQueries.Mobile} {
        animation : mobileShimmer 2s infinite;
        background-size: 500% 100%;
    }
    
    @keyframes shimmer {
        0% {
            background-position: 0vw 0;
        }
        100% {
            background-position: 50vw 0;
        }
    }
    
    @keyframes mobileShimmer {
        0% {
            background-position: 0% 0;
        }
        100% {
            background-position: -120% 0;
        }
    }

    ${styles.MediaQueries.Desktop} {
        height: 123px;
    }
    ${styles.MediaQueries.Mobile} {
        height: 60px;
    }
`

const HeaderGridItemContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${styles.Spacing.L};
`

type GridItemContentProps = {
    ishoverable: boolean;
    selected: boolean;
    ismainmonth: boolean;
    hasAwardTicket: boolean;
}

const GridItemContent = styled(motion.div)<GridItemContentProps>`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    height: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 132%;
    text-align: center;
    letter-spacing: 0.01em;
    background-color: rgb(253, 251, 247);
    ${styles.Animation.transitionStyles}
    ${props => props.selected && `
        background-color: ${styles.Color.TaekusPurple} !important;
        color: white;
    `}
    ${props => !props.ismainmonth && `
        background-color: rgb(242,242,242);
        box-shadow: inset 0 0 10px rgba(0,0,0,0.05);
    `}
    ${props => props.hasAwardTicket && `
        background-color: #E8CFE5;
    `}
    ${props => props.ishoverable && `
        cursor: pointer;
        &:hover {
            background-color: ${styles.Color.Grey};
        }
    `}
`

const HeaderGridItem = styled.th`
    width: ${1/7 * 100}%;
    height: 100%;
`

type GridItemProps = {
    ismainmonth: boolean
}

const GridItem = styled.th<GridItemProps>`
    ${props => !props.ismainmonth && `
        background-color: rgba(0,0,0,0.05);
        box-shadow: inset 0 0 10px rgba(0,0,0,0.05);
    `}
    width: ${1/7 * 100}%;
    overflow: hidden;
    margin: 0;
    user-select: none;
    padding: 0;
    ${styles.Animation.transitionStyles}
    &:not(&:last-child) {
        border-right: 1px solid ${styles.Color.GreyText};
    }
    ${styles.MediaQueries.Desktop} {
        height: 123px;
    }
    ${styles.MediaQueries.Mobile} {
        height: 60px;
    }
`

const ClassOptionsContainer = styled.div`
    display: flex;
    height: ${styles.Spacing.L};
    width: 100%;
    border-bottom: 1px solid #DFDFDF;
    align-items: center;
    ${styles.MediaQueries.Mobile} {
        padding: 0 ${styles.Spacing.XS};
    }
    ${styles.MediaQueries.Desktop} {
        justify-content: space-around;
    }
`

const CalendarHeader = styled.div`
    display: flex;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    height: 100%;
    width: 100%;
    height: 80px;
    align-items: center;
    font-size: ${styles.Font.Size.Medium};
    border-bottom: 1px dotted ${styles.Color.Grey};
    user-select: none;
    ${styles.MediaQueries.Mobile} {
        justify-content: space-around;
    }
    ${styles.MediaQueries.Desktop} {
        justify-content: space-between;
    }
`

const ArrowContainer = styled.div`
    width: 16px;
    cursor: pointer;
    ${styles.MediaQueries.Desktop} {
        margin: 0 100px;
    }
`

const CalendarContainer = styled.div`
    flex: 1;
    max-height: 941px;
    background-color: #FDFBF7;
    border-right: 1px solid ${styles.Color.Grey};
`

export default FlightSearchCalendar