import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import { geocodeByPlaceId } from "react-google-places-autocomplete";

import { AnimatePresence, motion } from "framer-motion";
import { styled } from "styled-components"

import { useIsFirstRender } from "hooks/useIsFirstRender";
import { parseGoogleAddress } from "components/common/util";

import { SignupStep } from "components/signup/constants";
import { EmailError, HousingError, StringError } from "components/signup/errors";
import messages from "components/signup/messages";
import { updateApplication, updateLead, updateStep } from "components/signup/signupSlice";
import { ProductType, HousingType, StepDirection } from "components/signup/types";
import { formatAsUSD } from "components/signup/utils";
import {
    validateEmail,
    validateString,
    validateSSN,
    validateIncome,
    validateHousing,
    validateAddress
} from "components/signup/validators";

import AddressInput from "components/signup/components/AddressInput";
import ArrowButton, { ArrowDirection } from "components/signup/components/ArrowButton";
import Button from "components/signup/components/Button";
import CurrencyInput from "components/signup/components/CurrencyInput";
import Input from "components/signup/components/Input";
import Selectable from "components/signup/components/Selectable";
import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";
import SSNInput from "components/signup/components/SSNInput";

import { expandVerticallyMotionProps } from "styles/motionConstants";
import styles from "styles/styles";

const inputFadeInOutMotionProps = {
    initial:{ height: 0, opacity: 0 },
    exit:{ height: 0, opacity: 0 },
    animate:{ height: 'auto', opacity: 1 },
}

const EditInfo = () => {
    const dispatch = useDispatch()
    const isFirstRender = useIsFirstRender()

    const application = useSelector((state: any) => state.signup.application)
    const patchFailed = useSelector((state: any) => state.signup.patchFailed)
    const stepDirection = useSelector((state: any) => state.signup.direction)
    const prevStep = useSelector((state: any) => state.signup.prevStep)
    const leadUuid = useSelector((state: any) => state.signup.leadUuid)
    const isLoading = useSelector((state: any) => state.signup.isLoading)
    const products = useSelector((state: any) => state.signup.products)

    const [showErrors, setShowErrors] = useState(false)
    const [errors, setErrors] = useState<any>({})
    const [firstName, setFirstName] = useState(application.firstName || '')
    const [lastName, setLastName] = useState(application.lastName || '')
    const [ssn, setSSN] = useState(application.ssn || application.ssnLastFour || '')
    const [showLastFour, setShowLastFour] = useState(!application.ssn && application.ssnLastFour)
    const [address, setAddress] = useState<any>(application.addressStreet ? {
        label: `${application.addressStreet}, ${application.addressCity}, ${application.addressState}`,
        value: undefined,
    } : undefined)
    const [addressLine2, setAddressLine2] = useState(application.addressLineTwo || '')
    const [email, setEmail] = useState(application.email || '')
    const [income, setIncome] = useState(Number(application.totalAnnualIncome) ? Number(application.totalAnnualIncome)?.toLocaleString() : '')
    const [housingType, setHousingType] = useState<HousingType | undefined>(application.residenceType || undefined)
    const [housingPayment, setHousingPayment] = useState(Number(application.monthlyHousingPayment) ? Number(application.monthlyHousingPayment)?.toLocaleString() : '')

    const selectedProduct = products?.find((product: any) => product.productCode === application.productCode)
    // Use state variables for the following values so they don't change during the unmount animation
    const [showEmail, setShowEmail] = useState(prevStep === SignupStep.FinalReview) // eslint-disable-line
    const [showCreditFields, setShowCreditFields] = useState(prevStep === SignupStep.FinalReview && selectedProduct?.productType === ProductType.Credit) // eslint-disable-line

    const handleBack = (ev: React.MouseEvent) => {
        dispatch(updateStep({ step: prevStep, direction: StepDirection.Up }))
    }

    const validateApplication = () => {
        // Basic fields
        const firstNameError = validateString(firstName)
        const lastNameError = validateString(lastName)
        const ssnError = !showLastFour && validateSSN(ssn)
        const addressError = validateAddress(address)
        const addressLine2Error = addressLine2.length > 40 ? StringError.TooLong : undefined
        const emailError = validateEmail(email)

        // Credit fields
        const incomeError = validateIncome(income)
        const housingError = validateHousing(housingType, housingPayment)

        const isBasicInfoInvalid = !![firstNameError, lastNameError, ssnError, addressError, addressLine2Error].some(error => error !== false && error !== undefined)
        const isEmailInvalid = showEmail && emailError !== undefined
        const isCreditInfoInvalid = showCreditFields && !![incomeError, housingError].some(error => error !== undefined)
 
        setErrors({
            // basic fields
            firstName: firstNameError,
            lastName: lastNameError,
            ssn: ssnError,
            address: addressError,
            address2: addressLine2Error,
            email: emailError,

            // credit fields
            income: incomeError,
            housing: housingError,
        })
        return !(isBasicInfoInvalid || isEmailInvalid || isCreditInfoInvalid)
    }

    const handleSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();

        const isInputValid = validateApplication()

        if (isInputValid) {
            // Geocode personal address
            const parsedAddress = await geocodeByPlaceId(address?.value?.place_id)
                .then((gAddress) => parseGoogleAddress(gAddress))
                .catch(() => undefined)

            const applicationChanges = {
                firstName,
                lastName,
                ssn: showLastFour ? undefined : ssn,
                addressStreet: parsedAddress?.street || application.addressStreet,
                addressCity: parsedAddress?.city || application.addressCity,
                addressState: parsedAddress?.state || application.addressState,
                addressZip: parsedAddress?.zip || application.addressZip,
                addressLineTwo: addressLine2,
                email: showEmail ? email : undefined,

                totalAnnualIncome: income.replaceAll(',',''),
                residenceType: housingType,
                monthlyHousingPayment: housingType === HousingType.Rent ? housingPayment.replaceAll(',','') : undefined,
            }

            dispatch(updateApplication(applicationChanges))
            dispatch(updateLead({ leadUuid, application: applicationChanges }))
        } else {
            setShowErrors(true)
        }
    }

    // onChange handlers

    const handleFirstNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.value.length > 40) return;

        setFirstName(ev.target.value)
        setErrors({ ...errors, firstName: validateString(ev.target.value) })
    }

    const handleLastNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.value.length > 40) return;
        
        setLastName(ev.target.value)
        setErrors({ ...errors, lastName: validateString(ev.target.value) })
    }

    const handleSsnChange = (value: string) => {
        if (showLastFour) {
            setShowLastFour(false)
            setSSN('')
            setErrors({ ...errors, ssn: validateSSN('') })
        } else {
            setSSN(value)
            setErrors({ ...errors, ssn: validateSSN(value) })
        }
    }

    const handleAddressChange = (value: any) => {
        setAddress(value)
    }

    const handleAddress2Change = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.value.length > 40) return;
        
        setAddressLine2(ev.target.value)
    }

    const handleEmailChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.value.length > 60) return;

        setEmail(ev.target.value)
        setErrors({ ...errors, email: validateEmail(ev.target.value) })
    }

    const handleIncomeChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (String(Number(ev.target.value.replaceAll(',',''))).length <= 9) {
            const inputValue = ev.target.value;
            const formattedValue = formatAsUSD(inputValue);
            setIncome(formattedValue);
            setErrors({ ...errors, income: validateIncome(formattedValue) })
        }
    }

    const updateHousingType = (ev: React.MouseEvent<HTMLButtonElement>) => {
        setHousingType((ev.target as any).id)
        setErrors({ ...errors, housing: validateHousing((ev.target as any).id, housingPayment) })
    }

    const handleHousingPaymentChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (String(Number(ev.target.value.replaceAll(',',''))).length <= 9) {
            const inputValue = ev.target.value;
            const formattedValue = formatAsUSD(inputValue);
            setHousingPayment(formattedValue);
        }
    }

    // Go back to review page when API is completed
    useEffect(() => {
        const isInputValid = validateApplication()
        if (patchFailed) {
            setErrors({ ...errors, email: EmailError.AlreadyExists })
            setShowErrors(true)
        } else if (!isFirstRender && isLoading === false && isInputValid) {
            dispatch(updateStep({ step: prevStep, direction: StepDirection.Up, prevStep: SignupStep.EditInfo }))
        }
    }, [isLoading]) // eslint-disable-line

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='EditInfo'
    >
        <Form onSubmit={handleSubmit}>
            <Title>Edit your info.</Title>
            <InputListContainer>
                <InputContainer>
                    <Input 
                        errorMessage={showErrors && messages.Errors.FirstName[errors.firstName]} 
                        onChange={handleFirstNameChange} 
                        value={firstName}
                        label="First Name"
                    />
                </InputContainer>
                <InputContainer>
                    <Input 
                        errorMessage={showErrors && messages.Errors.LastName[errors.lastName]} 
                        onChange={handleLastNameChange}  
                        value={lastName} 
                        label="Last Name"
                    />
                </InputContainer>
                <InputContainer>
                    <AddressInput
                        errorMessage={showErrors && messages.Errors.Address[errors.address]}
                        onChange={handleAddressChange}
                        value={address}
                    />
                </InputContainer>
                <InputContainer>
                    <Input
                        autocomplete='nope'
                        errorMessage={showErrors && messages.Errors.Address2[errors.address2]} 
                        onChange={handleAddress2Change}
                        value={addressLine2}
                        label="Address Line 2*"
                    />
                </InputContainer>
                <InputContainer>
                    <SSNInput 
                        errorMessage={showErrors && messages.Errors.SSN[errors.ssn]}
                        lastFour={showLastFour}
                        value={ssn}
                        onChange={handleSsnChange}
                    />
                </InputContainer>
                {showEmail && <InputContainer>
                    <Input
                        errorMessage={showErrors && messages.Errors.Email[errors.email]}
                        onChange={handleEmailChange} 
                        value={email}
                        label="Email"
                    />
                </InputContainer>}
                {showCreditFields && <InputContainer>
                    <CurrencyInput 
                        errorMessage={showErrors && messages.Errors.Income[errors.income]} 
                        value={income} 
                        onChange={handleIncomeChange} 
                        label="Annual Income"
                    />
                </InputContainer>}
                {showCreditFields && <InputContainer>
                    <div style={{display: 'flex', maxWidth: '308px'}}>
                        <Selectable
                            id={HousingType.Own}
                            onClick={updateHousingType}
                            selected={housingType === HousingType.Own}
                            label='Own'
                        />
                        <Selectable
                            id={HousingType.Rent} 
                            onClick={updateHousingType} 
                            selected={housingType === HousingType.Rent} 
                            label='Rent'
                        />
                    </div>
                    <AnimatePresence mode='wait'>
                        {(showErrors && errors.housing === HousingError.Required) && <motion.div style={{minWidth: '100%', width: 'min-content'}} {...expandVerticallyMotionProps}>
                            <ErrorMessage>{messages.Errors.Housing[errors.housing]}</ErrorMessage>
                        </motion.div>}
                    </AnimatePresence>
                </InputContainer>}
                <AnimatePresence mode='wait'>
                    {(showCreditFields && housingType === HousingType.Rent) && <InputContainer {...inputFadeInOutMotionProps}>
                        <CurrencyInput errorMessage={showErrors && messages.Errors.Housing[errors.housing]} value={housingPayment} onChange={handleHousingPaymentChange} label="Monthly housing payment"/>
                    </InputContainer>}
                </AnimatePresence>
            </InputListContainer>
            <ButtonContainer>
                <BackButtonContainer>
                    <ArrowButton onClick={handleBack} direction={ArrowDirection.Left}/>
                </BackButtonContainer>
                <Button isLoading={isLoading} type="submit">Next</Button>
            </ButtonContainer>
        </Form>
    </StepContainer>
}

const InputListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 12px 16px;
    width: 672px;
    padding: 0 20px;
    ${styles.MediaQueries.Mobile} {
        width: 100%;
        max-width: 100%;
        flex-direction: column;
    }
`

const ErrorMessage = styled.div`
    color: ${styles.Color.TaekusRed};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 14.88px */
    letter-spacing: 0.24px;
    padding: 8px 16px 0;
`

const BackButtonContainer = styled.div`
    margin-right: 16px;
`

const ButtonContainer = styled.div`
    margin-top: 32px;
    display: flex;
`

const InputContainer = styled(motion.div)`
    flex-basis: calc(50% - 8px);
    height: min-content;
    margin: 0;
    ${styles.MediaQueries.Mobile} {
        flex-basis: 0;
    }
`

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: calc(100dvh - 120px);
    padding: 10px;
    width: min-content;
    ${styles.MediaQueries.Mobile} {
        max-height: unset;
        min-height: min-content;
        max-width: 100%;
    }
    ${styles.Scrollbar.defaultScrollbarStyles}
`

const Title = styled.div`
    color: ${styles.Color.TaekusGrey1};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-bottom: 16px;
    ${styles.MediaQueries.Mobile} {
        font-size: 24px;
        line-height: 124%; /* 29.76px */
        letter-spacing: 0.24px;
    }
`

export default EditInfo