import moment from "moment"

import { validateString } from "utils/utils"

import { SearchOptions, TripType } from "components/pages/Rewards/constants"
import { PassengerValidationErrorLabels } from "components/pages/Rewards/Flights/constants"
import { TravelerDetails, TravelerDetailsErrors } from "../types"

export const validateDOB = (dateOfBirth?: string) => {
    if (!validateString(dateOfBirth)) {
        return PassengerValidationErrorLabels.DateOfBirth.MissingValue
    }
    if (moment(dateOfBirth).isBefore(moment().subtract(110, 'years'))) {
        return PassengerValidationErrorLabels.DateOfBirth.Exceeds110
    }
    if (moment(dateOfBirth).isSameOrAfter(moment().startOf('day'))) {
        return PassengerValidationErrorLabels.DateOfBirth.FutureDate
    }
    return undefined;
}

export const validatePassportExpDate = (expDate?: string) => {
    if (!validateString(expDate)) {
        return PassengerValidationErrorLabels.Passport.ExpDate.MissingValue
    }
    if (moment(expDate).isSameOrBefore(moment().startOf('day'))) {
        return PassengerValidationErrorLabels.Passport.ExpDate.Expired
    }
    if (moment(expDate).isAfter(moment().add(6, 'months').add(10, 'years').startOf('day'))) {
        return PassengerValidationErrorLabels.Passport.ExpDate.TooFarAway
    }

    return undefined
}

export const validateKTN = (ktn?: string, ktCountry?: string) => {
    // If ktCountry is provided, KTN is required
    if (!!ktCountry && !ktn) {
        return PassengerValidationErrorLabels.KnownTravellerNumber.MissingValue
    }
    // If KTN is empty/undefined, no error since KTN is optional
    if (!validateString(ktn)) {
        return undefined
    }
    if (ktn?.length !== 9 && ktn?.length !== 10) {
        return PassengerValidationErrorLabels.KnownTravellerNumber.CharacterLength
    }
    return undefined;
}

export const validatePassportNumber = (passportNo?: string) => {
    if (!validateString(passportNo)) {
        return PassengerValidationErrorLabels.Passport.Number.MissingValue
    }
    if ((passportNo?.length || 0) > 9) {
        return PassengerValidationErrorLabels.Passport.Number.TooLong
    }
    return undefined
}

export const isPassengerDetailsValid = (passengerDetails: TravelerDetails, isDomesticItinerary: boolean, isPassportImageRequired: boolean, citizenshipCountryRequired: boolean = false, residenceCountryRequired: boolean = false) => {
    const updatedErrors: TravelerDetailsErrors = {
        firstName: validateString(passengerDetails.firstName) ? undefined : PassengerValidationErrorLabels.FirstName.MissingValue,
        lastName: validateString(passengerDetails.lastName) ? undefined : PassengerValidationErrorLabels.LastName.MissingValue,
        dateOfBirth: validateDOB(passengerDetails.dateOfBirth),
        gender: validateString(passengerDetails.gender) ? undefined : PassengerValidationErrorLabels.Gender.MissingValue,
        knownTravellerNumber: validateKTN(passengerDetails.knownTravellerNumber, passengerDetails.knownTravellerCountry),
        knownTravellerCountry: !passengerDetails.knownTravellerNumber || validateString(passengerDetails.knownTravellerCountry) ? undefined : PassengerValidationErrorLabels.KnownTravellerCountry.MissingValue,
        passportNumber: isDomesticItinerary ? undefined : validatePassportNumber(passengerDetails.passportNumber),
        passportCountry: (isDomesticItinerary || validateString(passengerDetails.passportCountry)) ? undefined : PassengerValidationErrorLabels.Passport.Country.MissingValue,
        passportExpDate: isDomesticItinerary ? undefined : validatePassportExpDate(passengerDetails.passportExpDate),
        passportImage: !isPassportImageRequired || !!passengerDetails.passportImage ? undefined : PassengerValidationErrorLabels.Passport.Image.Missing,
        citizenshipCountry: (!citizenshipCountryRequired || isDomesticItinerary || validateString(passengerDetails.citizenshipCountry)) ? undefined : PassengerValidationErrorLabels.CitizenshipCountry.MissingValue,
        residenceCountry: (!residenceCountryRequired || isDomesticItinerary || validateString(passengerDetails.residenceCountry)) ? undefined : PassengerValidationErrorLabels.ResidenceCountry.MissingValue,
    }
    return !Object.values(updatedErrors).some(error => error !== undefined)
}

export const buildPriceDetailPayload = (searchOptions: SearchOptions, outboundItinerary: any, inboundItinerary: any, sessionId: any) => (
    {
    activeFlight: searchOptions.tripType === TripType.RoundTrip && outboundItinerary?.length ? 'inbound' : 'outbound',
    airlines: searchOptions.airlineOption ? {
        value: searchOptions.airlineOption
    } : [],
    connectingAirport: null,
    currentTripType: {
        value: searchOptions.tripType
    },
    departureDate: searchOptions.departureDate?.format('YYYY-MM-DD'),
    destination: {
        value: searchOptions.arrivalAirport?.value,
    },
    excludeNoCarryOn: {
        value: false
    },
    inboundItinerary: inboundItinerary,
    isRefundable: {
        value: searchOptions.isRefundable === 'true'
    },
    maxStops: {
        value: searchOptions.maxNumberOfStops,
    },
    numPax: {
        value: searchOptions.numberOfPassengers,
    },
    origin: {
        value: searchOptions.departureAirport?.value,
    },
    outboundItinerary: outboundItinerary,
    resetSearch: false,
    returnDate: searchOptions.tripType === TripType.RoundTrip ? searchOptions.returnDate?.format('YYYY-MM-DD') : searchOptions.departureDate?.format('YYYY-MM-DD'),
    sessionId: sessionId,
    ticketType: {
        value: searchOptions.ticketType
    },
    travelClass: {
        value: searchOptions.travelClass
    },
    weeksToFetch: []
})