import React, { useEffect, useRef, useState } from "react"

import { AnimatePresence, motion } from "framer-motion"
import { styled } from "styled-components"

import { expandVerticallyMotionProps, fadeInOutMotionProps } from "styles/motionConstants"
import styles from "styles/styles"

import { ReactComponent as CaretDown } from "assets/svg/CaretDown.svg";

interface SelectProps extends React.InputHTMLAttributes<HTMLInputElement> {
    parentRef?: any,
    selected?: any,
    label?: string,
    showsError?: boolean,
    onSelect?: (option: any) => void;
    onFocus?: (ev: React.FocusEvent<HTMLInputElement>) => void,
    onBlur?: (ev: React.FocusEvent<HTMLInputElement>) => void,
    errorMessage?: string,
    name?: string,
    type?: string,
    options: any[],
}

const Select = (props: SelectProps) => {

    const inputRef = useRef(null)
    const dropdownRef = useRef(null)
    const hoveredOptionRef = useRef(null)

    const [search, setSearch] = useState('')
    const [isInputFocused, setIsInputFocused] = useState(false)
    const [hoveredOptionIndex, setHoveredOptionIndex] = useState<number | undefined>(undefined)
    const [isKeyboardNavDebounced, setIsKeyboardNavDebounced] = useState(false)

    const filteredOptions = props.options.filter(option => option.label.toLowerCase().includes(search.toLowerCase()))

    const isLabelMinified = props.selected || isInputFocused

    const handleDropdownClick = (ev: any) => {
        ev.stopPropagation()
        ev.preventDefault()
    }

    const handleKeydown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if (ev.key === 'Enter' && isInputFocused) {
            props.onSelect && props.onSelect(filteredOptions[hoveredOptionIndex || 0]);
            (props.parentRef || inputRef)?.current?.blur();
        } else if (!isKeyboardNavDebounced) {
            if (ev.key === 'ArrowDown') {
                const incrementedIndex = hoveredOptionIndex !== undefined ? (hoveredOptionIndex + 1) : 0
                const wrappedIndex = incrementedIndex >= filteredOptions.length ? 0 : incrementedIndex
                setHoveredOptionIndex(wrappedIndex);
                setIsKeyboardNavDebounced(true)
                setTimeout(() => { setIsKeyboardNavDebounced(false) }, 100)
            } else if (ev.key === 'ArrowUp') {
                const decrementedIndex = hoveredOptionIndex !== undefined ? (hoveredOptionIndex - 1) : filteredOptions.length - 1
                const wrappedIndex = decrementedIndex < 0  ? filteredOptions.length - 1 : decrementedIndex
                setHoveredOptionIndex(wrappedIndex);
                setIsKeyboardNavDebounced(true)
                setTimeout(() => { setIsKeyboardNavDebounced(false) }, 100)
            }
        } 
    }

    const focus = (ev: React.FocusEvent<HTMLInputElement>) => {
        props.onFocus && props.onFocus(ev);
        setIsInputFocused(true);
    }

    const blur = (ev: React.FocusEvent<HTMLInputElement>) => {
        setSearch('')
        props.onBlur && props.onBlur(ev);
        setIsInputFocused(false);
        setHoveredOptionIndex(undefined);
    }

    const mapOptionToItem = (option: any, index: number) => {
        return <Option 
            ref={index === hoveredOptionIndex ? hoveredOptionRef : undefined} 
            onMouseEnter={() => { setHoveredOptionIndex(index) }} 
            hovered={(hoveredOptionIndex === index).toString()} 
            onClick={(ev) => { props.onSelect && props.onSelect(option); ev.preventDefault(); ev.stopPropagation() }} 
            role='listitem'
        >
            <OptionLabel>{option.label}</OptionLabel>
        </Option>
    }

    // Scroll hovered option fully into view
    useEffect(() => {
        (hoveredOptionRef?.current as any)?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }, [hoveredOptionIndex])

    return <Container>
        <InputEl
            onFocus={focus}
            onBlur={blur}
            ref={props.parentRef || inputRef}
            value={isInputFocused ? search : (props.selected ? `${props.selected?.label}` : '')}
            onChange={(ev) => { setSearch(ev.target.value) }}
            type={props.type}
            name={props.name}
            $error={props.errorMessage}
            onKeyDown={handleKeydown}
            autoComplete='off'
        />
        <Label 
            $error={props.errorMessage} 
            $isinputfocused={isInputFocused} 
            $islabelminified={isLabelMinified}
        >
            {props.label}
        </Label>
        <CaretContainer>
            <StyledCaretDown/>
        </CaretContainer>
        <AnimatePresence mode="wait">
            {isInputFocused && <Dropdown ref={dropdownRef} onClick={handleDropdownClick} role='list' {...fadeInOutMotionProps}>
                {filteredOptions.map(mapOptionToItem)}
            </Dropdown>}
        </AnimatePresence>
        <AnimatePresence mode='wait'>
            {props.errorMessage && <motion.div {...expandVerticallyMotionProps}>
                <ErrorMessage>{props.errorMessage}</ErrorMessage>
            </motion.div>}
        </AnimatePresence>
    </Container>
}

const OptionLabel = styled.div`
    white-space: nowrap;
    overflow: hidden;
    min-width: 48px;
    font-size: 16px;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    color: ${styles.Color.TaekusGrey1};
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 19.8
    text-overflow: ellipsis;
    &:not(&:last-child) {
        margin-right: 8px;
    }
`

type OptionProps = {
    hovered: string,
}

const Option = styled.div<OptionProps>`
    display: flex;
    cursor: pointer;
    padding: 12px 16px;
    color: ${styles.Color.TaekusGrey1};
    overflow: hidden;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    ${props => props.hovered === 'true' ? `
        background: ${styles.Color.TaekusGrey4};
    ` : `
        background: ${styles.Color.TaekusGrey5};
        &:nth-child(even) {
            background: ${styles.Color.White};
        }
    `}
    &:hover {
        background: ${styles.Color.TaekusGrey4};
    }
    ${styles.Animation.transitionStyles}
`

const Dropdown = styled(motion.div)`
    z-index: 9999;
    position: absolute;
    top: 52px;
    width: 100%;
    max-height: 240px;
    background: ${styles.Color.White};
    border-radius: 0px 0px 4px 4px;
    border-bottom: 1px solid ${styles.Color.TaekusGrey4};
    border-left: 1px solid ${styles.Color.TaekusGrey4};
    box-shadow: 0px 0px 16px 0px rgba(124, 61, 118, 0.15);
    overflow-x: hidden;
    overflow-y: scroll;
    outline: 0;
    ${styles.Scrollbar.TaekusGrey}
`

const CaretContainer = styled.div`
    pointer-events: none;
    position: absolute;
    width: 32px;
    height: 52px;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
`

const StyledCaretDown = styled(CaretDown)`
    width: 10px;
    height: auto;
    fill: ${styles.Color.TaekusPurple};
`

const ErrorMessage = styled.div`
    color: ${styles.Color.TaekusRed};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 14.88px */
    letter-spacing: 0.24px;
    padding: 8px 16px 0;
`

type ContainerProps = {
    showsError?: boolean,
}

const Container = styled.div<ContainerProps>`
    min-height: 52px;
    position: relative;
`

type LabelProps = {
    $error: any,
    $isinputfocused: boolean,
    $islabelminified: boolean,
}

const Label = styled.div<LabelProps>`
    pointer-events: none;
    position: absolute;
    padding: 9px 16px;
    display: flex;
    align-items: center;
    width: 308px;
    height: ${props => props.$islabelminified ? '30px' : '52px'};
    top: 0;
    left: 0;
    ${styles.Animation.transitionStyles}
    color: ${props => props.$error ? styles.Color.TaekusRed : (props.$isinputfocused ? styles.Color.TaekusPurple : styles.Color.TaekusGrey3)};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: ${props => props.$islabelminified ? '10px' : '16px'};
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
`

type InputElProps = {
    $error: any,
}

const InputEl = styled.input<InputElProps>`
    cursor: pointer;
    border-radius: 2px;
    outline: 1px solid ${props => props.$error ? `${styles.Color.TaekusRed} !important` : styles.Color.TaekusGrey4};
    border: 1px solid transparent;
    height: 52px;
    padding: 23px 40px 9px 16px;
    &:hover {
        box-shadow: 0px 0px 16px 0px rgba(124, 61, 118, 0.15);
        border: 1px solid transparent;
    }
    &:focus {
        box-shadow: 0px 0px 16px 0px rgba(124, 61, 118, 0.15);
        border: 1px solid transparent;
        background: linear-gradient(0deg, rgba(124, 61, 118, 0.05) 0%, rgba(124, 61, 118, 0.05) 100%), var(--Color-Taekus-White, #FFF);
        outline: 1px solid ${styles.Color.TaekusPurple};
    }
    min-width: 308px;
    width: 100%;
    color: ${styles.Color.TaekusBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 19.84px */
    letter-spacing: 0.32px;
    ${styles.Animation.transitionStyles}
`

export default Select