import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { motion } from "framer-motion"
import { styled } from "styled-components"

import { Actions as UserActions } from 'redux/currentUser'

import { SignupStep } from "components/signup/constants"
import { updateStep, userLogin } from "components/signup/signupSlice"
import { StepDirection } from "components/signup/types"

import ArrowButton, { ArrowDirection } from "components/signup/components/ArrowButton"
import Button from "components/signup/components/Button"
import Input from "components/signup/components/Input"
import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer"

import styles from "styles/styles"

const dynamicInputMotionProps = {
    initial: { height: 0, opacity: 0, padding: 0, },
    exit: { height: 0, opacity: 0, padding: 0, },
    animate: { height: 'min-content', opacity: 1,  }
}

const SignIn = () => {
    const dispatch = useDispatch()

    const user = useSelector((state: any) => state.currentUser)
    const isLoading = useSelector((state: any) => state.signup.isLoading)
    const userExists = useSelector((state: any) => state.signup.userExists)
    const step = useSelector((state: any) => state.signup.step)
    const application = useSelector((state: any) => state.signup.application)
    const isInviteCodeValid = useSelector((state: any) => state.signup.isInviteCodeValid)
    const products = useSelector((state: any) => state.signup.products)
    const stepDirection = useSelector((state: any) => state.signup.direction)
    const loginRequiresMFA = useSelector((state: any) => state.signup.loginRequiresMFA)
    const loginFailed = useSelector((state: any) => state.signup.loginFailed)

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [token, setToken] = useState('')
    const [showError, setShowError] = useState(false)
    const [showMFAInput, setShowMFAInput] = useState(false)
    // Step detection is captured as a state variable to avoid changing values on animation exit
    const [isUserDetected, setIsUserDetected] = useState(step === SignupStep.UserDetected) // eslint-disable-line

    const inputRef = useRef(null)

    const isSubmitDisabled = username === '' || password === ''
    const isLoginError = (user.error || loginFailed) && !showMFAInput && !isLoading
    const isMFAError = showMFAInput && (user.error || loginFailed)
    const selectedProduct = products?.find((product: any) => product.productCode === application.productCode)
    const title = isUserDetected ? `Is that you?` : 'Log In'
    const subtitle = isUserDetected ? `You’re already a Taekus member! Please log in to your account to continue so that we can expedite your application process.` : 'If you’re already a Taekus member, please log in to your account to prevent delays in processing your application.'

    const onUsernameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(ev.target.value)
    }

    const onPasswordChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(ev.target.value)
    }

    const returnToApplication = () => {
        const validatedInviteCode = isInviteCodeValid
        const productSelected = !!(selectedProduct?.productType && selectedProduct?.productCategory)
        const nextStep = validatedInviteCode ? 
            (productSelected ? 
                (user.userLoaded ? SignupStep.LoadingProve : SignupStep.FindInfo) 
                : SignupStep.ChooseProduct) 
            : SignupStep.InviteCode
        dispatch(updateStep({ step: userExists ? SignupStep.LoadingProve : nextStep, direction: StepDirection.Right }))
    }

    const handleBack = (ev: React.MouseEvent) => {
        ev.stopPropagation()
        const validatedInviteCode = isInviteCodeValid
        const productSelected = !!(selectedProduct?.productType && selectedProduct?.productCategory)
        const nextStep = validatedInviteCode ? (productSelected ? SignupStep.FindInfo : SignupStep.ChooseProduct) : SignupStep.InviteCode
        dispatch(updateStep({ step: nextStep, direction: StepDirection.Left }))
    }

    const handleSignIn = async (ev: React.FormEvent) => {
        ev.preventDefault();

        dispatch(userLogin({ 
            username, 
            password, 
            rememberMe: false, 
            token: loginRequiresMFA ? token : undefined 
        }))
        if (localStorage.getItem('accessToken')) {
            
        }
        setShowError(true)
    }

    useEffect(() => {
        if (loginRequiresMFA) {
            setShowMFAInput(true)
        }
    }, [loginRequiresMFA])

    // Use accessToken to fetch user account once set
    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken')
        
        if (accessToken) {
            dispatch(UserActions.fetchCurrentUser())
        }
    }, [isLoading, dispatch])


    // Once user is fetched, go to the relevant step
    useEffect(() => {
        if (user.userLoaded) {
            returnToApplication()
        }
    }, [user.userLoaded]) // eslint-disable-line

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='SignIn'
    >
        <form className="d-flex flex-column align-items-center" onSubmit={handleSignIn}>
            <Title>{title}</Title>
            <Text>{subtitle}</Text>
            <div style={{marginBottom: '8px'}}>
                <Input errorMessage={(showError && isLoginError) ? "Incorrect username or password." : undefined} name='username' onChange={onUsernameChange} value={username} parentRef={inputRef} label="Username"/>
            </div>
            <Input type='password' name='password' onChange={onPasswordChange} value={password} label="Password"/>
            {showMFAInput && <motion.div {...dynamicInputMotionProps} style={{overflow: 'hidden'}}>
                <div style={{padding: '8px'}}>
                    <Input errorMessage={isMFAError ? 'Your token is invalid.' : undefined} name='token' onChange={(ev) => { setToken(ev.target.value) }} value={token} label="One-time Authentication Code"/>
                </div>
            </motion.div>}
            <div style={{marginTop: '32px', display: 'flex'}}>
                <div style={{marginRight: '16px'}}>
                    <ArrowButton onClick={handleBack} direction={ArrowDirection.Left}/>
                </div>
                <Button isLoading={user.isFetching || isLoading} disabled={isSubmitDisabled} type="submit">Next</Button>
            </div>
        </form>
    </StepContainer>
}

const Text = styled.div`
    color: ${styles.Color.TaekusGrey2};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    margin-bottom: 16px;
`

const Title = styled.div`
    color: ${styles.Color.TaekusGrey1};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-bottom: 32px;
`

export default SignIn